import React from "react";
import Loadable from '@loadable/component'
import store from './store.js'
import { Button } from '@mui/material';

// recursively creates react element from json of component-props-children

const createReactElement = (elementData, componentName) => {
    if (typeof(elementData) === 'string') return elementData;

    let component;
    if (elementData.component[0] === elementData.component[0].toUpperCase()) {
        component = store[componentName].componentDictionary[elementData.component];
    } else if (elementData.component[1] && (elementData.component[0] + elementData.component[1] === './')) {
        component = Loadable(() => import(`./${elementData.component.replace('./','')}`));
    } else {
        component = elementData.component;
    }

    let props = {};
    Object.keys(elementData.props).map((key, _) => {
        if (store.ContentBar.propsDictionary[key]) {
            props[key] = store[componentName].propsDictionary[key][elementData.props[key]];
        } else {
            props[key] = elementData.props[key];
        }
    })

    let children = [];
    elementData.children.map((childData, _) => {
        const child = createReactElement(childData, componentName);
        children.push(child);
    })

    return (React.createElement(component, props, ...children));
}

// builds the whole content page for the componentName
// data to build resides in store.componentName

const DynamicComponent = ({ componentName }) => {

    const contentIDs = store[componentName].state.contentIDs;

    const [state, setState] = React.useState({
        contentIDsDataArray: []
    });

    React.useEffect(() => {
        contentIDs.map( async (contentID, index) => {
            const response = await makeRequest({ inputText: `{ "content_id": "${contentID}" }` }, '/api/content/fetchrecords');
            if (response.success) {
                setState({
                    ...state,
                    contentIDsDataArray: response.data
                })
           };
        });
    }, [contentIDs])

    let children = [];
    state.contentIDsDataArray.map((content, _) => {
        const child = createReactElement(content.content_data, componentName);
        children.push(child);
    });

    return (React.createElement(React.Fragment, {}, ...children));
}

export default DynamicComponent;