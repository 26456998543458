import React from "react";
import { Dna } from 'react-loader-spinner'
import Loadable from '@loadable/component';


export const Fallback = () => <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}><Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" /></div>

export const blogRoutes = [
    { route: '/stories/the-barometer-story', component: Loadable(() => import(`./blogs/stories/the-barometer-story.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/heisenberg-story', component: Loadable(() => import(`./blogs/stories/heisenberg-story.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/webcam-invention', component: Loadable(() => import(`./blogs/stories/webcam-invention.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/the-story-of-curies', component: Loadable(() => import(`./blogs/stories/the-story-of-curies.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/the-three-body-problem', component: Loadable(() => import(`./blogs/stories/the-three-body-problem.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/hevesy-solution', component: Loadable(() => import(`./blogs/stories/hevesy-solution.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/the-spaghetti-problem', component: Loadable(() => import(`./blogs/stories/the-spaghetti-problem.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/galileo-experiment', component: Loadable(() => import(`./blogs/stories/galileo-experiment.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/bubblegum-invention', component: Loadable(() => import(`./blogs/stories/bubblegum-invention.jsx`), { fallback: <Fallback /> }) },
    { route: '/creators/gyroscopic_precession', component: Loadable(() => import(`./blogs/creators/gyroscopic_precession.jsx`), { fallback: <Fallback /> }) },
    { route: '/creators/josephus_problem', component: Loadable(() => import(`./blogs/creators/josephus_problem.jsx`), { fallback: <Fallback /> }) },
    { route: '/creators/monty_hall_problem', component: Loadable(() => import(`./blogs/creators/monty_hall_problem.jsx`), { fallback: <Fallback /> }) },
    { route: '/creators/hundered_prisoners_riddle', component: Loadable(() => import(`./blogs/creators/hundered_prisoners_riddle.jsx`), { fallback: <Fallback /> }) },
    { route: '/creators/magnus_force', component: Loadable(() => import(`./blogs/creators/magnus_force.jsx`), { fallback: <Fallback /> }) },
    { route: '/creators/tennis_racket_theorem', component: Loadable(() => import(`./blogs/creators/tennis_racket_theorem.jsx`), { fallback: <Fallback /> }) },
    { route: '/creators/vortex-ring', component: Loadable(() => import(`./blogs/creators/vortex-ring.jsx`), { fallback: <Fallback /> }) },
    { route: '/stories/parallel-ray-axis', component: Loadable(() => import(`./blogs/stories/parallel-ray-axis.jsx`), { fallback: <Fallback /> }) },
];
