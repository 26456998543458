import React from "react";
import {
    Box,
} from '@mui/material';
import store from './store.js';

const managePlan = async () => {
    if (!store.LandingPage) {
        window.location.href = '/';
    }

    store.LandingPage.setState({
        ...store.LandingPage.state,
        componentToRender: '/plan'
    })
}

const Banner = () => {
    if (store.user?.isLoggedIn && store.user?.subscriptionPlan === 'free') {
        return (
            <Box
                onClick={managePlan}
                sx={{
                    backgroundColor: 'initial',
                    backgroundImage: `linear-gradient(-180deg, #FF7E31, #F15516)`,
                    color: '#FFFFFF',
                    cursor: 'pointer',
                    display: 'inline-block',
                    fontSize: '14px',
                    // fontFamily: `Roboto:wght@300`,
                    height: '30px',
                    lineHeight: '30px',
                    outline: '1px solid black',
                    overflow: 'hidden',
                    padding: '0 20px',
                    pointerEvents: 'auto',
                    position: 'relative',
                    textAlign: 'center',
                    touchAction: 'manipulation',
                    userSelect: 'none',
                    verticalAlign: 'top',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    zIndex: 9,
                    border: 0,
                }}
            >
                Unlock Full Coursebook With Premium Plan
            </Box>
        )
    }
}

export default Banner;