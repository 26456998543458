import React, { Component } from "react";
import Loadable from '@loadable/component'
import { Dna } from 'react-loader-spinner'
import NavbarLoggedIn from './modules/common/NavbarLoggedIn.jsx';
import { urlToContent2 } from './modules/common/contents.js';
import { blogRoutes } from './blogRoutes.js';

const common_path = './modules/common'
const physics_classx_electro_path = './modules/physics/classx/electro'
const physics_classx_optics_path = './modules/physics/classx/optics'
const physics_classx_mechanics_path = './modules/physics/classx/mechanics'
const physics_classx_calorimetry_path = './modules/physics/classx/calorimetry'
// const physics_classx_sound_path = './modules/physics/classx/sound'
const physics_classx_modernphysics_path = './modules/physics/classx/modernphysics'
const physics_classx_question_path = './modules/physics/classx/question'
const math_classx_circles_path = './modules/maths/classx/circles'
const math_classx_trignometry_path = './modules/maths/classx/trigonometry'
const math_classx_algebra_path = './modules/maths/classx/algebra'
const math_classx_mensuration_path = './modules/maths/classx/mensuration'
const math_classx_coordinate_path = './modules/maths/classx/coordinate'
const chemistry_classx_organic_path = './modules/chemistry/classx/organic'
const chemistry_classx_inorganic_path = './modules/chemistry/classx/inorganic'

const physics_sim_path = './modules/physics/simulations'


// Class 9
// ---- cbse
const physics_class9_cbse_mechanics_path = './modules/physics/class9-cbse/mechanics';
const physics_class9_cbse_sound_path = './modules/physics/class9-cbse/sound';
const chemistry_class9_cbse_physical_path = './modules/chemistry/class9-cbse/physical';
const maths_class9_cbse_number_path = './modules/maths/class9-cbse/number';
const maths_class9_cbse_algebra_path = './modules/maths/class9-cbse/algebra';
const maths_class9_cbse_coordinate_path = './modules/maths/class9-cbse/coordinate';
const maths_class9_cbse_geometry_path = './modules/maths/class9-cbse/geometry';
const maths_class9_cbse_mensuration_path = './modules/maths/class9-cbse/mensuration';
const maths_class9_cbse_statistics_path = './modules/maths/class9-cbse/statistics';
// ---- icse
const physics_class9_icse_measurement_path = './modules/physics/class9-icse/measurement';
const physics_class9_icse_mechanics_path = './modules/physics/class9-icse/mechanics';
const physics_class9_icse_pressure_path = './modules/physics/class9-icse/pressure';
const physics_class9_icse_fluids_path = './modules/physics/class9-icse/fluids';
const physics_class9_icse_heat_path = './modules/physics/class9-icse/heat';
const physics_class9_icse_optics_path = './modules/physics/class9-icse/optics';
const physics_class9_icse_sound_path = './modules/physics/class9-icse/sound';
const physics_class9_icse_electro_path = './modules/physics/class9-icse/electro';
const chemistry_class9_icse_physical_path = './modules/chemistry/class9-icse/physical';
const chemistry_class9_icse_inorganic_path = './modules/chemistry/class9-icse/inorganic';
const maths_class9_icse_number_path = './modules/maths/class9-icse/number';
const maths_class9_icse_finance_path = './modules/maths/class9-icse/finance';
const maths_class9_icse_algebra_path = './modules/maths/class9-icse/algebra';
const maths_class9_icse_geometry_path = './modules/maths/class9-icse/geometry';
const maths_class9_icse_statistics_path = './modules/maths/class9-icse/statistics';
const maths_class9_icse_mensuration_path = './modules/maths/class9-icse/mensuration';
const maths_class9_icse_trigonometry_path = './modules/maths/class9-icse/trigonometry';
const maths_class9_icse_coordinate_path = './modules/maths/class9-icse/coordinate';

// Common routes
export const Fallback = () => <div style={{ height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}><Dna visible={true} height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" /></div>
export const NotFound = Loadable(() => import(`${common_path}/NotFound.jsx`), { fallback: <Fallback /> })
export const Lolo = Loadable(() => import(`${common_path}/Lolo.jsx`), { fallback: <Fallback /> })
// export const Home = Loadable(() => import(`${common_path}/Home.jsx`),{fallback: <Fallback />})

export const Home = Loadable(() => import(`./pages/Home.jsx`), { fallback: <Fallback /> });
export const Products = Loadable(() => import(`./pages/Products.jsx`), { fallback: <Fallback /> });
export const Home2 = Loadable(() => import(`./pages/Home.jsx`), { fallback: <Fallback /> });
export const ContentTemplates = Loadable(() => import(`./pages/ContentTemplates.jsx`), { fallback: <Fallback /> });
export const Services = Loadable(() => import(`./pages/Services.jsx`), { fallback: <Fallback /> });
// export const Blocks = Loadable(() => import(`./pages/Blocks.jsx`), { fallback: <Fallback /> });
export const Bit = Loadable(() => import(`./pages/Bit.jsx`), { fallback: <Fallback /> });
export const IB = Loadable(() => import(`./pages/IB.jsx`), { fallback: <Fallback /> });

export const HomeCBSEUP = Loadable(() => import(`${common_path}/HomeCBSEUP.jsx`), { fallback: <Fallback /> })
export const HomeCBSEUPHINDI = Loadable(() => import(`${common_path}/HomeCBSEUPhindi.jsx`), { fallback: <Fallback /> })
export const HomeICSE = Loadable(() => import(`${common_path}/HomeICSE.jsx`), { fallback: <Fallback /> })
export const HomeICSEHindi = Loadable(() => import(`${common_path}/HomeICSEHindi.jsx`), { fallback: <Fallback /> })
export const LandingPage = Loadable(() => import(`${common_path}/LandingPage.jsx`), { fallback: <Fallback /> })
export const EditContent = Loadable(() => import(`${common_path}/EditContent.jsx`), { fallback: <Fallback /> })
export const Dashboard = Loadable(() => import(`${common_path}/Dashboard.jsx`), { fallback: <Fallback /> })
export const PlanPage = Loadable(() => import(`${common_path}/PlanPage.jsx`), { fallback: <Fallback /> })
export const ContactPage = Loadable(() => import(`${common_path}/ContactPage.jsx`), { fallback: <Fallback /> })
export const Terms = Loadable(() => import(`${common_path}/Terms.jsx`), { fallback: <Fallback /> })
export const Privacy = Loadable(() => import(`${common_path}/Privacy.jsx`), { fallback: <Fallback /> })
export const Refund = Loadable(() => import(`${common_path}/Refund.jsx`), { fallback: <Fallback /> })
export const Watermark = Loadable(() => import(`./modules/helpers/Watermark.jsx`), { fallback: <Fallback /> })
export const SignupPage = Loadable(() => import(`${common_path}/SignupPage.jsx`), { fallback: <Fallback /> })
export const LoginPage = Loadable(() => import(`${common_path}/LoginPage.jsx`), { fallback: <Fallback /> })
export const Offer = Loadable(() => import(`${common_path}/Offer.jsx`), { fallback: <Fallback /> })
export const Buy = Loadable(() => import(`${common_path}/Buy.jsx`), { fallback: <Fallback /> })
export const ReflectionDemo = Loadable(() => import(`./modules/demo/ReflectionDemo.jsx`), { fallback: <Fallback /> })
export const ForceTorqueDemo = Loadable(() => import(`./modules/demo/ForceTorqueDemo.jsx`), { fallback: <Fallback /> })
export const ChemicalReactionDemo = Loadable(() => import(`./modules/demo/ChemicalReactionDemo.jsx`), { fallback: <Fallback /> })
export const PeriodicTableDemo = Loadable(() => import(`./modules/demo/PeriodicTableDemo.jsx`), { fallback: <Fallback /> })
export const RealNumberDemo = Loadable(() => import(`./modules/demo/RealNumberDemo.jsx`), { fallback: <Fallback /> })
export const QuadraticDemo = Loadable(() => import(`./modules/demo/QuadraticDemo.jsx`), { fallback: <Fallback /> })
export const EditSrc = Loadable(() => import(`./pages/EditSrc.jsx`), { fallback: <Fallback /> })

export const PhysicsSim = Loadable(() => import(`${physics_sim_path}/Sim.jsx`), { fallback: <Fallback /> })

// -------------------------------------------- CLASS 10 LOL PHYSICS ------------------------------------------------

// MAHA LOL PAGES ELECTRO
export const Lol_charges = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_charges.jsx`), { fallback: <Fallback /> });
export const Lol_conductor_insulator = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_conductor_insulator.jsx`), { fallback: <Fallback /> });
export const Lol_resistance = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_resistance.jsx`), { fallback: <Fallback /> });
export const Lol_ohmslaw = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_ohmslaw.jsx`), { fallback: <Fallback /> });
export const Lol_EM1 = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_EM1.jsx`), { fallback: <Fallback /> });
export const Lol_EM1_part2 = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_EM1_part2.jsx`), { fallback: <Fallback /> });
export const Lol_EM2 = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_EM2.jsx`), { fallback: <Fallback /> });
export const Lol_EM3 = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_EM3.jsx`), { fallback: <Fallback /> });
export const Lol_emf = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_emf.jsx`), { fallback: <Fallback /> });
export const Lol_CombinationofResistances = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_CombinationOfResistances.jsx`), { fallback: <Fallback /> });
export const Lol_ElectricPower = Loadable(() => import(`./learn/class10-cbse/physics/lol_electro/Lol_ElectricPower.jsx`), { fallback: <Fallback /> });

// LOL OPTICS
export const Lol_ReflectionOfLight = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_ReflectionOfLight.jsx`), { fallback: <Fallback /> });
export const Lol_SphericalMirrors = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_SphericalMirrors.jsx`), { fallback: <Fallback /> });
export const Lol_ConcaveMirrors = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_ConcaveMirrors.jsx`), { fallback: <Fallback /> });
export const Lol_ConvexMirrors = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_ConvexMirrors.jsx`), { fallback: <Fallback /> });
export const Lol_RefractionOfLight = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_RefractionOfLight.jsx`), { fallback: <Fallback /> });
export const Lol_Prism = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_Prism.jsx`), { fallback: <Fallback /> });
export const Lol_SphericalLens = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_SphericalLens.jsx`), { fallback: <Fallback /> });
export const Lol_ConvexLens = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_ConvexLens.jsx`), { fallback: <Fallback /> });
export const Lol_ConcaveLens = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_ConcaveLens.jsx`), { fallback: <Fallback /> });
export const Lol_HumanEye1 = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_HumanEye1.jsx`), { fallback: <Fallback /> });
export const Lol_HumanEye2 = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_HumanEye2.jsx`), { fallback: <Fallback /> });
export const Lol_HumanEye3 = Loadable(() => import(`./learn/class10-cbse/physics/lol_optics/Lol_HumanEye3.jsx`), { fallback: <Fallback /> });

// LOL NUCLEAR PHYSICS
export const Lol_NuclearReactions = Loadable(() => import(`./learn/class10-cbse/physics/lol_modernphysics/Lol_NuclearReactions.jsx`), { fallback: <Fallback /> });

//-----------------------------ICSE-----------------------//

// LOL MECHANICS
export const Lol_ForceAndTorque = Loadable(() => import(`./learn/class10-icse/physics/lol_mechanics/Lol_ForceAndTorque.jsx`), { fallback: <Fallback /> });
export const Lol_WorkAndEnergy1 = Loadable(() => import(`./learn/class10-icse/physics/lol_mechanics/Lol_WorkAndEnergy1.jsx`), { fallback: <Fallback /> });
export const Lol_WorkAndEnergy2 = Loadable(() => import(`./learn/class10-icse/physics/lol_mechanics/Lol_WorkAndEnergy2.jsx`), { fallback: <Fallback /> });
export const Lol_WorkAndEnergy3 = Loadable(() => import(`./learn/class10-icse/physics/lol_mechanics/Lol_WorkAndEnergy3.jsx`), { fallback: <Fallback /> });
export const Lol_Machines = Loadable(() => import(`./learn/class10-icse/physics/lol_mechanics/Lol_Machines.jsx`), { fallback: <Fallback /> });
export const Lol_Pulleys = Loadable(() => import(`./learn/class10-icse/physics/lol_mechanics/Lol_Pulleys.jsx`), { fallback: <Fallback /> });









// -------------------------------------------- CLASS 10 CBSE PHYSICS ------------------------------------------------

export const Class10CbsePhysicsReflectionOfLight = Loadable(() => import(`./learn/class10-cbse/physics/optics/ReflectionOfLight.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsSphericalMirrors = Loadable(() => import(`./learn/class10-cbse/physics/optics/SphericalMirrors.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsConcaveMirrors = Loadable(() => import(`./learn/class10-cbse/physics/optics/ConcaveMirrors.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsConvexMirrors = Loadable(() => import(`./learn/class10-cbse/physics/optics/ConvexMirrors.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsRefractionOfLight = Loadable(() => import(`./learn/class10-cbse/physics/optics/RefractionOfLight.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsPrism = Loadable(() => import(`./learn/class10-cbse/physics/optics/Prism.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsSphericalLens = Loadable(() => import(`./learn/class10-cbse/physics/optics/SphericalLens.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsConvexLens = Loadable(() => import(`./learn/class10-cbse/physics/optics/ConvexLens.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsConcaveLens = Loadable(() => import(`./learn/class10-cbse/physics/optics/ConcaveLens.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsHumanEye1 = Loadable(() => import(`./learn/class10-cbse/physics/optics/HumanEye1.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsHumanEye2 = Loadable(() => import(`./learn/class10-cbse/physics/optics/HumanEye2.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsHumanEye3 = Loadable(() => import(`./learn/class10-cbse/physics/optics/HumanEye3.jsx`), { fallback: <Fallback /> });

export const Class10CbsePhysicsCharges = Loadable(() => import(`./learn/class10-cbse/physics/electro/Charges.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsElectricPotentialAndElectricCurrent = Loadable(() => import(`./learn/class10-cbse/physics/electro/ElectricPotentialAndElectricCurrent.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsOhmsLaw = Loadable(() => import(`./learn/class10-cbse/physics/electro/OhmsLaw.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsResistance = Loadable(() => import(`./learn/class10-cbse/physics/electro/Resistance.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsCombinationOfResistances = Loadable(() => import(`./learn/class10-cbse/physics/electro/CombinationOfResistances.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsElectricPower = Loadable(() => import(`./learn/class10-cbse/physics/electro/ElectricPower.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsEMF = Loadable(() => import(`./learn/class10-cbse/physics/electro/EMF.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsMagneticEffectsOfElectricCurrent = Loadable(() => import(`./learn/class10-cbse/physics/electro/MagneticEffectsOfElectricCurrent.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsMagneticEffectsOfElectricCurrent2 = Loadable(() => import(`./learn/class10-cbse/physics/electro/MagneticEffectsOfElectricCurrent2.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsForceOnCurrentCarryingConductor = Loadable(() => import(`./learn/class10-cbse/physics/electro/ForceOnCurrentCarryingConductor.jsx`), { fallback: <Fallback /> });
export const Class10CbsePhysicsElectromagneticInduction = Loadable(() => import(`./learn/class10-cbse/physics/electro/ElectromagneticInduction.jsx`), { fallback: <Fallback /> });

export const Class10CbsePhysicsNuclearReactions = Loadable(() => import(`./learn/class10-cbse/physics/modernphysics/NuclearReactions.jsx`), { fallback: <Fallback /> });

// -------------------------------------------- CLASS 10 CBSE CHEMISTRY ------------------------------------------------

// LOL ORGANIC
export const Lol_OrganicChemistry = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_OrganicChemistry.jsx`), { fallback: <Fallback /> });
export const Lol_ClassificationOfOrganicCompounds = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_ClassificationOfOrganicCompounds.jsx`), { fallback: <Fallback /> });
export const Lol_Nomenclature = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_Nomenclature.jsx`), { fallback: <Fallback /> });
export const Lol_UnsaturatedCompounds = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_UnsaturatedCompounds.jsx`), { fallback: <Fallback /> });
export const Lol_CombustionAndOxidation = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_CombustionAndOxidation.jsx`), { fallback: <Fallback /> });
export const Lol_Alkanes = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_Alkanes.jsx`), { fallback: <Fallback /> });
export const Lol_Alkenes = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_Alkenes.jsx`), { fallback: <Fallback /> });
export const Lol_Alkynes = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_Alkynes.jsx`), { fallback: <Fallback /> });
export const Lol_Alcohols = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_Alcohols.jsx`), { fallback: <Fallback /> });
export const Lol_CarboxylicAcids = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_organic/Lol_CarboxylicAcids.jsx`), { fallback: <Fallback /> });

// LOL INORGANIC
export const Lol_ChemicalReactions = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_ChemicalReactions.jsx`), { fallback: <Fallback /> });
export const Lol_TypesOfReactions1 = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_TypesOfReactions1.jsx`), { fallback: <Fallback /> });
export const Lol_TypesOfReactions2 = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_TypesOfReactions2.jsx`), { fallback: <Fallback /> });
export const Lol_Acids = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_Acids.jsx`), { fallback: <Fallback /> });
export const Lol_Bases = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_Bases.jsx`), { fallback: <Fallback /> });
export const Lol_Salts = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_Salts.jsx`), { fallback: <Fallback /> });
export const Lol_MetalsAndNonmetals1 = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_MetalsAndNonmetals1.jsx`), { fallback: <Fallback /> });
export const Lol_MetalsAndNonmetals2 = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_MetalsAndNonmetals2.jsx`), { fallback: <Fallback /> });
export const Lol_MetalsAndNonmetals3 = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_MetalsAndNonmetals3.jsx`), { fallback: <Fallback /> });
export const Lol_MetalsAndNonmetals4 = Loadable(() => import(`./learn/class10-cbse/chemistry/lol_inorganic/Lol_MetalsAndNonmetals4.jsx`), { fallback: <Fallback /> });



export const Class10CbseChemistryChemicalReactions = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/ChemicalReactions.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryTypesOfReactions1 = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/TypesOfReactions1.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryTypesOfReactions2 = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/TypesOfReactions2.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryAcids = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/Acids.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryBases = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/Bases.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistrySalts = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/Salts.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryMetalsAndNonmetals1 = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/MetalsAndNonmetals1.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryMetalsAndNonmetals2 = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/MetalsAndNonmetals2.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryMetalsAndNonmetals3 = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/MetalsAndNonmetals3.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryMetalsAndNonmetals4 = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/MetalsAndNonmetals4.jsx`), { fallback: <Fallback /> });

export const Class10CbseChemistryOrganicChemistry = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/OrganicChemistry.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryClassificationOfOrganicCompounds = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/ClassificationOfOrganicCompounds.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryNomenclature = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Nomenclature.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryUnsaturatedCompounds = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/UnsaturatedCompounds.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryCombustionAndOxidation = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/CombustionAndOxidation.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryAlkanes = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alkanes.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryAlkenes = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alkenes.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryAlkynes = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alkynes.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryAlcohols = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alcohols.jsx`), { fallback: <Fallback /> });
export const Class10CbseChemistryCarboxylicAcids = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/CarboxylicAcids.jsx`), { fallback: <Fallback /> });

// -------------------------------------------- CLASS 10 CBSE MATHS ------------------------------------------------

// LOL NUMBER
export const Lol_RealNumbers = Loadable(() => import(`./learn/class10-cbse/maths/lol_number/Lol_RealNumbers.jsx`), { fallback: <Fallback /> });

// LOL ALGEBRA
export const Lol_Polynomials = Loadable(() => import(`./learn/class10-cbse/maths/lol_algebra/Lol_Polynomials.jsx`), { fallback: <Fallback /> });
export const Lol_LinearEquationsInTwoVariables = Loadable(() => import(`./learn/class10-cbse/maths/lol_algebra/Lol_LinearEquationsInTwoVariables.jsx`), { fallback: <Fallback /> });
export const Lol_QuadraticEquations = Loadable(() => import(`./learn/class10-cbse/maths/lol_algebra/Lol_QuadraticEquations.jsx`), { fallback: <Fallback /> });
export const Lol_ApplicationsOfQuadraticEquations1 = Loadable(() => import(`./learn/class10-cbse/maths/lol_algebra/Lol_ApplicationsOfQuadraticEquations1.jsx`), { fallback: <Fallback /> });
export const Lol_ApplicationsOfQuadraticEquations2 = Loadable(() => import(`./learn/class10-cbse/maths/lol_algebra/Lol_ApplicationsOfQuadraticEquations2.jsx`), { fallback: <Fallback /> });
export const Lol_ArithmeticProgressions = Loadable(() => import(`./learn/class10-cbse/maths/lol_algebra/Lol_ArithmeticProgressions.jsx`), { fallback: <Fallback /> });

// LOL COORDINATE
export const Lol_CoordinateGeometry = Loadable(() => import(`./learn/class10-cbse/maths/lol_coordinate/Lol_CoordinateGeometry.jsx`), { fallback: <Fallback /> });
export const Lol_SectionFormula = Loadable(() => import(`./learn/class10-cbse/maths/lol_coordinate/Lol_SectionFormula.jsx`), { fallback: <Fallback /> });
export const Lol_StraightLine = Loadable(() => import(`./learn/class10-cbse/maths/lol_coordinate/Lol_StraightLine.jsx`), { fallback: <Fallback /> });

// LOL TRIGONOMETRY
export const Lol_Trigonometry = Loadable(() => import(`./learn/class10-cbse/maths/lol_trigonometry/Lol_Trigonometry.jsx`), { fallback: <Fallback /> });
export const Lol_HeightsAndDistances1 = Loadable(() => import(`./learn/class10-cbse/maths/lol_trigonometry/Lol_HeightsAndDistances1.jsx`), { fallback: <Fallback /> });
export const Lol_HeightsAndDistances2 = Loadable(() => import(`./learn/class10-cbse/maths/lol_trigonometry/Lol_HeightsAndDistances2.jsx`), { fallback: <Fallback /> });

// LOL GEOMETRY
export const Lol_Circles = Loadable(() => import(`./learn/class10-cbse/maths/lol_geometry/Lol_Circles.jsx`), { fallback: <Fallback /> });
export const Lol_AreasRelatedToCircles = Loadable(() => import(`./learn/class10-cbse/maths/lol_geometry/Lol_AreasRelatedToCircles.jsx`), { fallback: <Fallback /> });
export const Lol_SectorsAndSegments = Loadable(() => import(`./learn/class10-cbse/maths/lol_geometry/Lol_SectorsAndSegments.jsx`), { fallback: <Fallback /> });

// LOL MENSURATION
export const Lol_Mensuration = Loadable(() => import(`./learn/class10-cbse/maths/lol_mensuration/Lol_Mensuration.jsx`), { fallback: <Fallback /> });

// LOL PROBABILITY
export const Lol_Probability1 = Loadable(() => import(`./learn/class10-cbse/maths/lol_probability/Lol_Probability1.jsx`), { fallback: <Fallback /> });
export const Lol_Probability2 = Loadable(() => import(`./learn/class10-cbse/maths/lol_probability/Lol_Probability2.jsx`), { fallback: <Fallback /> });







export const Class10CbseMathsRealNumbers = Loadable(() => import(`./learn/class10-cbse/maths/number/RealNumbers.jsx`), { fallback: <Fallback /> });

export const Class10CbseMathsPolynomials = Loadable(() => import(`./learn/class10-cbse/maths/algebra/Polynomials.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsLinearEquationsInTwoVariables = Loadable(() => import(`./learn/class10-cbse/maths/algebra/LinearEquationsInTwoVariables.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsQuadraticEquations = Loadable(() => import(`./learn/class10-cbse/maths/algebra/QuadraticEquations.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsApplicationsOfQuadraticEquations1 = Loadable(() => import(`./learn/class10-cbse/maths/algebra/ApplicationsOfQuadraticEquations1.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsApplicationsOfQuadraticEquations2 = Loadable(() => import(`./learn/class10-cbse/maths/algebra/ApplicationsOfQuadraticEquations2.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsArithmeticProgressions = Loadable(() => import(`./learn/class10-cbse/maths/algebra/ArithmeticProgressions.jsx`), { fallback: <Fallback /> });

export const Class10CbseMathsCoordinateGeometry = Loadable(() => import(`./learn/class10-cbse/maths/coordinate/CoordinateGeometry.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsSectionFormula = Loadable(() => import(`./learn/class10-cbse/maths/coordinate/SectionFormula.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsStraightLine = Loadable(() => import(`./learn/class10-cbse/maths/coordinate/StraightLine.jsx`), { fallback: <Fallback /> });

export const Class10CbseMathsTrigonometry = Loadable(() => import(`./learn/class10-cbse/maths/trigonometry/Trigonometry.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsHeightsAndDistances1 = Loadable(() => import(`./learn/class10-cbse/maths/trigonometry/HeightsAndDistances1.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsHeightsAndDistances2 = Loadable(() => import(`./learn/class10-cbse/maths/trigonometry/HeightsAndDistances2.jsx`), { fallback: <Fallback /> });

export const Class10CbseMathsCircles = Loadable(() => import(`./learn/class10-cbse/maths/geometry/Circles.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsAreasRelatedToCircles1 = Loadable(() => import(`./learn/class10-cbse/maths/geometry/AreasRelatedToCircles1.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsAreasRelatedToCircles2 = Loadable(() => import(`./learn/class10-cbse/maths/geometry/AreasRelatedToCircles2.jsx`), { fallback: <Fallback /> });

export const Class10CbseMathsMensuration = Loadable(() => import(`./learn/class10-cbse/maths/mensuration/Mensuration.jsx`), { fallback: <Fallback /> });

export const Class10CbseMathsProbability1 = Loadable(() => import(`./learn/class10-cbse/maths/probability/Probability1.jsx`), { fallback: <Fallback /> });
export const Class10CbseMathsProbability2 = Loadable(() => import(`./learn/class10-cbse/maths/probability/Probability2.jsx`), { fallback: <Fallback /> });

// -------------------------------------------- CLASS 10 ICSE PHYSICS ------------------------------------------------

export const Class10IcsePhysicsForceAndTorque = Loadable(() => import(`./learn/class10-icse/physics/mechanics/ForceAndTorque.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsWorkAndEnergy1 = Loadable(() => import(`./learn/class10-icse/physics/mechanics/WorkAndEnergy1.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsWorkAndEnergy2 = Loadable(() => import(`./learn/class10-icse/physics/mechanics/WorkAndEnergy2.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsWorkAndEnergy3 = Loadable(() => import(`./learn/class10-icse/physics/mechanics/WorkAndEnergy3.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsMachines = Loadable(() => import(`./learn/class10-icse/physics/mechanics/Machines.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsPulleys = Loadable(() => import(`./learn/class10-icse/physics/mechanics/Pulleys.jsx`), { fallback: <Fallback /> });

export const Class10IcsePhysicsRefractionOfLight = Loadable(() => import(`./learn/class10-cbse/physics/optics/RefractionOfLight.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsPrism = Loadable(() => import(`./learn/class10-cbse/physics/optics/Prism.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsSphericalLens = Loadable(() => import(`./learn/class10-cbse/physics/optics/SphericalLens.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsConvexLens = Loadable(() => import(`./learn/class10-cbse/physics/optics/ConvexLens.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsConcaveLens = Loadable(() => import(`./learn/class10-cbse/physics/optics/ConcaveLens.jsx`), { fallback: <Fallback /> });

// Class 10 ICSE Sound = Class 9 CBSE Sound


// Class 10 ICSE Electro = Class 10 CBSE Electro

export const Class10IcsePhysicsCharges = Loadable(() => import(`./learn/class10-cbse/physics/electro/Charges.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsElectricPotentialAndElectricCurrent = Loadable(() => import(`./learn/class10-cbse/physics/electro/ElectricPotentialAndElectricCurrent.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsOhmsLaw = Loadable(() => import(`./learn/class10-cbse/physics/electro/OhmsLaw.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsResistance = Loadable(() => import(`./learn/class10-cbse/physics/electro/Resistance.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsCombinationOfResistances = Loadable(() => import(`./learn/class10-cbse/physics/electro/CombinationOfResistances.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsElectricPower = Loadable(() => import(`./learn/class10-cbse/physics/electro/ElectricPower.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsEMF = Loadable(() => import(`./learn/class10-cbse/physics/electro/EMF.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsEM1 = Loadable(() => import(`./learn/class10-cbse/physics/electro/MagneticEffectsOfElectricCurrent.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsEM2 = Loadable(() => import(`./learn/class10-cbse/physics/electro/ForceOnCurrentCarryingConductor.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsEM3 = Loadable(() => import(`./learn/class10-cbse/physics/electro/ElectromagneticInduction.jsx`), { fallback: <Fallback /> });

export const Class10IcsePhysicsCalorimetry1 = Loadable(() => import(`./learn/class10-icse/physics/calorimetry/Calorimetry1.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsCalorimetry2 = Loadable(() => import(`./learn/class10-icse/physics/calorimetry/Calorimetry2.jsx`), { fallback: <Fallback /> });

export const Class10IcsePhysicsRadioactivity = Loadable(() => import(`./learn/class10-icse/physics/modernphysics/Radioactivity.jsx`), { fallback: <Fallback /> });
export const Class10IcsePhysicsNuclearReactions = Loadable(() => import(`./learn/class10-cbse/physics/modernphysics/NuclearReactions.jsx`), { fallback: <Fallback /> });

// -------------------------------------------- CLASS 10 ICSE CHEMISTRY ------------------------------------------------

export const Class10IcseChemistryPeriodicTable1 = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/PeriodicTable1.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryPeriodicTable2 = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/PeriodicTable2.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryChemicalBonding1 = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/ChemicalBonding1.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryChemicalBonding2 = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/ChemicalBonding2.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryAcids = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/Acids.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryBases = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/Bases.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistrySalts = Loadable(() => import(`./learn/class10-cbse/chemistry/inorganic/Salts.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryAluminiumExtraction = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/AluminiumExtraction.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryMetallurgy = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/Metallurgy.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryAmmonia = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/Ammonia.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryNitricAcid = Loadable(() => import(`./learn/class10-icse/chemistry/inorganic/NitricAcid.jsx`), { fallback: <Fallback /> });

export const Class10IcseChemistryMoleConcept1 = Loadable(() => import(`./learn/class10-icse/chemistry/physical/MoleConcept1.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryMoleConcept2 = Loadable(() => import(`./learn/class10-icse/chemistry/physical/MoleConcept2.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryMoleConcept3 = Loadable(() => import(`./learn/class10-icse/chemistry/physical/MoleConcept3.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryMoleConcept4 = Loadable(() => import(`./learn/class10-icse/chemistry/physical/MoleConcept4.jsx`), { fallback: <Fallback /> });

export const Class10IcseChemistryOrganicChemistry = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/OrganicChemistry.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryClassificationOfOrganicCompounds = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/ClassificationOfOrganicCompounds.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryNomenclature = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Nomenclature.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryUnsaturatedCompounds = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/UnsaturatedCompounds.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryCombustionAndOxidation = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/CombustionAndOxidation.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryAlkanes = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alkanes.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryAlkenes = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alkenes.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryAlkynes = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alkynes.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryAlcohols = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/Alcohols.jsx`), { fallback: <Fallback /> });
export const Class10IcseChemistryCarboxylicAcids = Loadable(() => import(`./learn/class10-cbse/chemistry/organic/CarboxylicAcids.jsx`), { fallback: <Fallback /> });

// -------------------------------------------- CLASS 10 ICSE MATHS ------------------------------------------------

export const Class10IcseMathsQuadraticEquations = Loadable(() => import(`./learn/class10-cbse/maths/algebra/QuadraticEquations.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsApplicationsOfQuadraticEquations1 = Loadable(() => import(`./learn/class10-cbse/maths/algebra/ApplicationsOfQuadraticEquations1.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsApplicationsOfQuadraticEquations2 = Loadable(() => import(`./learn/class10-cbse/maths/algebra/ApplicationsOfQuadraticEquations2.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsArithmeticProgressions = Loadable(() => import(`./learn/class10-cbse/maths/algebra/ArithmeticProgressions.jsx`), { fallback: <Fallback /> });

export const Class10IcseMathsCoordinateGeometry = Loadable(() => import(`./learn/class10-cbse/maths/coordinate/CoordinateGeometry.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsSectionFormula = Loadable(() => import(`./learn/class10-cbse/maths/coordinate/SectionFormula.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsStraightLine = Loadable(() => import(`./learn/class10-cbse/maths/coordinate/StraightLine.jsx`), { fallback: <Fallback /> });

export const Class10IcseMathsTrigonometry = Loadable(() => import(`./learn/class10-cbse/maths/trigonometry/Trigonometry.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsHeightsAndDistances1 = Loadable(() => import(`./learn/class10-cbse/maths/trigonometry/HeightsAndDistances1.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsHeightsAndDistances2 = Loadable(() => import(`./learn/class10-cbse/maths/trigonometry/HeightsAndDistances2.jsx`), { fallback: <Fallback /> });

export const Class10IcseMathsCircles = Loadable(() => import(`./learn/class10-cbse/maths/geometry/Circles.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsAreasRelatedToCircles1 = Loadable(() => import(`./learn/class10-cbse/maths/geometry/AreasRelatedToCircles1.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsAreasRelatedToCircles2 = Loadable(() => import(`./learn/class10-cbse/maths/geometry/AreasRelatedToCircles2.jsx`), { fallback: <Fallback /> });

export const Class10IcseMathsMensuration = Loadable(() => import(`./learn/class10-cbse/maths/mensuration/Mensuration.jsx`), { fallback: <Fallback /> });

export const Class10IcseMathsProbability1 = Loadable(() => import(`./learn/class10-cbse/maths/probability/Probability1.jsx`), { fallback: <Fallback /> });
export const Class10IcseMathsProbability2 = Loadable(() => import(`./learn/class10-cbse/maths/probability/Probability2.jsx`), { fallback: <Fallback /> });

// ------------------------------------------------------------------------------------------------------------------

// -------------------------------------------- C L A S S - 10 ------------------------------------------------ //

//                                                 EXAMPLES
export const ExampleCharges = Loadable(() => import('./modules/examples/ExampleCharges.jsx'), { fallback: <Fallback /> })
export const ExampleForceTorque = Loadable(() => import('./modules/examples/ExampleForceTorque.jsx'), { fallback: <Fallback /> })

//                                                  PHYSICS

// CLASS X Lol_Electro

// Class X Electro
export const Charges = Loadable(() => import(`${physics_classx_electro_path}/Charges.jsx`), { fallback: <Fallback /> })
export const ChargesHINDI = Loadable(() => import(`${physics_classx_electro_path}/ChargesHINDI.jsx`), { fallback: <Fallback /> })
export const ConductorInsulator = Loadable(() => import(`${physics_classx_electro_path}/ConductorInsulator.jsx`), { fallback: <Fallback /> })
export const ConductorInsulatorHINDI = Loadable(() => import(`${physics_classx_electro_path}/ConductorInsulatorHINDI.jsx`), { fallback: <Fallback /> })
export const Resistance = Loadable(() => import(`${physics_classx_electro_path}/Resistance.jsx`), { fallback: <Fallback /> })
export const ResistanceHINDI = Loadable(() => import(`${physics_classx_electro_path}/ResistanceHINDI.jsx`), { fallback: <Fallback /> })
export const OhmsLaw = Loadable(() => import(`${physics_classx_electro_path}/OhmsLaw.jsx`), { fallback: <Fallback /> })
export const OhmsLawHINDI = Loadable(() => import(`${physics_classx_electro_path}/OhmsLawHINDI.jsx`), { fallback: <Fallback /> })
export const Power = Loadable(() => import(`${physics_classx_electro_path}/Power.jsx`), { fallback: <Fallback /> })
export const PowerHINDI = Loadable(() => import(`${physics_classx_electro_path}/PowerHINDI.jsx`), { fallback: <Fallback /> })
export const EMF = Loadable(() => import(`${physics_classx_electro_path}/EMF.jsx`), { fallback: <Fallback /> })
export const EMFHINDI = Loadable(() => import(`${physics_classx_electro_path}/EMFHINDI.jsx`), { fallback: <Fallback /> })
export const Combinationofresistances = Loadable(() => import(`${physics_classx_electro_path}/Combinationofresistances.jsx`), { fallback: <Fallback /> })
export const CombinationofresistancesHINDI = Loadable(() => import(`${physics_classx_electro_path}/CombinationofresistancesHINDI.jsx`), { fallback: <Fallback /> })
export const EM1 = Loadable(() => import(`${physics_classx_electro_path}/EM1.jsx`), { fallback: <Fallback /> })
export const EM1HINDI = Loadable(() => import(`${physics_classx_electro_path}/EM1HINDI.jsx`), { fallback: <Fallback /> })
export const EM2 = Loadable(() => import(`${physics_classx_electro_path}/EM2.jsx`), { fallback: <Fallback /> })
export const EM2HINDI = Loadable(() => import(`${physics_classx_electro_path}/EM2HINDI.jsx`), { fallback: <Fallback /> })
export const EM3 = Loadable(() => import(`${physics_classx_electro_path}/EM3.jsx`), { fallback: <Fallback /> })
export const EM3HINDI = Loadable(() => import(`${physics_classx_electro_path}/EM3HINDI.jsx`), { fallback: <Fallback /> })

// Class X Optics
export const Reflection = Loadable(() => import(`${physics_classx_optics_path}/Reflection.jsx`), { fallback: <Fallback /> })
export const ReflectionHINDI = Loadable(() => import(`${physics_classx_optics_path}/ReflectionHINDI.jsx`), { fallback: <Fallback /> })
export const Reflection2 = Loadable(() => import(`${physics_classx_optics_path}/Reflection2.jsx`), { fallback: <Fallback /> })
export const Reflection2HINDI = Loadable(() => import(`${physics_classx_optics_path}/Reflection2HINDI.jsx`), { fallback: <Fallback /> })
export const Spherical = Loadable(() => import(`${physics_classx_optics_path}/Spherical.jsx`), { fallback: <Fallback /> })
export const SphericalHINDI = Loadable(() => import(`${physics_classx_optics_path}/SphericalHINDI.jsx`), { fallback: <Fallback /> })
export const Spherical2 = Loadable(() => import(`${physics_classx_optics_path}/Spherical2.jsx`), { fallback: <Fallback /> })
export const Spherical2HINDI = Loadable(() => import(`${physics_classx_optics_path}/Spherical2HINDI.jsx`), { fallback: <Fallback /> })
export const Spherical3 = Loadable(() => import(`${physics_classx_optics_path}/Spherical3.jsx`), { fallback: <Fallback /> })
export const Spherical3HINDI = Loadable(() => import(`${physics_classx_optics_path}/Spherical3HINDI.jsx`), { fallback: <Fallback /> })
export const Refraction = Loadable(() => import(`${physics_classx_optics_path}/Refraction.jsx`), { fallback: <Fallback /> })
export const RefractionHINDI = Loadable(() => import(`${physics_classx_optics_path}/RefractionHINDI.jsx`), { fallback: <Fallback /> })
export const Lens = Loadable(() => import(`${physics_classx_optics_path}/Lens.jsx`), { fallback: <Fallback /> })
export const LensHINDI = Loadable(() => import(`${physics_classx_optics_path}/LensHINDI.jsx`), { fallback: <Fallback /> })
export const Lens2 = Loadable(() => import(`${physics_classx_optics_path}/Lens2.jsx`), { fallback: <Fallback /> })
export const Lens2HINDI = Loadable(() => import(`${physics_classx_optics_path}/Lens2HINDI.jsx`), { fallback: <Fallback /> })
export const Lens3 = Loadable(() => import(`${physics_classx_optics_path}/Lens3.jsx`), { fallback: <Fallback /> })
export const Lens3HINDI = Loadable(() => import(`${physics_classx_optics_path}/Lens3HINDI.jsx`), { fallback: <Fallback /> })
export const Prism = Loadable(() => import(`${physics_classx_optics_path}/Prism.jsx`), { fallback: <Fallback /> })
export const PrismHINDI = Loadable(() => import(`${physics_classx_optics_path}/PrismHINDI.jsx`), { fallback: <Fallback /> })
export const HumanEye1 = Loadable(() => import(`${physics_classx_optics_path}/HumanEye1.jsx`), { fallback: <Fallback /> })
export const HumanEye2 = Loadable(() => import(`${physics_classx_optics_path}/HumanEye2.jsx`), { fallback: <Fallback /> })
export const HumanEye3 = Loadable(() => import(`${physics_classx_optics_path}/HumanEye3.jsx`), { fallback: <Fallback /> })

// Class X Mechanics
export const Machines = Loadable(() => import(`${physics_classx_mechanics_path}/Machines.jsx`), { fallback: <Fallback /> })
export const Pulleys = Loadable(() => import(`${physics_classx_mechanics_path}/Pulleys.jsx`), { fallback: <Fallback /> })
export const ForceTorque = Loadable(() => import(`${physics_classx_mechanics_path}/ForceTorque.jsx`), { fallback: <Fallback /> })
export const WorkEnergy1 = Loadable(() => import(`${physics_classx_mechanics_path}/WorkEnergy1.jsx`), { fallback: <Fallback /> })
export const WorkEnergy2 = Loadable(() => import(`${physics_classx_mechanics_path}/WorkEnergy2.jsx`), { fallback: <Fallback /> })
export const WorkEnergy3 = Loadable(() => import(`${physics_classx_mechanics_path}/WorkEnergy3.jsx`), { fallback: <Fallback /> })
export const Lol = Loadable(() => import(`${physics_classx_mechanics_path}/Lol.jsx`), { fallback: <Fallback /> })
export const Demo = Loadable(() => import(`${physics_classx_mechanics_path}/Demo.jsx`), { fallback: <Fallback /> })
//----HINDI----
export const ForceTorqueHINDI = Loadable(() => import(`${physics_classx_mechanics_path}/ForceTorqueHINDI.jsx`), { fallback: <Fallback /> })


//Class X Calorimetry
export const Calorimetry = Loadable(() => import(`${physics_classx_calorimetry_path}/Calorimetry.jsx`), { fallback: <Fallback /> })
export const Calorimetry2 = Loadable(() => import(`${physics_classx_calorimetry_path}/Calorimetry2.jsx`), { fallback: <Fallback /> })

// Class X Sound
//export const Sound1 = Loadable(() => import(`${physics_classx_sound_path}/Sound1.jsx`),{fallback: <Fallback />})

// class X ModernPhysics
export const Radioactivity = Loadable(() => import(`${physics_classx_modernphysics_path}/Radioactivity.jsx`), { fallback: <Fallback /> })
export const Nuclear = Loadable(() => import(`${physics_classx_modernphysics_path}/Nuclear.jsx`), { fallback: <Fallback /> })

// Class X Question
export const Pryear = Loadable(() => import(`${physics_classx_question_path}/Pryear.jsx`), { fallback: <Fallback /> })

// Physics routes End

//                                                   MATHS
// Class X Circles
export const Circle1 = Loadable(() => import(`${math_classx_circles_path}/Circle1.jsx`), { fallback: <Fallback /> })
export const Circle3 = Loadable(() => import(`${math_classx_circles_path}/Circle3.jsx`), { fallback: <Fallback /> })
export const Circle4 = Loadable(() => import(`${math_classx_circles_path}/Circle4.jsx`), { fallback: <Fallback /> })

// Class X Trignometry
export const Trigno1 = Loadable(() => import(`${math_classx_trignometry_path}/Trigno1.jsx`), { fallback: <Fallback /> })
export const Trigno2 = Loadable(() => import(`${math_classx_trignometry_path}/Trigno2.jsx`), { fallback: <Fallback /> })
export const Trigno3 = Loadable(() => import(`${math_classx_trignometry_path}/Trigno3.jsx`), { fallback: <Fallback /> })
export const Trigno4 = Loadable(() => import(`${math_classx_trignometry_path}/Trigno4.jsx`), { fallback: <Fallback /> })
export const Trigno5 = Loadable(() => import(`${math_classx_trignometry_path}/Trigno5.jsx`), { fallback: <Fallback /> })

// Class X Algebra
export const RealNumber = Loadable(() => import(`${math_classx_algebra_path}/RealNumber.jsx`), { fallback: <Fallback /> })
export const Polynomials = Loadable(() => import(`${math_classx_algebra_path}/Polynomials.jsx`), { fallback: <Fallback /> })
export const Linear = Loadable(() => import(`${math_classx_algebra_path}/Linear.jsx`), { fallback: <Fallback /> })
export const Quadratic = Loadable(() => import(`${math_classx_algebra_path}/Quadratic.jsx`), { fallback: <Fallback /> })
export const Quadratic2 = Loadable(() => import(`${math_classx_algebra_path}/Quadratic2.jsx`), { fallback: <Fallback /> })
export const Quadratic3 = Loadable(() => import(`${math_classx_algebra_path}/Quadratic3.jsx`), { fallback: <Fallback /> })
export const AP = Loadable(() => import(`${math_classx_algebra_path}/AP.jsx`), { fallback: <Fallback /> })
export const Probability1 = Loadable(() => import(`${math_classx_algebra_path}/Probability1.jsx`), { fallback: <Fallback /> })
export const Probability2 = Loadable(() => import(`${math_classx_algebra_path}/Probability2.jsx`), { fallback: <Fallback /> })

//Class X Mensuration
export const Mensuration1 = Loadable(() => import(`${math_classx_mensuration_path}/Mensuration1.jsx`), { fallback: <Fallback /> })

// Class X Coordinate
export const Coord1 = Loadable(() => import(`${math_classx_coordinate_path}/Coord1.jsx`), { fallback: <Fallback /> })
export const Coord2 = Loadable(() => import(`${math_classx_coordinate_path}/Coord2.jsx`), { fallback: <Fallback /> })
export const StraightLine = Loadable(() => import(`${math_classx_coordinate_path}/StraightLine.jsx`), { fallback: <Fallback /> })

// Math routes End

//                                                CHEMISTRY
// Class X Organic
export const Organic = Loadable(() => import(`${chemistry_classx_organic_path}/Organic.jsx`), { fallback: <Fallback /> })
export const OrganicHINDI = Loadable(() => import(`${chemistry_classx_organic_path}/OrganicHINDI.jsx`), { fallback: <Fallback /> })
export const Organic2 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic2.jsx`), { fallback: <Fallback /> })
export const Organic2HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic2HINDI.jsx`), { fallback: <Fallback /> })
export const Organic3 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic3.jsx`), { fallback: <Fallback /> })
export const Organic3HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic3HINDI.jsx`), { fallback: <Fallback /> })
export const Organic4 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic4.jsx`), { fallback: <Fallback /> })
export const Organic4HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic4HINDI.jsx`), { fallback: <Fallback /> })
export const Organic6 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic6.jsx`), { fallback: <Fallback /> })
export const Organic6HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic6HINDI.jsx`), { fallback: <Fallback /> })
export const Organic7 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic7.jsx`), { fallback: <Fallback /> })
export const Organic7HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic7HINDI.jsx`), { fallback: <Fallback /> })
export const Organic8 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic8.jsx`), { fallback: <Fallback /> })
export const Organic8HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic8HINDI.jsx`), { fallback: <Fallback /> })
export const Organic9 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic9.jsx`), { fallback: <Fallback /> })
export const Organic9HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic9HINDI.jsx`), { fallback: <Fallback /> })
export const Organic10 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic10.jsx`), { fallback: <Fallback /> })
export const Organic10HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic10HINDI.jsx`), { fallback: <Fallback /> })
export const Organic11 = Loadable(() => import(`${chemistry_classx_organic_path}/Organic11.jsx`), { fallback: <Fallback /> })
export const Organic11HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/Organic11HINDI.jsx`), { fallback: <Fallback /> })
export const AcidBase = Loadable(() => import(`${chemistry_classx_organic_path}/AcidBase.jsx`), { fallback: <Fallback /> })
export const AcidBaseHINDI = Loadable(() => import(`${chemistry_classx_organic_path}/AcidBaseHINDI.jsx`), { fallback: <Fallback /> })
export const AcidBase2 = Loadable(() => import(`${chemistry_classx_organic_path}/AcidBase2.jsx`), { fallback: <Fallback /> })
export const AcidBase2HINDI = Loadable(() => import(`${chemistry_classx_organic_path}/AcidBase2HINDI.jsx`), { fallback: <Fallback /> })
export const Salt = Loadable(() => import(`${chemistry_classx_organic_path}/Salt.jsx`), { fallback: <Fallback /> })
export const SaltHINDI = Loadable(() => import(`${chemistry_classx_organic_path}/SaltHINDI.jsx`), { fallback: <Fallback /> })

// Class X Inorganic
export const PeriodicTable = Loadable(() => import(`${chemistry_classx_inorganic_path}/PeriodicTable.jsx`), { fallback: <Fallback /> })
export const PeriodicTableHINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/PeriodicTableHINDI.jsx`), { fallback: <Fallback /> })
export const PeriodicTable2 = Loadable(() => import(`${chemistry_classx_inorganic_path}/PeriodicTable2.jsx`), { fallback: <Fallback /> })
export const PeriodicTable2HINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/PeriodicTable2HINDI.jsx`), { fallback: <Fallback /> })
export const ChemicalBonding = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalBonding.jsx`), { fallback: <Fallback /> })
export const ChemicalBondingHINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalBondingHINDI.jsx`), { fallback: <Fallback /> })
export const ChemicalBonding2 = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalBonding2.jsx`), { fallback: <Fallback /> })
export const ChemicalBonding2HINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalBonding2HINDI.jsx`), { fallback: <Fallback /> })
export const ChemicalReaction = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalReaction.jsx`), { fallback: <Fallback /> })
export const ChemicalReactionHINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalReactionHINDI.jsx`), { fallback: <Fallback /> })
export const ChemicalReaction2 = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalReaction2.jsx`), { fallback: <Fallback /> })
export const ChemicalReaction2HINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalReaction2HINDI.jsx`), { fallback: <Fallback /> })
export const ChemicalReaction3 = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalReaction3.jsx`), { fallback: <Fallback /> })
export const ChemicalReaction3HINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/ChemicalReaction3HINDI.jsx`), { fallback: <Fallback /> })
export const StudyOfCompounds = Loadable(() => import(`${chemistry_classx_inorganic_path}/StudyOfCompounds.jsx`), { fallback: <Fallback /> })
export const StudyOfCompoundsHINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/StudyOfCompoundsHINDI.jsx`), { fallback: <Fallback /> })
export const StudyOfCompounds2 = Loadable(() => import(`${chemistry_classx_inorganic_path}/StudyOfCompounds2.jsx`), { fallback: <Fallback /> })
export const StudyOfCompounds2HINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/StudyOfCompounds2HINDI.jsx`), { fallback: <Fallback /> })
export const Metallurgy = Loadable(() => import(`${chemistry_classx_inorganic_path}/Metallurgy.jsx`), { fallback: <Fallback /> })
export const MetallurgyHINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/MetallurgyHINDI.jsx`), { fallback: <Fallback /> })
export const Metallurgy1 = Loadable(() => import(`${chemistry_classx_inorganic_path}/Metallurgy1.jsx`), { fallback: <Fallback /> })
export const Metallurgy1HINDI = Loadable(() => import(`${chemistry_classx_inorganic_path}/Metallurgy1HINDI.jsx`), { fallback: <Fallback /> })
export const MoleConcept1 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MoleConcept1.jsx`), { fallback: <Fallback /> })
export const MoleConcept2 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MoleConcept2.jsx`), { fallback: <Fallback /> })
export const MoleConcept3 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MoleConcept3.jsx`), { fallback: <Fallback /> })
export const MoleConcept4 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MoleConcept4.jsx`), { fallback: <Fallback /> })
export const MetalsAndNonmetals1 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MetalsAndNonmetals1.jsx`), { fallback: <Fallback /> })
export const MetalsAndNonmetals2 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MetalsAndNonmetals2.jsx`), { fallback: <Fallback /> })
export const MetalsAndNonmetals3 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MetalsAndNonmetals3.jsx`), { fallback: <Fallback /> })
export const MetalsAndNonmetals4 = Loadable(() => import(`${chemistry_classx_inorganic_path}/MetalsAndNonmetals4.jsx`), { fallback: <Fallback /> })

// Chemistry routes End
// ----------------------------------------------------------------------------------

// ------------------------------------- CLASS 9 CBSE PHYSICS ---------------------------------------

export const RestMotion = Loadable(() => import(`${physics_class9_cbse_mechanics_path}/RestMotion.jsx`), { fallback: <Fallback /> })
export const RestMotion2 = Loadable(() => import(`${physics_class9_cbse_mechanics_path}/RestMotion2.jsx`), { fallback: <Fallback /> })
export const RestMotion3 = Loadable(() => import(`${physics_class9_cbse_mechanics_path}/RestMotion3.jsx`), { fallback: <Fallback /> })

export const Force1 = Loadable(() => import(`${physics_class9_cbse_mechanics_path}/Force1.jsx`), { fallback: <Fallback /> })
export const Force2 = Loadable(() => import(`${physics_class9_cbse_mechanics_path}/Force2.jsx`), { fallback: <Fallback /> })
export const Force3 = Loadable(() => import(`${physics_class9_cbse_mechanics_path}/Force3.jsx`), { fallback: <Fallback /> })

export const Class9CbsePhysicsLol = Loadable(() => import('./learn/class9-cbse/physics/mechanics/Lol.jsx'), { fallback: <Fallback /> });

export const Class9CbsePhysicsMotion1 = Loadable(() => import('./learn/class9-cbse/physics/mechanics/Motion1.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsMotion2 = Loadable(() => import('./learn/class9-cbse/physics/mechanics/Motion2.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsMotion3 = Loadable(() => import('./learn/class9-cbse/physics/mechanics/Motion3.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsForce = Loadable(() => import('./learn/class9-cbse/physics/mechanics/Force.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsLawsOfMotion = Loadable(() => import('./learn/class9-cbse/physics/mechanics/LawsOfMotion.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsGravitation = Loadable(() => import('./learn/class9-cbse/physics/mechanics/Gravitation.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsWorkAndEnergy1 = Loadable(() => import('./learn/class9-cbse/physics/mechanics/WorkAndEnergy1.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsWorkAndEnergy2 = Loadable(() => import('./learn/class9-cbse/physics/mechanics/WorkAndEnergy2.jsx'), { fallback: <Fallback /> });
export const Class9CbsePhysicsWorkAndEnergy3 = Loadable(() => import('./learn/class9-cbse/physics/mechanics/WorkAndEnergy3.jsx'), { fallback: <Fallback /> });

// ---- Pressure
export const FliudPressureAndTransmission = Loadable(() => import(`${physics_class9_icse_pressure_path}/FliudPressureAndTransmission.jsx`), { fallback: <Fallback /> })
export const AtmosphericPressureAndMeasurement = Loadable(() => import(`${physics_class9_icse_pressure_path}/AtmosphericPressureAndMeasurement.jsx`), { fallback: <Fallback /> })

// ---- Sound

export const Class9CbsePhysicsSound = Loadable(() => import(`./learn/class9-cbse/physics/sound/Sound.jsx`), { fallback: <Fallback /> });
export const Class9CbsePhysicsSound2 = Loadable(() => import(`./learn/class9-cbse/physics/sound/Sound2.jsx`), { fallback: <Fallback /> });

// ----------------------------------- CLASS 9 CBSE CHEMISTRY -------------------------------------

// ---- Physical

export const Class9CbseChemistryMatter1 = Loadable(() => import('./learn/class9-cbse/chemistry/physical/Matter1.jsx'), { fallback: <Fallback /> });
export const Class9CbseChemistryMatter2 = Loadable(() => import('./learn/class9-cbse/chemistry/physical/Matter2.jsx'), { fallback: <Fallback /> });
export const Class9CbseChemistryIsMatterAroundUsPure1 = Loadable(() => import('./learn/class9-cbse/chemistry/IsMatterAroundUsPure/IsMatterAroundUsPure1.jsx'), { fallback: <Fallback /> });
export const Class9CbseChemistryIsMatterAroundUsPure2 = Loadable(() => import('./learn/class9-cbse/chemistry/IsMatterAroundUsPure/IsMatterAroundUsPure2.jsx'), { fallback: <Fallback /> });
export const Class9CbseChemistryAtomsAndMolecules1 = Loadable(() => import('./learn/class9-cbse/chemistry/AtomsAndMolecules/AtomsAndMolecules1.jsx'), { fallback: <Fallback /> });
export const Class9CbseChemistryAtomsAndMolecules2 = Loadable(() => import('./learn/class9-cbse/chemistry/AtomsAndMolecules/AtomsAndMolecules2.jsx'), { fallback: <Fallback /> });

export const Class9CbseChemistryStructureOfTheAtom1 = Loadable(() => import('./learn/class9-cbse/chemistry/StructureOfTheAtom/StructureOfTheAtom1.jsx'), { fallback: <Fallback /> });
export const Class9CbseChemistryStructureOfTheAtom2 = Loadable(() => import('./learn/class9-cbse/chemistry/StructureOfTheAtom/StructureOfTheAtom2.jsx'), { fallback: <Fallback /> });

// ------------------------------------- CLASS 9 CBSE MATHS ---------------------------------------

export const Class9CbseMathsNumberSystem = Loadable(() => import(`./learn/class9-cbse/maths/number/NumberSystem.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsNumberSystem2 = Loadable(() => import(`./learn/class9-cbse/maths/number/NumberSystem2.jsx`), { fallback: <Fallback /> });

export const Class9CbseMathsPolynomials = Loadable(() => import(`./learn/class9-cbse/maths/algebra/Polynomials.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsPolynomials2 = Loadable(() => import(`./learn/class9-cbse/maths/algebra/Polynomials2.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsLinearEquationsInTwoVariables = Loadable(() => import(`./learn/class9-cbse/maths/algebra/LinearEquationsInTwoVariables.jsx`), { fallback: <Fallback /> });

export const Class9CbseMathsCoordinateGeometry = Loadable(() => import(`./learn/class9-cbse/maths/coordinate/CoordinateGeometry.jsx`), { fallback: <Fallback /> });

export const Class9CbseMathsEuclidsGeometry = Loadable(() => import(`./learn/class9-cbse/maths/geometry/EuclidsGeometry.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsLinesAndAngles = Loadable(() => import(`./learn/class9-cbse/maths/geometry/LinesAndAngles.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsTriangles = Loadable(() => import(`./learn/class9-cbse/maths/geometry/Triangles.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsQuadrilaterals = Loadable(() => import(`./learn/class9-cbse/maths/geometry/Quadrilaterals.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsCircles1 = Loadable(() => import(`./learn/class9-cbse/maths/geometry/Circles1.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsCircles2 = Loadable(() => import(`./learn/class9-cbse/maths/geometry/Circles2.jsx`), { fallback: <Fallback /> });


export const Class9CbseMathsHeronsFormula = Loadable(() => import(`./learn/class9-cbse/maths/mensuration/HeronsFormula.jsx`), { fallback: <Fallback /> });
export const Class9CbseMathsSurfaceAreasAndVolumes = Loadable(() => import(`./learn/class9-cbse/maths/mensuration/SurfaceAreasAndVolumes.jsx`), { fallback: <Fallback /> });

export const Class9CbseMathsStatistics = Loadable(() => import(`./learn/class9-cbse/maths/statistics/Statistics.jsx`), { fallback: <Fallback /> });

// export const Class9CbseMathsIntroductionToEuclidsGeometry = Loadable(() => import(`${maths_class9_cbse_geometry_path}/IntroductionToEuclidsGeometry.jsx`), { fallback: <Fallback /> });
// export const Class9CbseMathsLinesAndAngles = Loadable(() => import(`${maths_class9_cbse_geometry_path}/LinesAndAngles.jsx`), { fallback: <Fallback /> });
// export const Class9CbseMathsTriangles = Loadable(() => import(`${maths_class9_cbse_geometry_path}/Triangles.jsx`), { fallback: <Fallback /> });
// export const Class9CbseMathsQuadrilaterals = Loadable(() => import(`${maths_class9_cbse_geometry_path}/Quadrilaterals.jsx`), { fallback: <Fallback /> });
// export const Class9CbseMathsCircles = Loadable(() => import(`${maths_class9_cbse_geometry_path}/Circles.jsx`), { fallback: <Fallback /> });

// ---- Mensuration

// export const Class9CbseMathsHeronsFormula = Loadable(() => import(`${maths_class9_cbse_mensuration_path}/HeronsFormula.jsx`), { fallback: <Fallback /> });
// export const Class9CbseMathsSurfaceAreasAndVolumes = Loadable(() => import(`${maths_class9_cbse_mensuration_path}/SurfaceAreasAndVolumes.jsx`), { fallback: <Fallback /> });

// ---- Statistics

// export const Class9CbseMathsStatistics = Loadable(() => import(`${maths_class9_cbse_statistics_path}/Statistics.jsx`), { fallback: <Fallback /> });

// ------------------------------------- CLASS 9 ICSE PHYSICS ---------------------------------------

// ---- Measurement

export const Class9IcsePhysicsMeasurement = Loadable(() => import(`${physics_class9_icse_measurement_path}/Measurement.jsx`), { fallback: <Fallback /> });

// ---- Mechanics

export const Class9IcsePhysicsMotionInOneDimension = Loadable(() => import(`${physics_class9_icse_mechanics_path}/MotionInOneDimension.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsLawsOfMotion = Loadable(() => import(`${physics_class9_icse_mechanics_path}/LawsOfMotion.jsx`), { fallback: <Fallback /> });

// ---- Fluids

export const UpthrustAndArchimedesPrinciple = Loadable(() => import(`${physics_class9_icse_fluids_path}/UpthrustAndArchimedesPrinciple.jsx`), { fallback: <Fallback /> });
export const RelativeDensityAndFloatation = Loadable(() => import(`${physics_class9_icse_fluids_path}/RelativeDensityAndFloatation.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsAtmosphericPressure = Loadable(() => import(`./learn/class9-icse/physics/fluids/AtmosphericPressure.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsPressureInFluids = Loadable(() => import(`./learn/class9-icse/physics/fluids/PressureInFluids.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsUpthrustAndArchimedesPrinciple = Loadable(() => import(`./learn/class9-icse/physics/fluids/UpthrustAndArchimedesPrinciple.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsRelativeDensityAndFloatation = Loadable(() => import(`./learn/class9-icse/physics/fluids/RelativeDensityAndFloatation.jsx`), { fallback: <Fallback /> });
// HINDI
export const Class9IcsePhysicsAtmosphericPressureHINDI = Loadable(() => import(`./learnHINDI/class9-icse-HINDI/physics-HINDI/fluids-HINDI/AtmosphericPressureHINDI.jsx`), { fallback: <Fallback /> });


// ---- Heat and Energy
export const Class9IcsePhysicsHeatAndEnergy = Loadable(() => import(`./learn/class9-icse/physics/heat/HeatAndEnergy.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsHeatAndEnergy2 = Loadable(() => import(`./learn/class9-icse/physics/heat/HeatAndEnergy2.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsHeatAndEnergy3 = Loadable(() => import(`./learn/class9-icse/physics/heat/HeatAndEnergy3.jsx`), { fallback: <Fallback /> });
// HINDI
export const Class9IcsePhysicsHeatAndEnergyHINDI = Loadable(() => import(`./learnHINDI/class9-icse-HINDI/physics-HINDI/heat-HINDI/HeatAndEnergyHINDI.jsx`), { fallback: <Fallback /> });

export const Class9IcsePhysicsReflectionOfLight = Loadable(() => import(`${physics_class9_icse_optics_path}/ReflectionOfLight.jsx`), { fallback: <Fallback /> });

export const Class9IcsePhysicsSound = Loadable(() => import(`${physics_class9_icse_sound_path}/Sound.jsx`), { fallback: <Fallback /> }); // NOT USED

export const Class9IcsePhysicsCurrentElectricity = Loadable(() => import(`./learn/class9-icse/physics/electricity/CurrentElectricity.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsCurrentElectricity2 = Loadable(() => import(`./learn/class9-icse/physics/electricity/CurrentElectricity2.jsx`), { fallback: <Fallback /> });

export const Class9IcsePhysicsMagnetism = Loadable(() => import(`./learn/class9-icse/physics/magnetism/Magnetism.jsx`), { fallback: <Fallback /> });
export const Class9IcsePhysicsMagnetism2 = Loadable(() => import(`./learn/class9-icse/physics/magnetism/Magnetism2.jsx`), { fallback: <Fallback /> });

// ------------------------------------- CLASS 9 ICSE CHEMISTRY ---------------------------------------

export const Class9IcseChemistryTheLanguageOfChemistry1 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/TheLanguageOfChemistry1.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryTheLanguageOfChemistry2 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/TheLanguageOfChemistry2.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryChemicalChangesAndReactions1 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/ChemicalChangesAndReactions1.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryChemicalChangesAndReactions2 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/ChemicalChangesAndReactions2.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryChemicalChangesAndReactions3 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/ChemicalChangesAndReactions3.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryPeriodicTable1 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/PeriodicTable1.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryPeriodicTable2 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/PeriodicTable2.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryPeriodicTable3 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/PeriodicTable3.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryHydrogen1 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/Hydrogen1.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryHydrogen2 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/Hydrogen2.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryHydrogen3 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/Hydrogen3.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryWater1 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/Water1.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryWater2 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/Water2.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryWater3 = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/Water3.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryAtmosphericPollution = Loadable(() => import(`./learn/class9-icse/chemistry/inorganic/AtmosphericPollution.jsx`), { fallback: <Fallback /> });

export const Class9IcseChemistryAtomicStructureAndChemicalBonding1 = Loadable(() => import(`./learn/class9-icse/chemistry/physical/AtomicStructureAndChemicalBonding1.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryAtomicStructureAndChemicalBonding2 = Loadable(() => import(`./learn/class9-icse/chemistry/physical/AtomicStructureAndChemicalBonding2.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryAtomicStructureAndChemicalBonding3 = Loadable(() => import(`./learn/class9-icse/chemistry/physical/AtomicStructureAndChemicalBonding3.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryAtomicStructureAndChemicalBonding4 = Loadable(() => import(`./learn/class9-icse/chemistry/physical/AtomicStructureAndChemicalBonding4.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryStudyOfGasLaws1 = Loadable(() => import(`./learn/class9-icse/chemistry/physical/StudyOfGasLaws1.jsx`), { fallback: <Fallback /> });
export const Class9IcseChemistryStudyOfGasLaws2 = Loadable(() => import(`./learn/class9-icse/chemistry/physical/StudyOfGasLaws2.jsx`), { fallback: <Fallback /> });

// ------------------------------------- CLASS 9 ICSE MATHS ---------------------------------------

// ---- Number

export const Class9IcseMathsRationalAndIrrationalNumbers = Loadable(() => import(`${maths_class9_icse_number_path}/RationalAndIrrationalNumbers.jsx`), { fallback: <Fallback /> });

// ---- Finance

export const Class9IcseMathsCompoundInterest = Loadable(() => import(`${maths_class9_icse_finance_path}/CompoundInterest.jsx`), { fallback: <Fallback /> });

// ---- Algebra

export const Class9IcseMathsExpansions = Loadable(() => import(`${maths_class9_icse_algebra_path}/Expansions.jsx`), { fallback: <Fallback /> });
export const Class9IcseMathsFactorization = Loadable(() => import(`${maths_class9_icse_algebra_path}/Factorization.jsx`), { fallback: <Fallback /> });
export const Class9IcseMathsSimulateneousLinearEquationsInTwoVariables = Loadable(() => import(`${maths_class9_icse_algebra_path}/SimulateneousLinearEquationsInTwoVariables.jsx`), { fallback: <Fallback /> });
export const Class9IcseMathsIndices = Loadable(() => import(`${maths_class9_icse_algebra_path}/Indices.jsx`), { fallback: <Fallback /> });
export const Class9IcseMathsLogarithms = Loadable(() => import(`${maths_class9_icse_algebra_path}/Logarithms.jsx`), { fallback: <Fallback /> });

// ---- Geometry

export const Class9IcseMathsTriangles = Loadable(() => import(`${maths_class9_icse_geometry_path}/Triangles.jsx`), { fallback: <Fallback /> });
export const Class9IcseMathsRectilinearFigures = Loadable(() => import(`${maths_class9_icse_geometry_path}/RectilinearFigures.jsx`), { fallback: <Fallback /> });
export const Class9IcseMathsCircle = Loadable(() => import(`${maths_class9_icse_geometry_path}/Circle.jsx`), { fallback: <Fallback /> });

// ---- Statistics

export const Class9IcseMathsStatistics = Loadable(() => import(`${maths_class9_icse_geometry_path}/Statistics.jsx`), { fallback: <Fallback /> });

// ---- Mensuration

export const Class9IcseMathsMensuration = Loadable(() => import(`${maths_class9_icse_mensuration_path}/Mensuration.jsx`), { fallback: <Fallback /> });

// ---- Trigonometry

export const Class9IcseMathsTrigonometry = Loadable(() => import(`${maths_class9_icse_trigonometry_path}/Trigonometry.jsx`), { fallback: <Fallback /> });

// ---- Coordinate

export const Class9IcseMathsCoordinateGeometry = Loadable(() => import(`${maths_class9_icse_coordinate_path}/CoordinateGeometry.jsx`), { fallback: <Fallback /> });

// ----------------------------------------------------------------------------------

// ----------------------------------------------------------------------------------

// -------------------------------- C L A S S - 10 ----------------------------------

const component_map_class10 = {

    // Class X Electro

    '/charges': Charges,
    '/chargesHINDI': ChargesHINDI,
    '/conductor-insulator': ConductorInsulator,
    '/conductor-insulatorHINDI': ConductorInsulatorHINDI,
    '/resistance': Resistance,
    '/resistanceHINDI': ResistanceHINDI,
    '/ohms-law': OhmsLaw,
    '/ohms-lawHINDI': OhmsLawHINDI,
    '/power': Power,
    '/powerHINDI': PowerHINDI,
    '/emf': EMF,
    '/emfHINDI': EMFHINDI,
    '/combinationofresistances': Combinationofresistances,
    '/combinationofresistancesHINDI': CombinationofresistancesHINDI,
    '/em1': EM1,
    '/em1HINDI': EM1HINDI,
    '/em2': EM2,
    '/em2HINDI': EM2HINDI,
    '/em3': EM3,
    '/em3HINDI': EM3HINDI,

    // Class X Optics

    '/reflection': Reflection,
    '/reflectionHINDI': ReflectionHINDI,
    '/reflection2': Reflection2,
    '/spherical': Spherical,
    '/sphericalHINDI': SphericalHINDI,
    '/spherical2': Spherical2,
    '/spherical2HINDI': Spherical2HINDI,
    '/spherical3': Spherical3,
    '/spherical3HINDI': Spherical3HINDI,
    '/reflectionHINDI': ReflectionHINDI,
    '/reflection2HINDI': Reflection2HINDI,
    '/refraction': Refraction,
    '/refractionHINDI': RefractionHINDI,
    '/lens': Lens,
    '/lensHINDI': LensHINDI,
    '/lens2': Lens2,
    '/lens2HINDI': Lens2HINDI,
    '/lens3': Lens3,
    '/lens3HINDI': Lens3HINDI,
    '/prism': Prism,
    '/prismHINDI': PrismHINDI,
    '/humaneye1': HumanEye1,
    '/humaneye2': HumanEye2,
    '/humaneye3': HumanEye3,

    // Class X Mechanics

    '/machines': Machines,
    '/pulleys': Pulleys,
    '/fortor': ForceTorque,
    '/fortorHINDI': ForceTorqueHINDI,
    '/workenergy1': WorkEnergy1,
    '/workenergy2': WorkEnergy2,
    '/workenergy3': WorkEnergy3,
    '/demo': Demo,
    '/lol': Lol,

    // Class X Calorimetry

    '/calorimetry': Calorimetry,
    '/calorimetry2': Calorimetry2,

    // Class X Sound

    //'/sound1': Sound1,

    // Class X ModernPhysics

    '/radioactivity': Radioactivity,
    '/nuclear': Nuclear,

    // Class X Organic

    '/organic': Organic,
    '/organicHINDI': OrganicHINDI,
    '/organic2': Organic2,
    '/organic2HINDI': Organic2HINDI,
    '/organic3': Organic3,
    '/organic3HINDI': Organic3HINDI,
    '/organic4': Organic4,
    '/organic4HINDI': Organic4HINDI,
    '/organic6': Organic6,
    '/organic6HINDI': Organic6HINDI,
    '/organic7': Organic7,
    '/organic7HINDI': Organic7HINDI,
    '/organic8': Organic8,
    '/organic8HINDI': Organic8HINDI,
    '/organic9': Organic9,
    '/organic9HINDI': Organic9HINDI,
    '/organic10': Organic10,
    '/organic10HINDI': Organic10HINDI,
    '/organic11': Organic11,
    '/organic11HINDI': Organic11HINDI,
    '/acid-base': AcidBase,
    '/acid-baseHINDI': AcidBaseHINDI,
    '/acid-base2': AcidBase2,
    '/acid-base2HINDI': AcidBase2HINDI,
    '/salt': Salt,
    '/saltHINDI': SaltHINDI,

    // Class X Inorganic

    '/periodic': PeriodicTable,
    '/periodicHINDI': PeriodicTableHINDI,
    '/periodic2': PeriodicTable2,
    '/periodic2HINDI': PeriodicTable2HINDI,
    '/moleconcept1': MoleConcept1,
    '/moleconcept2': MoleConcept2,
    '/moleconcept3': MoleConcept3,
    '/moleconcept4': MoleConcept4,
    '/metalsandnonmetals1': MetalsAndNonmetals1,
    '/metalsandnonmetals2': MetalsAndNonmetals2,
    '/metalsandnonmetals3': MetalsAndNonmetals3,
    '/metalsandnonmetals4': MetalsAndNonmetals4,
    '/chembond': ChemicalBonding,
    '/chembondHINDI': ChemicalBondingHINDI,
    '/chembond2': ChemicalBonding2,
    '/chembond2HINDI': ChemicalBonding2HINDI,
    '/chemreact': ChemicalReaction,
    '/chemreactHINDI': ChemicalReactionHINDI,
    '/chemreact2': ChemicalReaction2,
    '/chemreact2HINDI': ChemicalReaction2HINDI,
    '/chemreact3': ChemicalReaction3,
    '/chemreact3HINDI': ChemicalReaction3HINDI,
    '/study': StudyOfCompounds,
    '/studyHINDI': StudyOfCompoundsHINDI,
    '/study2': StudyOfCompounds2,
    '/study2HINDI': StudyOfCompounds2HINDI,
    '/metallurgy': Metallurgy,
    '/metallurgyHINDI': MetallurgyHINDI,
    '/metallurgy1': Metallurgy1,
    '/metallurgy1HINDI': Metallurgy1HINDI,

    // Class X Circles

    '/circle1': Circle1,
    '/circle3': Circle3,
    '/circle4': Circle4,

    // Class X Trignometry

    '/trigno1': Trigno1,
    '/trigno2': Trigno2,
    '/trigno3': Trigno3,
    '/trigno4': Trigno4,
    '/trigno5': Trigno5,

    // Class X Algebra

    '/realno': RealNumber,
    '/poly': Polynomials,
    '/linear': Linear,
    '/quadratic': Quadratic,
    '/quadratic2': Quadratic2,
    '/quadratic3': Quadratic3,
    '/ap': AP,
    '/prob1': Probability1,
    '/prob2': Probability2,

    // Class X Coordinate

    '/coord1': Coord1,
    '/coord2': Coord2,
    '/straight': StraightLine,

    // Class X Mensuration

    '/mensuration1': Mensuration1,
};

// ----------------------------------------------------------------------------------

// --------------------------------- C L A S S - 9 ----------------------------------

const component_map_class9 = {
    '/restmotion': RestMotion,
    '/restmotion2': RestMotion2,
    '/restmotion3': RestMotion3,
    '/force1': Force1,
    '/force2': Force2,
    '/force3': Force3,
    '/pressure1': FliudPressureAndTransmission,
    '/pressure2': AtmosphericPressureAndMeasurement,
    '/fluid1': UpthrustAndArchimedesPrinciple,
    '/fluid2': RelativeDensityAndFloatation,
}

// ----------------------------------------------------------------------------------


export const component_map = {
    // -------------------------------- GENERAL ------------------------

    '/': LandingPage,
    '/signup': SignupPage,
    '/login': LoginPage,
    '/terms': Terms,
    '/privacy': Privacy,
    '/refund': Refund,
    '/plan': PlanPage,
    '/contact': ContactPage,
    '/examplefortor': ExampleForceTorque,
    '/examplecharges': ExampleCharges,
    '/dashboard': Dashboard,
    '/sim': PhysicsSim,
    '/lolo': Lolo,
    '/editcontent': EditContent,
    '/home': Home,
    '/products': Products,
    '/home2': Home2,
    '/content-templates': ContentTemplates,
    '/questions': Loadable(() => import(`./pages/Questions.jsx`), { fallback: <Fallback /> }),
    '/schooladmin': Loadable(() => import(`./pages/SchoolAdmin.jsx`), { fallback: <Fallback /> }),
    '/my-student-portal': Loadable(() => import(`./pages/MyStudentPortal.jsx`), { fallback: <Fallback /> }),
    '/assignment': Loadable(() => import(`./pages/Assignment.jsx`), { fallback: <Fallback /> }),
    '/jsadmin/manage-clients': Loadable(() => import(`./pages/ManageClients.jsx`), { fallback: <Fallback /> }),
    '/jsadmin': Loadable(() => import(`./pages/JSAdminPortal.jsx`), { fallback: <Fallback /> }),
    '/services': Services,
    // '/blocks': Blocks,
    '/bit': Bit,
    '/ib': IB,

    '/homecbse-up': HomeCBSEUP,
    '/homecbse-upHINDI': HomeCBSEUPHINDI,
    '/homeicse': HomeICSE,
    '/homeicse-HINDI': HomeICSEHindi,
    '/pryear': Pryear,
    '/offer': Offer,
    '/buy': Buy,
    '/reflection-demo': ReflectionDemo,
    '/fortor-demo': ForceTorqueDemo,
    '/chemreact-demo': ChemicalReactionDemo,
    '/periodic-demo': PeriodicTableDemo,
    '/realno-demo': RealNumberDemo,
    '/quadratic-demo': QuadraticDemo,
    '/editsrc': EditSrc,
    '/practice': Loadable(() => import(`./pages/Practice.jsx`), { fallback: <Fallback /> }),
    '/questiondump': Loadable(() => import(`./pages/QuestionDump.jsx`), { fallback: <Fallback /> }),

    // -------------------------------- CLASS 10 LOL PHYSICS -----------------------------

    // MAHA LOL PAGES ELECTRO
    '/lol-charges': Lol_charges,
    '/lol-resistance': Lol_resistance,
    '/lol-conductor-insulator': Lol_conductor_insulator,
    '/lol-ohmslaw': Lol_ohmslaw,
    '/lol-em1': Lol_EM1,
    '/lol-em1-part2': Lol_EM1_part2,
    '/lol-em2': Lol_EM2,
    '/lol-em3': Lol_EM3,
    '/lol-emf': Lol_emf,
    '/lol-combination-of-resistances': Lol_CombinationofResistances,
    '/lol-electric-power': Lol_ElectricPower,

    // LOL OPTICS
    '/lol-reflection-of-light': Lol_ReflectionOfLight,
    '/lol-spherical-mirrors': Lol_SphericalMirrors,
    '/lol-concave-mirrors': Lol_ConcaveMirrors,
    '/lol-convex-mirrors': Lol_ConvexMirrors,
    '/lol-refraction-of-light': Lol_RefractionOfLight,
    '/lol-prism': Lol_Prism,
    '/lol-spherical-lens': Lol_SphericalLens,
    '/lol-convex-lens': Lol_ConvexLens,
    '/lol-concave-lens': Lol_ConcaveLens,
    '/lol-human-eye-1': Lol_HumanEye1,
    '/lol-human-eye-2': Lol_HumanEye2,
    '/lol-human-eye-3': Lol_HumanEye3,

    // LOL NUCLEAR PHYSICS
    '/lol-nuclear-reactions': Lol_NuclearReactions,

    // LOL MECHANICS
    '/lol-force-and-torque': Lol_ForceAndTorque,
    '/lol-work-and-energy-1': Lol_WorkAndEnergy1,
    '/lol-work-and-energy-2': Lol_WorkAndEnergy2,
    '/lol-work-and-energy-3': Lol_WorkAndEnergy3,
    '/lol-machines': Lol_Machines,
    '/lol-pulleys': Lol_Pulleys,










    // -------------------------------- CLASS 10 CBSE PHYSICS -----------------------------

    '/learn/class10-cbse/reflection-of-light': Class10CbsePhysicsReflectionOfLight,
    '/learn/class10-cbse/spherical-mirrors': Class10CbsePhysicsSphericalMirrors,
    '/learn/class10-cbse/concave-mirrors': Class10CbsePhysicsConcaveMirrors,
    '/learn/class10-cbse/convex-mirrors': Class10CbsePhysicsConvexMirrors,
    '/learn/class10-cbse/refraction-of-light': Class10CbsePhysicsRefractionOfLight,
    '/learn/class10-cbse/prism': Class10CbsePhysicsPrism,
    '/learn/class10-cbse/spherical-lens': Class10CbsePhysicsSphericalLens,
    '/learn/class10-cbse/convex-lens': Class10CbsePhysicsConvexLens,
    '/learn/class10-cbse/concave-lens': Class10CbsePhysicsConcaveLens,
    '/learn/class10-cbse/human-eye-1': Class10CbsePhysicsHumanEye1,
    '/learn/class10-cbse/human-eye-2': Class10CbsePhysicsHumanEye2,
    '/learn/class10-cbse/human-eye-3': Class10CbsePhysicsHumanEye3,

    '/learn/class10-cbse/charges': Class10CbsePhysicsCharges,
    '/learn/class10-cbse/electric-potential-and-electric-current': Class10CbsePhysicsElectricPotentialAndElectricCurrent,
    '/learn/class10-cbse/ohms-law': Class10CbsePhysicsOhmsLaw,
    '/learn/class10-cbse/resistance': Class10CbsePhysicsResistance,
    '/learn/class10-cbse/combination-of-resistances': Class10CbsePhysicsCombinationOfResistances,
    '/learn/class10-cbse/electric-power': Class10CbsePhysicsElectricPower,
    '/learn/class10-cbse/emf': Class10CbsePhysicsEMF,
    '/learn/class10-cbse/magnetic-effects-of-electric-current-1': Class10CbsePhysicsMagneticEffectsOfElectricCurrent,
    '/learn/class10-cbse/magnetic-effects-of-electric-current-2': Class10CbsePhysicsMagneticEffectsOfElectricCurrent2,
    '/learn/class10-cbse/force-on-current-carrying-conductor': Class10CbsePhysicsForceOnCurrentCarryingConductor,
    '/learn/class10-cbse/electromagnetic-induction': Class10CbsePhysicsElectromagneticInduction,

    '/learn/class10-cbse/nuclear-reactions': Class10CbsePhysicsNuclearReactions,

    // ------------------------------ CLASS 10 CBSE CHEMISTRY ---------------------------

    // LOL ORGANIC
    '/lol-organic-chemistry': Lol_OrganicChemistry,
    '/lol-classification-of-organic-compounds': Lol_ClassificationOfOrganicCompounds,
    '/lol-nomenclature': Lol_Nomenclature,
    '/lol-unsaturated-compounds': Lol_UnsaturatedCompounds,
    '/lol-combustion-and-oxidation': Lol_CombustionAndOxidation,
    '/lol-alkanes': Lol_Alkanes,
    '/lol-alkenes': Lol_Alkenes,
    '/lol-alkynes': Lol_Alkynes,
    '/lol-alcohols': Lol_Alcohols,
    '/lol-carboxylic-acids': Lol_CarboxylicAcids,

    // LOL INORGANIC
    '/lol-chemical-reactions': Lol_ChemicalReactions,
    '/lol-types-of-reactions-1': Lol_TypesOfReactions1,
    '/lol-types-of-reactions-2': Lol_TypesOfReactions2,
    '/lol-acids': Lol_Acids,
    '/lol-bases': Lol_Bases,
    '/lol-salts': Lol_Salts,
    '/lol-metals-and-nonmetals-1': Lol_MetalsAndNonmetals1,
    '/lol-metals-and-nonmetals-2': Lol_MetalsAndNonmetals2,
    '/lol-metals-and-nonmetals-3': Lol_MetalsAndNonmetals3,
    '/lol-metals-and-nonmetals-4': Lol_MetalsAndNonmetals4,





    '/learn/class10-cbse/chemical-reactions': Class10CbseChemistryChemicalReactions,
    '/learn/class10-cbse/types-of-reactions-1': Class10CbseChemistryTypesOfReactions1,
    '/learn/class10-cbse/types-of-reactions-2': Class10CbseChemistryTypesOfReactions2,
    '/learn/class10-cbse/acids': Class10CbseChemistryAcids,
    '/learn/class10-cbse/bases': Class10CbseChemistryBases,
    '/learn/class10-cbse/salts': Class10CbseChemistrySalts,
    '/learn/class10-cbse/metals-and-nonmetals-1': Class10CbseChemistryMetalsAndNonmetals1,
    '/learn/class10-cbse/metals-and-nonmetals-2': Class10CbseChemistryMetalsAndNonmetals2,
    '/learn/class10-cbse/metals-and-nonmetals-3': Class10CbseChemistryMetalsAndNonmetals3,
    '/learn/class10-cbse/metals-and-nonmetals-4': Class10CbseChemistryMetalsAndNonmetals4,

    '/learn/class10-cbse/organic-chemistry': Class10CbseChemistryOrganicChemistry,
    '/learn/class10-cbse/classification-of-organic-compounds': Class10CbseChemistryClassificationOfOrganicCompounds,
    '/learn/class10-cbse/nomenclature': Class10CbseChemistryNomenclature,
    '/learn/class10-cbse/unsaturated-compounds': Class10CbseChemistryUnsaturatedCompounds,
    '/learn/class10-cbse/combustion-and-oxidation': Class10CbseChemistryCombustionAndOxidation,
    '/learn/class10-cbse/alkanes': Class10CbseChemistryAlkanes,
    '/learn/class10-cbse/alkenes': Class10CbseChemistryAlkenes,
    '/learn/class10-cbse/alkynes': Class10CbseChemistryAlkynes,
    '/learn/class10-cbse/alcohols': Class10CbseChemistryAlcohols,
    '/learn/class10-cbse/carboxylic-acids': Class10CbseChemistryCarboxylicAcids,

    // ------------------------------ CLASS 10 CBSE BIOLOGY ---------------------------

    '/learn/class10-cbse/nutrition': Loadable(() => import('./learn/class10-cbse/biology/LifeProcesses/Nutrition.jsx'), { fallback: <Fallback /> }),
    '/learn/class10-cbse/respiration': Loadable(() => import('./learn/class10-cbse/biology/LifeProcesses/Respiration.jsx'), { fallback: <Fallback /> }),
    '/learn/class10-cbse/transportation': Loadable(() => import('./learn/class10-cbse/biology/LifeProcesses/Transportation.jsx'), { fallback: <Fallback /> }),
    '/learn/class10-cbse/excretion': Loadable(() => import('./learn/class10-cbse/biology/LifeProcesses/Excretion.jsx'), { fallback: <Fallback /> }),

    '/learn/class10-cbse/control-coordination': Loadable(() => import('./learn/class10-cbse/biology/ControlCoordination/ControlCoordination.jsx'), { fallback: <Fallback /> }),

    '/learn/class10-cbse/reproduction': Loadable(() => import('./learn/class10-cbse/biology/Reproduction/Reproduction.jsx'), { fallback: <Fallback /> }),
    '/learn/class10-cbse/reproduction-2': Loadable(() => import('./learn/class10-cbse/biology/Reproduction/Reproduction2.jsx'), { fallback: <Fallback /> }),

    '/learn/class10-cbse/heredity-evolution': Loadable(() => import('./learn/class10-cbse/biology/HeredityEvolution/HeredityEvolution.jsx'), { fallback: <Fallback /> }),


    // --------------------------------- CLASS 10 CBSE MATHS ------------------------------

    // LOL NUMBER
    '/lol-real-numbers': Lol_RealNumbers,

    // LOL ALGEBRA
    '/lol-polynomials': Lol_Polynomials,
    '/lol-linear-equations-in-two-variables': Lol_LinearEquationsInTwoVariables,
    '/lol-quadratic-equations': Lol_QuadraticEquations,
    '/lol-applications-of-quadratic-equations-1': Lol_ApplicationsOfQuadraticEquations1,
    '/lol-applications-of-quadratic-equations-2': Lol_ApplicationsOfQuadraticEquations2,
    '/lol-arithmetic-progressions': Lol_ArithmeticProgressions,

    // LOL COORDINATE
    '/lol-coordinate-geometry': Lol_CoordinateGeometry,
    '/lol-section-formula': Lol_SectionFormula,
    '/lol-straight-line': Lol_StraightLine,

    // LOL TRIGONOMTERY
    '/lol-trigonometry': Lol_Trigonometry,
    '/lol-heights-and-distances-1': Lol_HeightsAndDistances1,
    '/lol-heights-and-distances-2': Lol_HeightsAndDistances2,

    // LOL GEOMETRY
    '/lol-circles': Lol_Circles,
    '/lol-areas-related-to-circles': Lol_AreasRelatedToCircles,
    '/lol-sectors-and-segments': Lol_SectorsAndSegments,

    // LOL MENSURATION
    '/lol-mensuration': Lol_Mensuration,

    // LOL PROBABILITY
    '/lol-probability-1': Lol_Probability1,
    '/lol-probability-2': Lol_Probability2,






    '/learn/class10-cbse/real-numbers': Class10CbseMathsRealNumbers,
    '/learn/class10-cbse/polynomials': Class10CbseMathsPolynomials,
    '/learn/class10-cbse/linear-equations-in-two-variables': Class10CbseMathsLinearEquationsInTwoVariables,
    '/learn/class10-cbse/quadratic-equations': Class10CbseMathsQuadraticEquations,
    '/learn/class10-cbse/applications-of-quadratic-equations-1': Class10CbseMathsApplicationsOfQuadraticEquations1,
    '/learn/class10-cbse/applications-of-quadratic-equations-2': Class10CbseMathsApplicationsOfQuadraticEquations2,
    '/learn/class10-cbse/arithmetic-progressions': Class10CbseMathsArithmeticProgressions,

    '/learn/class10-cbse/coordinate-geometry': Class10CbseMathsCoordinateGeometry,
    '/learn/class10-cbse/section-formula': Class10CbseMathsSectionFormula,
    '/learn/class10-cbse/straight-line': Class10CbseMathsStraightLine,

    '/learn/class10-cbse/trigonometry': Class10CbseMathsTrigonometry,
    '/learn/class10-cbse/heights-and-distances-1': Class10CbseMathsHeightsAndDistances1,
    '/learn/class10-cbse/heights-and-distances-2': Class10CbseMathsHeightsAndDistances2,

    '/learn/class10-cbse/circles': Class10CbseMathsCircles,
    '/learn/class10-cbse/areas-related-to-circles-1': Class10CbseMathsAreasRelatedToCircles1,
    '/learn/class10-cbse/areas-related-to-circles-2': Class10CbseMathsAreasRelatedToCircles2,

    '/learn/class10-cbse/mensuration': Class10CbseMathsMensuration,

    '/learn/class10-cbse/probability-1': Class10CbseMathsProbability1,
    '/learn/class10-cbse/probability-2': Class10CbseMathsProbability2,

    // -------------------------------- CLASS 10 ICSE PHYSICS -----------------------------

    '/learn/class10-icse/force-and-torque': Class10IcsePhysicsForceAndTorque,
    '/learn/class10-icse/work-and-energy-1': Class9CbsePhysicsWorkAndEnergy1, // -
    '/learn/class10-icse/work-and-energy-2': Class9CbsePhysicsWorkAndEnergy2, // -
    '/learn/class10-icse/work-and-energy-3': Class9CbsePhysicsWorkAndEnergy3, // -
    '/learn/class10-icse/machines': Class10IcsePhysicsMachines,
    '/learn/class10-icse/pulleys': Class10IcsePhysicsPulleys,

    '/learn/class10-icse/refraction-of-light': Class10IcsePhysicsRefractionOfLight, // -
    '/learn/class10-icse/prism': Class10IcsePhysicsPrism, // -
    '/learn/class10-icse/spherical-lens': Class10IcsePhysicsSphericalLens, // -
    '/learn/class10-icse/convex-lens': Class10IcsePhysicsConvexLens, // -
    '/learn/class10-icse/concave-lens': Class10IcsePhysicsConcaveLens, // -

    '/learn/class10-icse/sound': Class9CbsePhysicsSound, // -
    '/learn/class10-icse/sound-2': Class9CbsePhysicsSound2, // -

    '/learn/class10-icse/charges': Class10IcsePhysicsCharges, // -
    '/learn/class10-icse/electric-potential-and-electric-current': Class10IcsePhysicsElectricPotentialAndElectricCurrent, // -
    '/learn/class10-icse/ohms-law': Class10IcsePhysicsOhmsLaw, // -
    '/learn/class10-icse/resistance': Class10IcsePhysicsResistance, // -
    '/learn/class10-icse/combination-of-resistances': Class10IcsePhysicsCombinationOfResistances, // -
    '/learn/class10-icse/electric-power': Class10IcsePhysicsElectricPower, // -
    '/learn/class10-icse/emf': Class10IcsePhysicsEMF, // -
    '/learn/class10-icse/em1': Class10IcsePhysicsEM1, // -
    '/learn/class10-icse/em2': Class10IcsePhysicsEM2, // -
    '/learn/class10-icse/em3': Class10IcsePhysicsEM3, // -


    '/learn/class10-icse/magnetic-effects-of-electric-current-1': Class10CbsePhysicsMagneticEffectsOfElectricCurrent, // -
    '/learn/class10-icse/magnetic-effects-of-electric-current-2': Class10CbsePhysicsMagneticEffectsOfElectricCurrent2, // -
    '/learn/class10-icse/force-on-current-carrying-conductor': Class10CbsePhysicsForceOnCurrentCarryingConductor, // -
    '/learn/class10-icse/electromagnetic-induction': Class10CbsePhysicsElectromagneticInduction, // -


    '/learn/class10-icse/calorimetry-1': Class10IcsePhysicsCalorimetry1,
    '/learn/class10-icse/calorimetry-2': Class10IcsePhysicsCalorimetry2,

    '/learn/class10-icse/radioactivity': Class10IcsePhysicsRadioactivity,
    '/learn/class10-icse/nuclear-reactions': Class10IcsePhysicsNuclearReactions, // -

    // -------------------------------- CLASS 10 ICSE CHEMISTRY -----------------------------

    '/periodic-table-1': Class10IcseChemistryPeriodicTable1,
    '/periodic-table-2': Class10IcseChemistryPeriodicTable2,

    '/learn/class10-icse/periodic-table-1': Class9IcseChemistryPeriodicTable1, // -
    '/learn/class10-icse/periodic-table-2': Class9IcseChemistryPeriodicTable2, // -
    '/learn/class10-icse/periodic-table-3': Class9IcseChemistryPeriodicTable3, // -
    '/learn/class10-icse/chemical-bonding-1': Class9IcseChemistryAtomicStructureAndChemicalBonding3, // -
    '/learn/class10-icse/chemical-bonding-2': Class9IcseChemistryAtomicStructureAndChemicalBonding4, // -
    '/learn/class10-icse/acids': Class10IcseChemistryAcids,
    '/learn/class10-icse/bases': Class10IcseChemistryBases,
    '/learn/class10-icse/salts': Class10IcseChemistrySalts,
    '/learn/class10-icse/aluminium-extraction': Class10IcseChemistryAluminiumExtraction,
    '/learn/class10-icse/metallurgy': Class10IcseChemistryMetallurgy,
    '/learn/class10-icse/metals-nonmetals': Class9IcseChemistryPeriodicTable3, // -
    '/learn/class10-icse/ammonia': Class10IcseChemistryAmmonia,
    '/learn/class10-icse/nitric-acid': Class10IcseChemistryNitricAcid,

    '/learn/class10-icse/mole-concept-1': Class10IcseChemistryMoleConcept1,
    '/learn/class10-icse/mole-concept-2': Class10IcseChemistryMoleConcept2,
    '/learn/class10-icse/mole-concept-3': Class10IcseChemistryMoleConcept3,
    '/learn/class10-icse/mole-concept-4': Class10IcseChemistryMoleConcept4,

    '/learn/class10-icse/organic-chemistry': Class10IcseChemistryOrganicChemistry,
    '/learn/class10-icse/classification-of-organic-compounds': Class10IcseChemistryClassificationOfOrganicCompounds,
    '/learn/class10-icse/nomenclature': Class10IcseChemistryNomenclature,
    '/learn/class10-icse/unsaturated-compounds': Class10IcseChemistryUnsaturatedCompounds,
    '/learn/class10-icse/combustion-and-oxidation': Class10IcseChemistryCombustionAndOxidation,
    '/learn/class10-icse/alkanes': Class10IcseChemistryAlkanes,
    '/learn/class10-icse/alkenes': Class10IcseChemistryAlkenes,
    '/learn/class10-icse/alkynes': Class10IcseChemistryAlkynes,
    '/learn/class10-icse/alcohols': Class10IcseChemistryAlcohols,
    '/learn/class10-icse/carboxylic-acids': Class10IcseChemistryCarboxylicAcids,

    // -------------------------------- CLASS 10 ICSE MATHS -----------------------------

    '/learn/class10-icse/quadratic-equations': Class10IcseMathsQuadraticEquations, // -
    '/learn/class10-icse/applications-of-quadratic-equations-1': Class10IcseMathsApplicationsOfQuadraticEquations1, // -
    '/learn/class10-icse/applications-of-quadratic-equations-2': Class10IcseMathsApplicationsOfQuadraticEquations2, // -
    '/learn/class10-icse/arithmetic-progressions': Class10IcseMathsArithmeticProgressions, // -
    '/learn/class10-icse/polynomials': Class10CbseMathsPolynomials, // -

    '/learn/class10-icse/coordinate-geometry': Class10IcseMathsCoordinateGeometry, // -
    '/learn/class10-icse/section-formula': Class10IcseMathsSectionFormula, // -
    '/learn/class10-icse/straight-line': Class10IcseMathsStraightLine, // -

    '/learn/class10-icse/trigonometry': Class10IcseMathsTrigonometry,
    '/learn/class10-icse/heights-and-distances-1': Class10IcseMathsHeightsAndDistances1,
    '/learn/class10-icse/heights-and-distances-2': Class10IcseMathsHeightsAndDistances2,

    '/learn/class10-icse/circles': Class10IcseMathsCircles, // -
    '/learn/class10-icse/areas-related-to-circles-1': Class10IcseMathsAreasRelatedToCircles1, // -
    '/learn/class10-icse/areas-related-to-circles-2': Class10IcseMathsAreasRelatedToCircles2, // -

    '/learn/class10-icse/mensuration': Class10IcseMathsMensuration, // -

    '/learn/class10-icse/probability-1': Class10IcseMathsProbability1,
    '/learn/class10-icse/probability-2': Class10IcseMathsProbability2,

    // -------------------------------- CLASS 9 CBSE PHYSICS -----------------------------

    '/lol9': Class9CbsePhysicsLol,

    '/learn/class9-cbse/motion-1': Class9CbsePhysicsMotion1,
    '/learn/class9-cbse/motion-2': Class9CbsePhysicsMotion2,
    '/learn/class9-cbse/motion-3': Class9CbsePhysicsMotion3,
    '/learn/class9-cbse/force': Class9CbsePhysicsForce,
    '/learn/class9-cbse/laws-of-motion': Class9CbsePhysicsLawsOfMotion,
    '/learn/class9-cbse/gravitation': Class9CbsePhysicsGravitation,

    '/learn/class9-cbse/atmospheric-pressure': Class9IcsePhysicsAtmosphericPressure,
    '/learn/class9-cbse/pressure-in-fluids': Class9IcsePhysicsPressureInFluids,
    '/learn/class9-cbse/upthrust-and-archimedes-principle': Class9IcsePhysicsUpthrustAndArchimedesPrinciple,
    '/learn/class9-cbse/relative-density-and-floatation': Class9IcsePhysicsRelativeDensityAndFloatation,

    '/learn/class9-cbse/work-and-energy-1': Class9CbsePhysicsWorkAndEnergy1,
    '/learn/class9-cbse/work-and-energy-2': Class9CbsePhysicsWorkAndEnergy2,
    '/learn/class9-cbse/work-and-energy-3': Class9CbsePhysicsWorkAndEnergy3,

    '/learn/class9-cbse/sound': Class9CbsePhysicsSound,
    '/learn/class9-cbse/sound-2': Class9CbsePhysicsSound2,

    // -------------------------------- CLASS 9 CBSE CHEMISTRY -----------------------------

    '/learn/class9-cbse/matter': Class9CbseChemistryMatter1,
    '/learn/class9-cbse/matter-2': Class9CbseChemistryMatter2,
    '/learn/class9-cbse/is-matter-around-us-pure-1': Class9CbseChemistryIsMatterAroundUsPure1,
    '/learn/class9-cbse/is-matter-around-us-pure-2': Class9CbseChemistryIsMatterAroundUsPure2,
    '/learn/class9-cbse/atoms-and-molecules-1': Class9CbseChemistryAtomsAndMolecules1,
    '/learn/class9-cbse/atoms-and-molecules-2': Class9CbseChemistryAtomsAndMolecules2,
    '/learn/class9-cbse/structure-of-the-atom-1': Class9CbseChemistryStructureOfTheAtom1,
    '/learn/class9-cbse/structure-of-the-atom-2': Class9CbseChemistryStructureOfTheAtom2,

    // -------------------------------- CLASS 9 CBSE MATHS -----------------------------

    '/learn/class9-cbse/number-system': Class9CbseMathsNumberSystem,
    '/learn/class9-cbse/number-system-2': Class9CbseMathsNumberSystem2,

    '/learn/class9-cbse/polynomials': Class9CbseMathsPolynomials,
    '/learn/class9-cbse/polynomials-2': Class9CbseMathsPolynomials2,
    '/learn/class9-cbse/linear-equations-in-two-variables': Class9CbseMathsLinearEquationsInTwoVariables,

    '/learn/class9-cbse/coordinate-geometry': Class9CbseMathsCoordinateGeometry,

    '/learn/class9-cbse/euclids-geometry': Class9CbseMathsEuclidsGeometry,
    '/learn/class9-cbse/lines-and-angles': Class9CbseMathsLinesAndAngles,
    '/learn/class9-cbse/triangles': Class9CbseMathsTriangles,
    '/learn/class9-cbse/quadrilaterals': Class9CbseMathsQuadrilaterals,
    '/learn/class9-cbse/circles-1': Class9CbseMathsCircles1,
    '/learn/class9-cbse/circles-2': Class9CbseMathsCircles2,

    '/learn/class9-cbse/herons-formula': Class9CbseMathsHeronsFormula,
    '/learn/class9-cbse/surface-areas-and-volumes': Class9CbseMathsSurfaceAreasAndVolumes,

    '/learn/class9-cbse/statistics': Class9CbseMathsStatistics,


    // -------------------------------- CLASS 9 ICSE PHYSICS -----------------------------

    '/learn/class9-icse/motion-1': Class9CbsePhysicsMotion1, // -
    '/learn/class9-icse/motion-2': Class9CbsePhysicsMotion2, // -
    '/learn/class9-icse/motion-3': Class9CbsePhysicsMotion3, // -
    '/learn/class9-icse/force': Class9CbsePhysicsForce, // -
    '/learn/class9-icse/laws-of-motion': Class9CbsePhysicsLawsOfMotion, // -
    '/learn/class9-icse/gravitation': Class9CbsePhysicsGravitation, // -

    '/learn/class9-icse/atmospheric-pressure': Class9IcsePhysicsAtmosphericPressure,
    '/learn/class9-icse/pressure-in-fluids': Class9IcsePhysicsPressureInFluids,
    '/learn/class9-icse/upthrust-and-archimedes-principle': Class9IcsePhysicsUpthrustAndArchimedesPrinciple,
    '/learn/class9-icse/relative-density-and-floatation': Class9IcsePhysicsRelativeDensityAndFloatation,
    '/learnHINDI/class9-icse-HINDI/physics-HINDI/fluids-HINDI/AtmosphericPressureHINDI': Class9IcsePhysicsAtmosphericPressureHINDI,

    '/learn/class9-icse/heat-and-energy': Class9IcsePhysicsHeatAndEnergy,
    '/learn/class9-icse/heat-and-energy-2': Class9IcsePhysicsHeatAndEnergy2,
    '/learn/class9-icse/heat-and-energy-3': Class9IcsePhysicsHeatAndEnergy3,
    '/learnHINDI/class9-icse-HINDI/physics-HINDI/heat-HINDI/HeatAndEnergyHINDI': Class9IcsePhysicsHeatAndEnergyHINDI,

    '/learn/class9-icse/reflection-of-light': Class10CbsePhysicsReflectionOfLight, // -
    '/learn/class9-icse/spherical-mirrors': Class10CbsePhysicsSphericalMirrors, // -
    '/learn/class9-icse/concave-mirrors': Class10CbsePhysicsConcaveMirrors, // -
    '/learn/class9-icse/convex-mirrors': Class10CbsePhysicsConvexMirrors, // -

    '/learn/class9-icse/sound': Class9CbsePhysicsSound, // -

    '/learn/class9-icse/current-electricity': Class9IcsePhysicsCurrentElectricity,
    '/learn/class9-icse/current-electricity-2': Class9IcsePhysicsCurrentElectricity2,

    '/learn/class9-icse/magnetism': Class9IcsePhysicsMagnetism,
    '/learn/class9-icse/magnetism-2': Class9IcsePhysicsMagnetism2,

    // -------------------------------- CLASS 9 ICSE CHEMISTRY -----------------------------

    '/learn/class9-icse/the-language-of-chemistry-1': Class9IcseChemistryTheLanguageOfChemistry1,
    '/learn/class9-icse/the-language-of-chemistry-2': Class9IcseChemistryTheLanguageOfChemistry2,
    '/learn/class9-icse/chemical-changes-and-reactions-1': Class9IcseChemistryChemicalChangesAndReactions1,
    '/learn/class9-icse/chemical-changes-and-reactions-2': Class9IcseChemistryChemicalChangesAndReactions2,
    '/learn/class9-icse/chemical-changes-and-reactions-3': Class9IcseChemistryChemicalChangesAndReactions3,
    '/learn/class9-icse/periodic-table-1': Class9IcseChemistryPeriodicTable1,
    '/learn/class9-icse/periodic-table-2': Class9IcseChemistryPeriodicTable2,
    '/learn/class9-icse/periodic-table-3': Class9IcseChemistryPeriodicTable3,
    '/learn/class9-icse/hydrogen-1': Class9IcseChemistryHydrogen1,
    '/learn/class9-icse/hydrogen-2': Class9IcseChemistryHydrogen2,
    '/learn/class9-icse/hydrogen-3': Class9IcseChemistryHydrogen3,
    '/learn/class9-icse/water-1': Class9IcseChemistryWater1,
    '/learn/class9-icse/water-2': Class9IcseChemistryWater2,
    '/learn/class9-icse/water-3': Class9IcseChemistryWater3,
    '/learn/class9-icse/atmospheric-pollution': Class9IcseChemistryAtmosphericPollution,

    '/learn/class9-icse/atomic-structure-and-chemical-bonding-1': Class9IcseChemistryAtomicStructureAndChemicalBonding1,
    '/learn/class9-icse/atomic-structure-and-chemical-bonding-2': Class9IcseChemistryAtomicStructureAndChemicalBonding2,
    '/learn/class9-icse/atomic-structure-and-chemical-bonding-3': Class9IcseChemistryAtomicStructureAndChemicalBonding3,
    '/learn/class9-icse/atomic-structure-and-chemical-bonding-4': Class9IcseChemistryAtomicStructureAndChemicalBonding4,
    '/learn/class9-icse/study-of-gas-laws-1': Class9IcseChemistryStudyOfGasLaws1,
    '/learn/class9-icse/study-of-gas-laws-2': Class9IcseChemistryStudyOfGasLaws2,

    // -------------------------------- CLASS 9 ICSE MATHS -----------------------------

    '/learn/class9-icse/number-system': Class9CbseMathsNumberSystem,
    '/learn/class9-icse/number-system-2': Class9CbseMathsNumberSystem2,

    '/learn/class9-icse/polynomials': Class9CbseMathsPolynomials,
    '/learn/class9-icse/polynomials-2': Class9CbseMathsPolynomials2,
    '/learn/class9-icse/linear-equations-in-two-variables': Class9CbseMathsLinearEquationsInTwoVariables,

    '/learn/class9-icse/coordinate-geometry': Class9CbseMathsCoordinateGeometry,

    '/learn/class9-icse/euclids-geometry': Class9CbseMathsEuclidsGeometry,
    '/learn/class9-icse/lines-and-angles': Class9CbseMathsLinesAndAngles,
    '/learn/class9-icse/triangles': Class9CbseMathsTriangles,
    '/learn/class9-icse/quadrilaterals': Class9CbseMathsQuadrilaterals,
    '/learn/class9-icse/circles-1': Class9CbseMathsCircles1,
    '/learn/class9-icse/circles-2': Class9CbseMathsCircles2,

    '/learn/class9-icse/herons-formula': Class9CbseMathsHeronsFormula,
    '/learn/class9-icse/surface-areas-and-volumes': Class9CbseMathsSurfaceAreasAndVolumes,

    '/learn/class9-icse/statistics': Class9CbseMathsStatistics,



    // -----------------------------------------------------------------------
}



// ----------------------------------------------------------------------------------
// Append components with Navbar

// import {
//     Grid
// } from '@mui/material';

// const Navbar = () => {
//     const [isLoggedIn, setIsLoggedIn] = React.useState(null);

//     React.useEffect(() => { (async () => {
//         const res = await makeRequest({}, '/isloggedin');
//         setIsLoggedIn(res);
//     })() }, []);
//     if (window.location.pathname == '/') {
//         return
//     } else if (isLoggedIn == true) {
//         return (<NavbarLoggedIn />)
//     } else if (isLoggedIn == false) {
//         return (
//             <Grid
//                 container
//                 justifyContent="space-between"
//                 alignItems="center"
//                 sx={{ backgroundColor: "#ae4e7a", filter: "drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.4))" }}
//             >
//                 <Grid item width='120px'>
//                     <Grid
//                         container
//                         justifyContent="left"
//                         alignItems="center"
//                     >
//                         <Grid item xs={6} paddingTop='10px' paddingBottom='10px' paddingLeft='15px'>
//                             <a href="/">
//                                 <img
//                                     src="/landingpage/assets/img/logo/jadu-white.png"
//                                     style={{ width: "100px" }}
//                                 />
//                             </a>
//                         </Grid>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         )
//     }
// }

// const component_map2 = {};

// Object.keys(component_map).map((key, index) => {
//     if (key != '/') {
//         const Component = component_map[key]
//         component_map2[key] = () => (<><Navbar /><Component /></>)
//     } else {
//         component_map2[key] = component_map[key]
//     }
// })

// ----------------------------------------------------------------------------------



// Import all needed Component for this tutorial

import {
    BrowserRouter as Router,
    Route,
    Routes
} from "react-router-dom";

const api_respnse = [

    // ----------------------------------- GENERAL -------------------------------------

    { route: '/' },
    // {route: '/signup'},
    // {route: '/login'},
    { route: '/terms' },
    { route: '/privacy' },
    { route: '/refund' },
    { route: '/plan' },
    { route: '/contact' },
    // {route: '/examplefortor'},
    // {route: '/examplecharges'},
    // {route: '/dashboard'},
    // {route: '/sim'},
    // {route: '/lolo'},
    { route: '/editcontent' },
    { route: '/home' },
    { route: '/products' },
    // {route: '/home2'},
    { route: '/content-templates' },
    { route: '/questions' },
    { route: '/schooladmin' },
    { route: '/my-student-portal' },
    { route: '/assignment' },
    { route: '/jsadmin/manage-clients' },
    {route: '/jsadmin'},
    { route: '/services' },
    // {route: '/blocks'},
    { route: '/bit' },
    { route: '/ib' },

    { route: '/homecbse-up' },
    { route: '/homecbse-upHINDI' },
    { route: '/homeicse' },
    { route: '/homeicse-HINDI' },
    { route: '/pryear' },
    { route: '/offer' },
    { route: '/buy' },
    { route: '/reflection-demo' },
    { route: '/fortor-demo' },
    { route: '/chemreact-demo' },
    { route: '/periodic-demo' },
    { route: '/realno-demo' },
    { route: '/quadratic-demo' },
    { route: '/editsrc' },
    { route: '/practice' },
    { route: '/questiondump' },

    // // --------------------------------- CLASS 10 LOL PHYSICS --------------------------------

    // // MAHA LOL PAGES ELECTRO
    // { route: '/lol-charges' },
    // { route: '/lol-resistance' },
    // { route: '/lol-conductor-insulator' },
    // { route: '/lol-ohmslaw' },
    // { route: '/lol-em1' },
    // { route: '/lol-em1-part2' },
    // { route: '/lol-em2' },
    // { route: '/lol-em3' },
    // { route: '/lol-emf' },
    // { route: '/lol-combination-of-resistances' },
    // { route: '/lol-electric-power' },

    // // LOL OPTICS
    // { route: '/lol-reflection-of-light' },
    // { route: '/lol-spherical-mirrors' },
    // { route: '/lol-concave-mirrors' },
    // { route: '/lol-convex-mirrors' },
    // { route: '/lol-refraction-of-light' },
    // { route: '/lol-prism' },
    // { route: '/lol-spherical-lens' },
    // { route: '/lol-convex-lens' },
    // { route: '/lol-concave-lens' },
    // { route: '/lol-human-eye-1' },
    // { route: '/lol-human-eye-2' },
    // { route: '/lol-human-eye-3' },

    // // LOL NUCLEAR PHYSICS
    // { route: '/lol-nuclear-reactions' },

    // LOL MECHANICS
    { route: '/lol-force-and-torque' },
    { route: '/lol-work-and-energy-1' },
    { route: '/lol-work-and-energy-2' },
    { route: '/lol-work-and-energy-3' },
    { route: '/lol-machines' },
    { route: '/lol-pulleys' },








    // --------------------------------- CLASS 10 CBSE PHYSICS --------------------------------

    { route: '/learn/class10-cbse/reflection-of-light' },
    { route: '/learn/class10-cbse/spherical-mirrors' },
    { route: '/learn/class10-cbse/concave-mirrors' },
    { route: '/learn/class10-cbse/convex-mirrors' },
    { route: '/learn/class10-cbse/refraction-of-light' },
    { route: '/learn/class10-cbse/prism' },
    { route: '/learn/class10-cbse/spherical-lens' },
    { route: '/learn/class10-cbse/convex-lens' },
    { route: '/learn/class10-cbse/concave-lens' },
    { route: '/learn/class10-cbse/human-eye-1' },
    { route: '/learn/class10-cbse/human-eye-2' },
    { route: '/learn/class10-cbse/human-eye-3' },

    { route: '/learn/class10-cbse/charges' },
    { route: '/learn/class10-cbse/electric-potential-and-electric-current' },
    { route: '/learn/class10-cbse/ohms-law' },
    { route: '/learn/class10-cbse/resistance' },
    { route: '/learn/class10-cbse/combination-of-resistances' },
    { route: '/learn/class10-cbse/electric-power' },
    { route: '/learn/class10-cbse/emf' },
    { route: '/learn/class10-cbse/magnetic-effects-of-electric-current-1' },
    { route: '/learn/class10-cbse/magnetic-effects-of-electric-current-2' },
    { route: '/learn/class10-cbse/force-on-current-carrying-conductor' },
    { route: '/learn/class10-cbse/electromagnetic-induction' },

    { route: '/learn/class10-cbse/nuclear-reactions' },

    // --------------------------------- CLASS 10 CBSE CHEMISTRY --------------------------------

    // // LOL ORGANIC
    // { route: '/lol-organic-chemistry' },
    // { route: '/lol-classification-of-organic-compounds' },
    // { route: '/lol-nomenclature' },
    // { route: '/lol-unsaturated-compounds' },
    // { route: '/lol-combustion-and-oxidation' },
    // { route: '/lol-alkanes' },
    // { route: '/lol-alkenes' },
    // { route: '/lol-alkynes' },
    // { route: '/lol-alcohols' },
    // { route: '/lol-carboxylic-acids' },

    // // LOL INORGANIC
    // { route: '/lol-chemical-reactions' },
    // { route: '/lol-types-of-reactions-1' },
    // { route: '/lol-types-of-reactions-2' },
    // { route: '/lol-acids' },
    // { route: '/lol-bases' },
    // { route: '/lol-salts' },
    // { route: '/lol-metals-and-nonmetals-1' },
    // { route: '/lol-metals-and-nonmetals-2' },
    // { route: '/lol-metals-and-nonmetals-3' },
    // { route: '/lol-metals-and-nonmetals-4' },



    { route: '/learn/class10-cbse/chemical-reactions' },
    { route: '/learn/class10-cbse/types-of-reactions-1' },
    { route: '/learn/class10-cbse/types-of-reactions-2' },
    { route: '/learn/class10-cbse/acids' },
    { route: '/learn/class10-cbse/bases' },
    { route: '/learn/class10-cbse/salts' },
    { route: '/learn/class10-cbse/metals-and-nonmetals-1' },
    { route: '/learn/class10-cbse/metals-and-nonmetals-2' },
    { route: '/learn/class10-cbse/metals-and-nonmetals-3' },
    { route: '/learn/class10-cbse/metals-and-nonmetals-4' },

    { route: '/learn/class10-cbse/organic-chemistry' },
    { route: '/learn/class10-cbse/classification-of-organic-compounds' },
    { route: '/learn/class10-cbse/nomenclature' },
    { route: '/learn/class10-cbse/unsaturated-compounds' },
    { route: '/learn/class10-cbse/combustion-and-oxidation' },
    { route: '/learn/class10-cbse/alkanes' },
    { route: '/learn/class10-cbse/alkenes' },
    { route: '/learn/class10-cbse/alkynes' },
    { route: '/learn/class10-cbse/alcohols' },
    { route: '/learn/class10-cbse/carboxylic-acids' },

    // --------------------------------- CLASS 10 CBSE BIOLOGY --------------------------------

    { route: '/learn/class10-cbse/nutrition' },
    { route: '/learn/class10-cbse/respiration' },
    { route: '/learn/class10-cbse/transportation' },
    { route: '/learn/class10-cbse/excretion' },
    { route: '/learn/class10-cbse/control-coordination' },
    { route: '/learn/class10-cbse/reproduction' },
    { route: '/learn/class10-cbse/reproduction-2' },
    { route: '/learn/class10-cbse/heredity-evolution' },




    // --------------------------------- CLASS 10 CBSE MATHS --------------------------------

    // LOL NUMBER
    { route: '/lol-real-numbers' },

    // LOL ALGEBRA
    { route: '/lol-polynomials' },
    { route: '/lol-linear-equations-in-two-variables' },
    { route: '/lol-quadratic-equations' },
    { route: '/lol-applications-of-quadratic-equations-1' },
    { route: '/lol-applications-of-quadratic-equations-2' },
    { route: '/lol-arithmetic-progressions' },

    // LOL COORDINATE
    { route: '/lol-coordinate-geometry' },
    { route: '/lol-section-formula' },
    { route: '/lol-straight-line' },

    // LOL TRIGONOMETRY
    { route: '/lol-trigonometry' },
    { route: '/lol-heights-and-distances-1' },
    { route: '/lol-heights-and-distances-2' },

    // LOL GEOMETRY
    { route: '/lol-circles' },
    { route: '/lol-areas-related-to-circles' },
    { route: '/lol-sectors-and-segments' },

    // LOL MENSURATION
    { route: '/lol-mensuration' },

    // LOL PROBABILITY
    { route: '/lol-probability-1' },
    { route: '/lol-probability-2' },







    { route: '/learn/class10-cbse/real-numbers' },
    { route: '/learn/class10-cbse/polynomials' },
    { route: '/learn/class10-cbse/linear-equations-in-two-variables' },
    { route: '/learn/class10-cbse/quadratic-equations' },
    { route: '/learn/class10-cbse/applications-of-quadratic-equations-1' },
    { route: '/learn/class10-cbse/applications-of-quadratic-equations-2' },
    { route: '/learn/class10-cbse/arithmetic-progressions' },

    { route: '/learn/class10-cbse/coordinate-geometry' },
    { route: '/learn/class10-cbse/section-formula' },
    { route: '/learn/class10-cbse/straight-line' },

    { route: '/learn/class10-cbse/trigonometry' },
    { route: '/learn/class10-cbse/heights-and-distances-1' },
    { route: '/learn/class10-cbse/heights-and-distances-2' },

    { route: '/learn/class10-cbse/circles' },
    { route: '/learn/class10-cbse/areas-related-to-circles-1' },
    { route: '/learn/class10-cbse/areas-related-to-circles-2' },

    { route: '/learn/class10-cbse/mensuration' },

    { route: '/learn/class10-cbse/probability-1' },
    { route: '/learn/class10-cbse/probability-2' },

    // --------------------------------- CLASS 10 ICSE PHYSICS --------------------------------

    { route: '/learn/class10-icse/force-and-torque' },
    { route: '/learn/class10-icse/work-and-energy-1' },
    { route: '/learn/class10-icse/work-and-energy-2' },
    { route: '/learn/class10-icse/work-and-energy-3' },
    { route: '/learn/class10-icse/machines' },
    { route: '/learn/class10-icse/pulleys' },

    { route: '/learn/class10-icse/refraction-of-light' },
    { route: '/learn/class10-icse/prism' },
    { route: '/learn/class10-icse/spherical-lens' },
    { route: '/learn/class10-icse/convex-lens' },
    { route: '/learn/class10-icse/concave-lens' },

    { route: '/learn/class10-icse/sound' },
    { route: '/learn/class10-icse/sound-2' },

    { route: '/learn/class10-icse/charges' },
    { route: '/learn/class10-icse/electric-potential-and-electric-current' },
    { route: '/learn/class10-icse/ohms-law' },
    { route: '/learn/class10-icse/resistance' },
    { route: '/learn/class10-icse/combination-of-resistances' },
    { route: '/learn/class10-icse/electric-power' },
    { route: '/learn/class10-icse/emf' },
    { route: '/learn/class10-icse/em1' },
    { route: '/learn/class10-icse/em2' },
    { route: '/learn/class10-icse/em3' },


    { route: '/learn/class10-icse/magnetic-effects-of-electric-current-1' },
    { route: '/learn/class10-icse/magnetic-effects-of-electric-current-2' },
    { route: '/learn/class10-icse/force-on-current-carrying-conductor' },
    { route: '/learn/class10-icse/electromagnetic-induction' },


    { route: '/learn/class10-icse/calorimetry-1' },
    { route: '/learn/class10-icse/calorimetry-2' },

    { route: '/learn/class10-icse/radioactivity' },
    { route: '/learn/class10-icse/nuclear-reactions' },

    // --------------------------------- CLASS 10 ICSE CHEMISTRY --------------------------------

    // OLD
    { route: '/periodic-table-1' },
    { route: '/periodic-table-2' },
    //



    { route: '/learn/class10-icse/periodic-table-1' },
    { route: '/learn/class10-icse/periodic-table-2' },
    { route: '/learn/class10-icse/periodic-table-3' },
    { route: '/learn/class10-icse/chemical-bonding-1' },
    { route: '/learn/class10-icse/chemical-bonding-2' },
    { route: '/learn/class10-icse/acids' },
    { route: '/learn/class10-icse/bases' },
    { route: '/learn/class10-icse/salts' },
    { route: '/learn/class10-icse/aluminium-extraction' },
    { route: '/learn/class10-icse/metallurgy' },
    { route: '/learn/class10-icse/metals-nonmetals' },
    { route: '/learn/class10-icse/ammonia' },
    { route: '/learn/class10-icse/nitric-acid' },

    { route: '/learn/class10-icse/mole-concept-1' },
    { route: '/learn/class10-icse/mole-concept-2' },
    { route: '/learn/class10-icse/mole-concept-3' },
    { route: '/learn/class10-icse/mole-concept-4' },

    { route: '/learn/class10-icse/organic-chemistry' },
    { route: '/learn/class10-icse/classification-of-organic-compounds' },
    { route: '/learn/class10-icse/nomenclature' },
    { route: '/learn/class10-icse/unsaturated-compounds' },
    { route: '/learn/class10-icse/combustion-and-oxidation' },
    { route: '/learn/class10-icse/alkanes' },
    { route: '/learn/class10-icse/alkenes' },
    { route: '/learn/class10-icse/alkynes' },
    { route: '/learn/class10-icse/alcohols' },
    { route: '/learn/class10-icse/carboxylic-acids' },

    // --------------------------------- CLASS 10 ICSE MATHS --------------------------------

    { route: '/learn/class10-icse/quadratic-equations' },
    { route: '/learn/class10-icse/applications-of-quadratic-equations-1' },
    { route: '/learn/class10-icse/applications-of-quadratic-equations-2' },
    { route: '/learn/class10-icse/arithmetic-progressions' },
    { route: '/learn/class10-icse/polynomials' },

    { route: '/learn/class10-icse/coordinate-geometry' },
    { route: '/learn/class10-icse/section-formula' },
    { route: '/learn/class10-icse/straight-line' },

    { route: '/learn/class10-icse/trigonometry' },
    { route: '/learn/class10-icse/heights-and-distances-1' },
    { route: '/learn/class10-icse/heights-and-distances-2' },

    { route: '/learn/class10-icse/circles' },
    { route: '/learn/class10-icse/areas-related-to-circles-1' },
    { route: '/learn/class10-icse/areas-related-to-circles-2' },

    { route: '/learn/class10-icse/mensuration' },

    { route: '/learn/class10-icse/probability-1' },
    { route: '/learn/class10-icse/probability-2' },

    // --------------------------------- C L A S S - 10 --------------------------------

    { route: '/charges' },
    { route: '/chargesHINDI' },
    { route: '/conductor-insulator' },
    { route: '/conductor-insulatorHINDI' },
    { route: '/ohms-law' },
    { route: '/ohms-lawHINDI' },
    { route: '/resistance' },
    { route: '/resistanceHINDI' },
    { route: '/combinationofresistances' },
    { route: '/combinationofresistancesHINDI' },
    { route: '/power' },
    { route: '/powerHINDI' },
    { route: '/emf' },
    { route: '/emfHINDI' },
    { route: '/em1' },
    { route: '/em1HINDI' },
    { route: '/em2' },
    { route: '/em2HINDI' },
    { route: '/em3' },
    { route: '/em3HINDI' },
    { route: '/reflection' },
    { route: '/reflectionHINDI' },
    { route: '/reflection2' },
    { route: '/reflection2HINDI' },
    { route: '/spherical' },
    { route: '/sphericalHINDI' },
    { route: '/spherical2' },
    { route: '/spherical2HINDI' },
    { route: '/spherical3' },
    { route: '/spherical3HINDI' },
    { route: '/refraction' },
    { route: '/refractionHINDI' },
    { route: '/lens' },
    { route: '/lensHINDI' },
    { route: '/lens2' },
    { route: '/lens2HINDI' },
    { route: '/lens3' },
    { route: '/lens3HINDI' },
    { route: '/prism' },
    { route: '/prismHINDI' },
    { route: '/humaneye1' },
    { route: '/humaneye2' },
    { route: '/humaneye3' },
    { route: '/machines' },
    /*
    {route: '/sound1'},
    {route: '/sound2'},
    */
    { route: '/pulleys' },
    { route: '/fortor' },
    { route: '/fortorHINDI' },
    { route: '/workenergy1' },
    { route: '/workenergy2' },
    { route: '/workenergy3' },
    { route: '/calorimetry' },
    { route: '/demo' },
    { route: '/lol' },
    { route: '/circle1' },
    { route: '/circle3' },
    { route: '/circle4' },
    { route: '/trigno1' },
    { route: '/trigno2' },
    { route: '/trigno3' },
    { route: '/trigno4' },
    { route: '/trigno5' },
    { route: '/realno' },
    { route: '/poly' },
    { route: '/linear' },
    { route: '/quadratic' },
    { route: '/quadratic2' },
    { route: '/quadratic3' },
    { route: '/ap' },
    { route: '/prob1' },
    { route: '/prob2' },
    { route: '/coord1' },
    { route: '/coord2' },
    { route: '/straight' },
    { route: '/mensuration1' },
    { route: '/organic' },
    { route: '/organicHINDI' },
    { route: '/organic2' },
    { route: '/organic2HINDI' },
    { route: '/organic3' },
    { route: '/organic3HINDI' },
    { route: '/organic4' },
    { route: '/organic4HINDI' },
    { route: '/organic6' },
    { route: '/organic6HINDI' },
    { route: '/organic7' },
    { route: '/organic7HINDI' },
    { route: '/organic8' },
    { route: '/organic8HINDI' },
    { route: '/organic9' },
    { route: '/organic9HINDI' },
    { route: '/organic10' },
    { route: '/organic10HINDI' },
    { route: '/organic11' },
    { route: '/organic11HINDI' },
    { route: '/acid-base' },
    { route: '/acid-baseHINDI' },
    { route: '/acid-base2' },
    { route: '/acid-base2HINDI' },
    { route: '/salt' },
    { route: '/saltHINDI' },
    { route: '/periodic' },
    { route: '/periodicHINDI' },
    { route: '/periodic2' },
    { route: '/periodic2HINDI' },
    { route: '/moleconcept1' },
    { route: '/moleconcept2' },
    { route: '/moleconcept3' },
    { route: '/moleconcept4' },
    { route: '/chembond' },
    { route: '/chembondHINDI' },
    { route: '/chembond2' },
    { route: '/chembond2HINDI' },
    { route: '/chemreact' },
    { route: '/chemreactHINDI' },
    { route: '/chemreact2' },
    { route: '/chemreact2HINDI' },
    { route: '/chemreact3' },
    { route: '/chemreact3HINDI' },
    { route: '/study' },
    { route: '/studyHINDI' },
    { route: '/study2' },
    { route: '/study2HINDI' },
    { route: '/metallurgy' },
    { route: '/metallurgyHINDI' },
    { route: '/metallurgy1' },
    { route: '/metallurgy1HINDI' },

    // --------------------------------- CLASS 9 CBSE PHYSICS --------------------------------

    { route: '/lol9' },

    { route: '/learn/class9-cbse/motion-1' },
    { route: '/learn/class9-cbse/motion-2' },
    { route: '/learn/class9-cbse/motion-3' },
    { route: '/learn/class9-cbse/force' },
    { route: '/learn/class9-cbse/laws-of-motion' },
    { route: '/learn/class9-cbse/gravitation' },

    { route: '/learn/class9-cbse/atmospheric-pressure' },
    { route: '/learn/class9-cbse/pressure-in-fluids' },
    { route: '/learn/class9-cbse/upthrust-and-archimedes-principle' },
    { route: '/learn/class9-cbse/relative-density-and-floatation' },

    { route: '/learn/class9-cbse/work-and-energy-1' },
    { route: '/learn/class9-cbse/work-and-energy-2' },
    { route: '/learn/class9-cbse/work-and-energy-3' },

    { route: '/learn/class9-cbse/sound' },
    { route: '/learn/class9-cbse/sound-2' },


    { route: '/restmotion' },
    { route: '/restmotion2' },
    { route: '/restmotion3' },
    { route: '/pressure1' },
    { route: '/pressure2' },
    { route: '/fluid1' },
    { route: '/fluid2' },
    // {route: '/class9-cbse-physics-motion'},
    // {route: '/class9-cbse-physics-forceandlawsofmotion'},
    // {route: '/class9-cbse-physics-gravitation'},
    // {route: '/class9-cbse-physics-floatation'},
    // {route: '/class9-cbse-physics-workandenergy1'},
    // {route: '/class9-cbse-physics-workandenergy2'},
    // {route: '/class9-cbse-physics-workandenergy3'},
    // {route: '/class9-cbse-physics-sound'},

    // --------------------------------- CLASS 9 CBSE CHEMISTRY -------------------------------

    { route: '/learn/class9-cbse/matter' },
    { route: '/learn/class9-cbse/matter-2' },
    { route: '/learn/class9-cbse/is-matter-around-us-pure-1' },
    { route: '/learn/class9-cbse/is-matter-around-us-pure-2' },
    { route: '/learn/class9-cbse/atoms-and-molecules-1' },
    { route: '/learn/class9-cbse/atoms-and-molecules-2' },
    { route: '/learn/class9-cbse/structure-of-the-atom-1' },
    { route: '/learn/class9-cbse/structure-of-the-atom-2' },

    // {route: '/class9-cbse-chemistry-atomsandmolecules'},
    // {route: '/class9-cbse-chemistry-structureoftheatom'},

    // --------------------------------- CLASS 9 CBSE MATHS --------------------------------

    { route: '/learn/class9-cbse/number-system' },
    { route: '/learn/class9-cbse/number-system-2' },

    { route: '/learn/class9-cbse/polynomials' },
    { route: '/learn/class9-cbse/polynomials-2' },
    { route: '/learn/class9-cbse/linear-equations-in-two-variables' },

    { route: '/learn/class9-cbse/coordinate-geometry' },

    { route: '/learn/class9-cbse/euclids-geometry' },
    { route: '/learn/class9-cbse/lines-and-angles' },
    { route: '/learn/class9-cbse/triangles' },
    { route: '/learn/class9-cbse/quadrilaterals' },
    { route: '/learn/class9-cbse/circles-1' },
    { route: '/learn/class9-cbse/circles-2' },

    { route: '/learn/class9-cbse/herons-formula' },
    { route: '/learn/class9-cbse/surface-areas-and-volumes' },

    { route: '/learn/class9-cbse/statistics' },


    // {route: '/class9-cbse-maths-introductiontoeuclidsgeometry'},
    // {route: '/class9-cbse-maths-linesandangles'},
    // {route: '/class9-cbse-maths-triangles'},
    // {route: '/class9-cbse-maths-quadrilaterals'},
    // {route: '/class9-cbse-maths-circles'},
    // {route: '/class9-cbse-maths-heronsformula'},
    // {route: '/class9-cbse-maths-surfaceareasandvolumes'},
    // {route: '/class9-cbse-maths-statistics'},

    // --------------------------------- CLASS 9 ICSE PHYSICS --------------------------------

    { route: '/learn/class9-icse/motion-1' },
    { route: '/learn/class9-icse/motion-2' },
    { route: '/learn/class9-icse/motion-3' },
    { route: '/learn/class9-icse/force' },
    { route: '/learn/class9-icse/laws-of-motion' },
    { route: '/learn/class9-icse/gravitation' },

    { route: '/learn/class9-icse/atmospheric-pressure' },
    { route: '/learn/class9-icse/pressure-in-fluids' },
    { route: '/learn/class9-icse/upthrust-and-archimedes-principle' },
    { route: '/learn/class9-icse/relative-density-and-floatation' },
    { route: '/learnHINDI/class9-icse-HINDI/physics-HINDI/fluids-HINDI/AtmosphericPressureHINDI' },

    { route: '/learn/class9-icse/heat-and-energy' },
    { route: '/learn/class9-icse/heat-and-energy-2' },
    { route: '/learn/class9-icse/heat-and-energy-3' },
    { route: '/learnHINDI/class9-icse-HINDI/physics-HINDI/heat-HINDI/HeatAndEnergyHINDI' },

    { route: '/learn/class9-icse/reflection-of-light' },
    { route: '/learn/class9-icse/spherical-mirrors' },
    { route: '/learn/class9-icse/concave-mirrors' },
    { route: '/learn/class9-icse/convex-mirrors' },

    { route: '/learn/class9-icse/sound' },

    { route: '/learn/class9-icse/current-electricity' },
    { route: '/learn/class9-icse/current-electricity-2' },

    { route: '/learn/class9-icse/magnetism' },
    { route: '/learn/class9-icse/magnetism-2' },



    // --------------------------------- CLASS 9 ICSE CHEMISTRY --------------------------------

    // {route: '/class9-icse-chemistry-thelanguageofchemistry'},
    // {route: '/class9-icse-chemistry-chemicalchangesandreactions'},
    // {route: '/class9-icse-chemistry-water'},
    // {route: '/class9-icse-chemistry-atomicstructureandchemicalbonding'},
    // {route: '/class9-icse-chemistry-theperiodictable'},
    // {route: '/class9-icse-chemistry-hydrogen'},
    // {route: '/class9-icse-chemistry-studyofgaslaws'},
    // {route: '/class9-icse-chemistry-atmosphericpollution'},
    // {route: '/class9-icse-chemistry-practicalwork'},

    { route: '/learn/class9-icse/the-language-of-chemistry-1' },
    { route: '/learn/class9-icse/the-language-of-chemistry-2' },
    { route: '/learn/class9-icse/chemical-changes-and-reactions-1' },
    { route: '/learn/class9-icse/chemical-changes-and-reactions-2' },
    { route: '/learn/class9-icse/chemical-changes-and-reactions-3' },
    { route: '/learn/class9-icse/periodic-table-1' },
    { route: '/learn/class9-icse/periodic-table-2' },
    { route: '/learn/class9-icse/periodic-table-3' },
    { route: '/learn/class9-icse/hydrogen-1' },
    { route: '/learn/class9-icse/hydrogen-2' },
    { route: '/learn/class9-icse/hydrogen-3' },
    { route: '/learn/class9-icse/water-1' },
    { route: '/learn/class9-icse/water-2' },
    { route: '/learn/class9-icse/water-3' },
    { route: '/learn/class9-icse/atmospheric-pollution' },

    { route: '/learn/class9-icse/atomic-structure-and-chemical-bonding-1' },
    { route: '/learn/class9-icse/atomic-structure-and-chemical-bonding-2' },
    { route: '/learn/class9-icse/atomic-structure-and-chemical-bonding-3' },
    { route: '/learn/class9-icse/atomic-structure-and-chemical-bonding-4' },
    { route: '/learn/class9-icse/study-of-gas-laws-1' },
    { route: '/learn/class9-icse/study-of-gas-laws-2' },

    // --------------------------------- CLASS 9 ICSE MATHS --------------------------------

    { route: '/learn/class9-icse/number-system' },
    { route: '/learn/class9-icse/number-system-2' },

    { route: '/learn/class9-icse/polynomials' },
    { route: '/learn/class9-icse/polynomials-2' },
    { route: '/learn/class9-icse/linear-equations-in-two-variables' },

    { route: '/learn/class9-icse/coordinate-geometry' },

    { route: '/learn/class9-icse/euclids-geometry' },
    { route: '/learn/class9-icse/lines-and-angles' },
    { route: '/learn/class9-icse/triangles' },
    { route: '/learn/class9-icse/quadrilaterals' },
    { route: '/learn/class9-icse/circles-1' },
    { route: '/learn/class9-icse/circles-2' },

    { route: '/learn/class9-icse/herons-formula' },
    { route: '/learn/class9-icse/surface-areas-and-volumes' },

    { route: '/learn/class9-icse/statistics' },



    // --------------------------------- CLASS 9 ICSE MATHS --------------------------------



    // {route: '/class9-icse-maths-rationalandirrationalnumbers'},
    // {route: '/class9-icse-maths-compoundinterest'},
    // {route: '/class9-icse-maths-expansions'},
    // {route: '/class9-icse-maths-factorization'},
    // {route: '/class9-icse-maths-simulateneouslinearequationsintwovariables'},
    // {route: '/class9-icse-maths-indices'},
    // {route: '/class9-icse-maths-logarithms'},
    // {route: '/class9-icse-maths-triangles'},
    // {route: '/class9-icse-maths-rectilinearfigures'},
    // {route: '/class9-icse-maths-circle'},
    // {route: '/class9-icse-maths-statistics'},
    // {route: '/class9-icse-maths-mensuration'},
    // {route: '/class9-icse-maths-trigonometry'},
    // {route: '/class9-icse-maths-coordinategeometry'},

    // // ------------------------------------------------------------------------------

    // ---------------------------------------------------------------------------------

    // ----------------------------------- GENERAL -------------------------------------

    // {route: '/'},
    // {route: '/signup'},
    // {route: '/login'},
    { route: '/terms' },
    { route: '/privacy' },
    { route: '/refund' },
    { route: '/plan' },
    { route: '/contact' },
    // {route: '/examplefortor'},
    // {route: '/examplecharges'},
    // {route: '/dashboard'},
    // {route: '/sim'},
    // {route: '/lolo'},
    { route: '/editcontent' },
    { route: '/home' },
    { route: '/homecbse-up' },
    { route: '/homecbse-upHINDI' },
    { route: '/homeicse' },
    { route: '/homeicse-HINDI' },
    { route: '/pryear' },
    { route: '/demo' },
    { route: '/lol' },

    // --------------------------------- C L A S S - 10 --------------------------------

    // physics

    { route: '/charges' },
    { route: '/chargesHINDI' },
    { route: '/conductor-insulator' },
    { route: '/conductor-insulatorHINDI' },
    { route: '/ohms-law' },
    { route: '/ohms-lawHINDI' },
    { route: '/resistance' },
    { route: '/resistanceHINDI' },
    { route: '/combinationofresistances' },
    { route: '/combinationofresistancesHINDI' },
    { route: '/power' },
    { route: '/powerHINDI' },
    { route: '/emf' },
    { route: '/emfHINDI' },
    { route: '/em1' },
    { route: '/em1HINDI' },
    { route: '/em2' },
    { route: '/em2HINDI' },
    { route: '/em3' },
    { route: '/em3HINDI' },
    { route: '/reflection' },
    { route: '/reflectionHINDI' },
    { route: '/reflection2' },
    { route: '/reflection2HINDI' },
    { route: '/spherical' },
    { route: '/sphericalHINDI' },
    { route: '/spherical2' },
    { route: '/spherical2HINDI' },
    { route: '/spherical3' },
    { route: '/spherical3HINDI' },
    { route: '/refraction' },
    { route: '/refractionHINDI' },
    { route: '/lens' },
    { route: '/lensHINDI' },
    { route: '/lens2' },
    { route: '/lens2HINDI' },
    { route: '/lens3' },
    { route: '/lens3HINDI' },
    { route: '/prism' },
    { route: '/prismHINDI' },
    { route: '/humaneye1' },
    { route: '/humaneye2' },
    { route: '/humaneye3' },
    { route: '/machines' },
    /*
    {route: '/sound1'},
    {route: '/sound2'},
    */
    { route: '/pulleys' },
    { route: '/fortor' },
    { route: '/fortor' },
    { route: '/fortorHINDI' },
    { route: '/workenergy1' },
    { route: '/workenergy2' },
    { route: '/workenergy3' },
    { route: '/calorimetry' },
    { route: '/calorimetry2' },
    { route: '/radioactivity' },
    { route: '/nuclear' },

    // maths

    { route: '/circle1' },
    { route: '/circle3' },
    { route: '/circle4' },
    { route: '/trigno1' },
    { route: '/trigno2' },
    { route: '/trigno3' },
    { route: '/trigno4' },
    { route: '/trigno5' },
    { route: '/realno' },
    { route: '/poly' },
    { route: '/linear' },
    { route: '/quadratic' },
    { route: '/quadratic2' },
    { route: '/quadratic3' },
    { route: '/ap' },
    { route: '/prob1' },
    { route: '/prob2' },
    { route: '/coord1' },
    { route: '/coord2' },
    { route: '/straight' },
    { route: '/mensuration1' },

    // chemistry

    { route: '/organic' },
    { route: '/organicHINDI' },
    { route: '/organic2' },
    { route: '/organic2HINDI' },
    { route: '/organic3' },
    { route: '/organic3HINDI' },
    { route: '/organic4' },
    { route: '/organic4HINDI' },
    { route: '/organic6' },
    { route: '/organic6HINDI' },
    { route: '/organic7' },
    { route: '/organic7HINDI' },
    { route: '/organic8' },
    { route: '/organic8HINDI' },
    { route: '/organic9' },
    { route: '/organic9HINDI' },
    { route: '/organic10' },
    { route: '/organic10HINDI' },
    { route: '/organic11' },
    { route: '/organic11HINDI' },
    { route: '/acid-base' },
    { route: '/acid-baseHINDI' },
    { route: '/acid-base2' },
    { route: '/acid-base2HINDI' },
    { route: '/salt' },
    { route: '/saltHINDI' },
    { route: '/periodic' },
    { route: '/periodicHINDI' },
    { route: '/periodic2' },
    { route: '/periodic2HINDI' },
    { route: '/moleconcept1' },
    { route: '/moleconcept2' },
    { route: '/moleconcept3' },
    { route: '/moleconcept4' },
    { route: '/metalsandnonmetals1' },
    { route: '/metalsandnonmetals2' },
    { route: '/metalsandnonmetals3' },
    { route: '/metalsandnonmetals4' },
    { route: '/chembond' },
    { route: '/chembondHINDI' },
    { route: '/chembond2' },
    { route: '/chembond2HINDI' },
    { route: '/chemreact' },
    { route: '/chemreactHINDI' },
    { route: '/chemreact2' },
    { route: '/chemreact2HINDI' },
    { route: '/chemreact3' },
    { route: '/chemreact3HINDI' },
    { route: '/study' },
    { route: '/studyHINDI' },
    { route: '/study2' },
    { route: '/study2HINDI' },
    { route: '/metallurgy' },
    { route: '/metallurgyHINDI' },
    { route: '/metallurgy1' },
    { route: '/metallurgy1HINDI' },

]

class RoutesFile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            routes: []
        }
    }

    async componentDidMount() {
        // fetch login info
        const isLoggedIn = await makeRequest({}, '/isloggedin')
        let plan_name = 'free';
        let courses = [];

        if (isLoggedIn) {
            // fetch plan
            var response = await makeRequest({ tag: "auth", query: "auth.query.payment_status", variables: {} }, '/api/graphql')
            plan_name = response.data?.payment_status?.plan_name;


        }

        // fetch course list
        var response = await makeRequest({ tag: "courses", query: "courses.query.fetchusercourselist", variables: {} }, '/api/graphql');
        courses = response.data?.fetchusercourselist?.courses ? response.data?.fetchusercourselist?.courses : [];

        // add all class10 content
        Object.keys(component_map_class10).map((component, index) => {
            component_map[component] = component_map_class10[component];
            // if (plan_name === 'premium' || plan_name === 'admin' || courses.includes(component)) {
            //     component_map[component] = component_map_class10[component];
            // } else {
            //     component_map[component] = Watermark;
            // }
        })

        // add class 9 content that is paid for
        Object.keys(component_map_class9).map((component, index) => {
            if (plan_name === 'premium' || plan_name === 'admin' || courses.includes(component)) {
                component_map[component] = component_map_class9[component];
            } else {
                component_map[component] = Watermark;
            }
        })

        this.setState({
            routes: api_respnse
        })
    }

    render() {
        // only return after routes are finalized
        if (this.state.routes.length == 0) { return }

        return (
            <Router>
                <Routes>
                    {/*<Route path="/" element={<LandingPage />} />*/}
                    {
                        this.state.routes.map((item, index) => {
                            const RouteComponent = component_map[item.route] ? component_map[item.route] : NotFound
                            return (
                                <Route key={index} path={item.route} element={<RouteComponent />} />
                            )
                        })
                    }
                    {
                        blogRoutes.map((item, index) => {
                            return (
                                <Route key={index} path={item.route} element={<item.component />} />
                            )
                        })
                    }
                    {/*<Route path="*" element={<NotFound />} />*/}
                </Routes>
            </Router>
        );
    }
}

export default RoutesFile;