import React from "react";
import {
    Box,
    Grid,
    Button,
    Drawer,
    Menu,
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Collapse,
    Divider
} from '@mui/material';

import {
    ExpandLess,
    ExpandMore,
    Home as HomeIcon,
    Build as PhysicsIcon,
    Science as ChemistryIcon,
    Calculate as MathIcon,
    Dashboard as DashboardIcon,
    Lock as LockIcon,
    LockOpen as LockOpenIcon,
    Headphones as HeadphonesIcon
} from '@mui/icons-material';
import DynamicComponent from './DynamicComponent.jsx'
import contents from './contents.js';
import store from './store.js';

const handleClickContent = async (component) => {
    // if (!store.LandingPage) {
    //     window.location.href = '/';
    // }

    // store.LandingPage.setState({
    //     ...store['LandingPage'].state,
    //     componentToRender: component
    // })
    if (component) {
        const userstate = JSON.stringify({ componentToRender: component });
        var response = await makeRequest({ tag: "auth", query: "auth.mutation.update_userstate", variables: { userstate } }, '/api/graphql');

        window.location.href = component;
    }
}

const handleHelp = async () => {
    if (!store.LandingPage) {
        window.location.href = '/';
    }

    store.LandingPage.setState({
        ...store.LandingPage.state,
        componentToRender: '/contact'
    })
}


const componentDidMount = async (state, setState) => {
    let courseLevel;
    let board;
    let language;
    let itemsShowInside = {};

    if (store.user.board && store.user.language)  {
        board = store.user.board;
        language = store.user.language;
    } else {
        var response = await makeRequest({ inputText: JSON.stringify(['courseLevel', 'board', 'language']) }, '/api/content/fetch_userstate');
        if (response.success) {
            courseLevel = response.data.courseLevel ? response.data.courseLevel : 'CLASS-10';
            board = response.data.board ? response.data.board : 'CBSE';
            language = response.data.language ? response.data.language : 'HINGLISH';
        } else {
            courseLevel = 'CLASS-10';
            board = 'CBSE';
            language = 'HINGLISH';
        }
    }

    let tmp = ['physics', 'chemistry', 'math'];
    ['physics', 'chemistry', 'math'].map((subject, _) => {
        contents[courseLevel][board][language][subject].map((item, index) => {
            if (item.children) {
                tmp.push(item.label)
            }
        })
    })

    var response = await makeRequest({ inputText: JSON.stringify(tmp) }, '/api/content/fetch_userstate');
    if (response.success) {
        ['physics', 'chemistry', 'math'].map((subject, _) => {
            itemsShowInside[subject] = response.data[subject] ? response.data[subject] : { showInside: true };
            contents[courseLevel][board][language][subject].map((item, index) => {
                if (item.children) {
                    itemsShowInside[item.label] = response.data[item.label] ? response.data[item.label] : { showInside: true };
                }
            });
        })
    } else {
        ['physics', 'chemistry', 'math'].map((subject, _) => {
            itemsShowInside[subject] = { showInside: true };
            contents[courseLevel][board][language][subject].map((item, index) => {
                if (item.children) {
                    itemsShowInside[item.label] = { showInside: true };
                }
            });
        })
    }
    // contents[board][language].physics.map((item, index) => {
    //             if (item.children) {
    //                 if (response.data[item.label]) {
    //                     itemsShowInside[item.label] = response.data[item.label];
    //                 } else {
    //                     itemsShowInside[item.label] = { showInside: true };
    //                 }
    //             }
    //         });
    //         contents[board][language].chemistry.map((item, index) => {
    //             if (item.children) {
    //                 itemsShowInside[item.label] = { showInside: true };
    //             }
    //         });
    //         contents[board][language].math.map((item, index) => {
    //             if (item.children) {
    //                 itemsShowInside[item.label] = { showInside: true };
    //             }
    //         });



    // fetch subscription plan

    let subscriptionPlan = 'free';
    var response = await makeRequest({ tag: "auth", query: "auth.query.payment_status", variables: {} }, '/api/graphql')
    const plan_name = response.data?.payment_status?.plan_name;
    if (plan_name) {
        subscriptionPlan = plan_name;
    }

    // fetch course list

    var response = await makeRequest({ tag: "courses", query: "courses.query.fetchusercourselist", variables: {} }, '/api/graphql')
    const courses = response.data?.fetchusercourselist?.courses ? response.data?.fetchusercourselist?.courses : [];

    setState({
        ...state,
        courseLevel,
        board,
        language,
        ...itemsShowInside,
        subscriptionPlan,
        courses
    });
}


const ContentBar = () => {
    const [state, setState] = React.useState({
        componentName: 'ContentBar',
        subscriptionPlan: 'free',
        courses: [],
        courseLevel: null,
        board: null,
        language: null,
        physics: {
            showInside: false,
        },
        chemistry: {
            showInside: false
        },
        math: {
            showInside: false
        }
    });

    React.useEffect(() => { (async () => { await componentDidMount(state, setState); })() }, []);

    const ShowInsideIcon = ({ showInside }) => {
        return (showInside ? <ExpandLess /> : <ExpandMore />);
    }

    const toggleCollapse = async (item) => {
        setState({
            ...state,
            [item]: {
                ...state[item],
                showInside: !state[item].showInside
            }
        })

        const userstate = JSON.stringify({ [item]: { showInside: !state[item].showInside } });
        var response = await makeRequest({ tag: "auth", query: "auth.mutation.update_userstate", variables: { userstate } }, '/api/graphql');
    }

    const ContentItem = ({ item }) => {
        if (item.children) {
            return (
                <>
                    <ListItemButton onClick={() => toggleCollapse(item.label)}>
                    <ListItemText primary={item.label} />
                    <ListItemIcon>
                        <ShowInsideIcon showInside={state[item.label].showInside} />
                    </ListItemIcon>
                    </ListItemButton>
                    <Collapse in={state[item.label].showInside}>
                        <List disablePadding sx={{ pl: 1, backgroundColor: '#F6F1E9' }}>
                            {
                                item.children.map((this_item, this_index) => {
                                    return (
                                        <ListItemButton key={this_index} onClick={() => handleClickContent(this_item.url)}>
                                            {(()=>{
                                                if (state.courses.includes(this_item.url) || this_item.public === true) {
                                                    return (
                                                        <ListItemIcon>
                                                            <LockOpenIcon />
                                                        </ListItemIcon>
                                                    );
                                                } else {
                                                    return (
                                                        <ListItemIcon>
                                                            <LockIcon />
                                                        </ListItemIcon>
                                                    );
                                                }
                                            })()}
                                            <ListItemText primary={this_item.label} />
                                        </ListItemButton>
                                    )
                                })
                            }
                        </List>
                    </Collapse>
                </>
            )
        } else {
            return (
                <ListItemButton onClick={() => handleClickContent(item.url)} sx={{ backgroundColor: '#F6F1E9' }}>
                    {(()=>{
                        if (state.courses.includes(item.url) || item.public === true) {
                            return (
                                <ListItemIcon>
                                    <LockOpenIcon />
                                </ListItemIcon>
                            );
                        } else {
                            return (
                                <ListItemIcon>
                                    <LockIcon />
                                </ListItemIcon>
                            );
                        }
                    })()}
                    <ListItemText primary={item.label} />
                </ListItemButton>
            )
        }
    }

    if (!state.courseLevel || !state.board || !state.language) { return; }

    return (
        <>
            <Box width='292px'>
                <Grid
                    container
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        bgcolor: "#ffffff",
                        textAlign: "center",
                        paddingTop: '10px',
                        paddingBottom: '20px'
                    }}
                >
                    <h4 style={{ margin: 0 }}>CONTENTS</h4>
                </Grid>
                <List disablePadding sx={{ pl: 1 }}>
                    <Divider />
                    <ListItemButton onClick={() => handleClickContent('/home')}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>
                    <Divider />
                    <ListItemButton onClick={() => toggleCollapse('physics')}>
                        <ListItemIcon>
                            <PhysicsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Physics" />
                        <ListItemIcon>
                            <ShowInsideIcon showInside={state.physics.showInside} />
                        </ListItemIcon>
                    </ListItemButton>
                    <Collapse in={state.physics.showInside}>
                        <List disablePadding sx={{ pl: 1 }}>
                            {
                                contents[state.courseLevel][state.board][state.language].physics.map((item, index) => {
                                    return (
                                        <ContentItem key={index} item={{ ...item }} />
                                    )
                                })
                            }
                        </List>
                    </Collapse>
                    <Divider />
                    <ListItemButton onClick={() => toggleCollapse('chemistry')}>
                        <ListItemIcon>
                            <ChemistryIcon />
                        </ListItemIcon>
                        <ListItemText primary="Chemistry" />
                        <ListItemIcon>
                            <ShowInsideIcon showInside={state.chemistry.showInside} />
                        </ListItemIcon>
                    </ListItemButton>
                    <Collapse in={state.chemistry.showInside}>
                        <List disablePadding sx={{ pl: 1 }}>
                            {
                                contents[state.courseLevel][state.board][state.language].chemistry.map((item, index) => {
                                    return (
                                        <ContentItem key={index} item={{ ...item }} />
                                    )
                                })
                            }
                        </List>
                    </Collapse>
                    <Divider />
                    <ListItemButton onClick={() => toggleCollapse('math')}>
                        <ListItemIcon>
                            <MathIcon />
                        </ListItemIcon>
                        <ListItemText primary="Math" />
                        <ListItemIcon>
                            <ShowInsideIcon showInside={state.math.showInside} />
                        </ListItemIcon>
                    </ListItemButton>
                    <Collapse in={state.math.showInside}>
                        <List disablePadding sx={{ pl: 1 }}>
                            {
                                contents[state.courseLevel][state.board][state.language].math.map((item, index) => {
                                    return (
                                        <ContentItem key={index} item={{ ...item }} />
                                    )
                                })
                            }
                        </List>
                    </Collapse>
                    <Divider />
                    <br/><br/><br/><br/>
                    <Divider />
                    <ListItemButton onClick={handleHelp}>
                        <ListItemIcon>
                            <HeadphonesIcon />
                        </ListItemIcon>
                        <ListItemText primary="Help" />
                    </ListItemButton>
                    <Divider />
                    <br/>
                </List>
            </Box>
        </>
    )
}

export default ContentBar;