const contents = {
    'CLASS-10': {
        CBSE: {
            HINGLISH: {
                physics: [
                    { label: "Light - Reflection and Refraction", children: [
                        { label: "Reflection of Light", url: "/reflection", more: "Laws of Reflection, Image Formation, Real and Virtual Images, Combination of Mirrors, Deviation in Plane Mirrors, Applications, Concept Builders, Test Drive, Subjective Questions" },
                        { label: "Spherical Mirrors", url: "/spherical", more: "Spherical Mirrors, Components of Curved Mirrors, Rules for Image Formation, Mirror Formula, Magnification of mirror, Sign Convention, Applications, Concept Builders, Subjective Questions" },
                        { label: "Concave Mirrors", url: "/spherical2", more: "Concave Mirrors, Detailed analysis for different cases for image formation in Concave Mirrors, Applications, Test Drive" },
                        { label: "Convex Mirrors", url: "/spherical3", more: "Convex Mirrors, Rules for image formation in Convex Mirrors, Detailed analysis for different cases for image formation in Convex Mirrors, Applications, Test Drive" },
                        { label: "Refraction of Light", url: "/refraction", more: "Refraction of Light, Laws of Refraction, Refractive Index, Refraction from Glass Slab, Applications, Applications, Concept Builders, Test Drive, Subjective Questions" },
                        { label: "Prism", url: "/prism", more: "Prism, Refraction through Prism, Dispersion of Light, Why White light disperses, Combination of Prism, Working of Prism" },
                        { label: "Spherical Lens", url: "/lens", more: "Lens, Components of Lenses, Rules for Image Formation in lenses, Lens Formula, Magnification in Lens, Sign Convention for Lens, Applications, Subjective Questions" },
                        { label: "Convex Lens", url: "/lens2", more: "Detailed analysis of Convex Lens, Different Cases of Image Formation in Convex Lens,Image Characteristics, Observations, Applications, Test Drive" },
                        { label: "Concave Lens", url: "/lens3", more: "Concave Lens, Rules for image formations, Different Cases of Image Formation in Concave Lens, Image Characteristics, Power of lens, Applications, Subjective Questions, Test Drive" },
                        { label: "Human Eye and the Colourful World - 1", url: "/humaneye1", more: "Construction, Functions, Working, Accommodation, Applications" },
                        { label: "Human Eye and the Colourful World - 2", url: "/humaneye2", more: "Myopia, Hypermetropia, Presbiopia, Cures of defect of vision, Applications" },
                        { label: "Human Eye and the Colourful World - 3", url: "/humaneye3", more: "Refraction through prism, Dispersion of Light, Atmospheric refraction, Scattering of light" },
                    ] },
                    { label: "Electricity", children: [
                        { label: "Charges", url: "/charges", more: "Charges, Properties of charges, Coulomb's Law, Applications, Concept Builders, Test Drive"  },
                        { label: "Electric Current and Electric Potential", url: "/conductor-insulator", more: "Free electrons, Conductor and Insulator, Potential and Potential Difference, Electric Current, Voltmeter, Ammeter, Applications, Concept Builders, Test Drive" },
                        { label: "Ohm's Law", url: "/ohms-law", more: "Introduction to Circuit Elements, Ohms Law, Experimental Demonstration of Ohms Law, Applications, Subjective Questions" },
                        { label: "Resistance", url: "/resistance", more: "Resistance, Factors affecting resistance, Resistivity, Effect of material, length, area, temperature on resistance, Applications, Concept Builders" },
                        { label: "Resistances Combo", url: "/combinationofresistances", more: "Combination of Resistances, Series Combination, Parallel Combination, Various Cases, Applications" },
                        { label: "Electric Power", url: "/power", more: "Power, Expressions of Power, Electrical Appliances, Power Analysis, Commercial unit of electricity, Applications, Concept Builders" },
                        { label: "Electrical Circuits", url: "/emf", more: "Potential Difference in a battery, Electric Cell, Electromotive Force, Terminal Voltage, Internal Resistance, Applications, Concept Builders, Subjective Questions" },
                    ] },
                    { label: "Magnetic Effects of Electric Current", children: [
                        { label: "Magnetic Effects", url: "/em1", more:"Oersted Experiment, Magnetic Field due to straight wire, Magnetic field due to circular coil, Magnetic field due to Solenoid, Electromagnet, Solenoid Bar Magnet, Applications, Subjective Questions" },
                        { label: "Force on Current Carrying Conductor", url: "/em2", more: "Force on current carrying conductor, Lorentz Force, Experimental Demonstration, Magnitude and Direction of force, Fleming's Rule, D.C. Motor, Its construction and working, Applications, Subjective Questions" },
                        { label: "Electromagnetic Induction", url: "/em3", more: "Electromagnetic Induction, Faradays Law, A.C. Generator, Its principle and working, Advantage of a.c. over d.c., Differences between AC/DC & AC Generator/ DC Motor, Applications, Subjective Questions" }
                    ] },
                    { label: "Our Environment", children: [
                        { label: "Nuclear Reactions", url: "/nuclear", more: "E = mc2 • Nuclear fission • Nuclear fusion • Power Plant • Applications"  },
                    ]},
                ],
                chemistry: [
                    { label: "Chemical Reactions and Equations", children: [
                        { label: "Chemical Reaction", url: "/chemreact", more: "Chemical equations, balanced chemical equations, implications of a balanced chemical equation, Important examples writing off balanced chemical equations, Concept Builders, JADUSAY Perfect Strategy, Applications" },
                        { label: "Types Of Reactions", url: "/chemreact2", more: "Combination Reactions, Decomposition Reactions, Displacement Reactions, Experimental Demonstration, JADUSAY Perfect Strategy, Applications" },
                        { label: "Types Of Reactions - II", url: "/chemreact3", more: "Double Displacement Reactions, Oxidation, Reduction Reactions, Experimental Demonstration, JADUSAY Perfect Strategy, Applications" },
                    ] },
                    { label: "Acids, Bases and Salts", children: [
                        { label: "Acids", url: "/acid-base", more: "Acids, Classification of acids, Basicity, Reactions for acids, JADUSAY Perfect Strategy, Test Drive, Applications"  },
                        { label: "Bases", url: "/acid-base2", more: "Bases, Alkalis, Properties of Bases, pH - Scale, Universal Indicator, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Salts", url: "/salt", more: "Salt, pH of Salt Solutions, Common Salt, Sodium Hydroxide, Washing Soda, Baking Soda, Bleaching Powder, Plaster of Paris, JADUSAY Perfect Strategy, Test Drive, Applications" },
                    ] },
                    { label: "Metals and Non-metals", children: [
                        { label: "Metals and Non-metals – 1", url: "/metalsandnonmetals1", more: "About metals, Physical Properties, Chemical Properties, Reactions, Applications" },
                        { label: "Metals and Non-metals – 2", url: "/metalsandnonmetals2", more: "About non-metals, Physical Properties, Chemical Properties, Reactions, Applications" },
                        { label: "Metals and Non-metals – 3", url: "/metalsandnonmetals3", more: "Metals-nonmetals Bonds, Chemical Bonding, Structure of Compounds, Applications" },
                        { label: "Metals and Non-metals – 4", url: "/metalsandnonmetals4", more: "Covalent Bonds, Polar and Non-polar Compounds, Coordinate Bond, Applications" },
                    ] },
                    { label: "Carbon and its Compounds", children: [
                        { label: "Organic Chemistry", url: "/organic", more: "Differentiation between organic and inorganic compounds, Unique nature of Carbon atoms, Covalent Bond, Polar and non-polar compounds, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Classification of Organic Compounds", url: "/organic2", more: "Structural representation of organic compounds, Classification of organic compounds, Classification of carbon atoms, Functional Group, Homologous Series, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Nomenclature", url: "/organic3", more: "Nomenclature, IUPAC System of Naming, Rules for IUPAC Nomenclature, Applications, Test Drive" },
                        { label: "Unsaturated Compounds", url: "/organic4", more: "Unsaturated Hydrocarbons, IUPAC System of naming Unsaturated hydrocarbons, Nomenclature for functional group, Applications, JADUSAY Perfect Strategy" },
                        { label: "Combustion and Oxidation", url: "/organic6", more: "Combustion and Oxidation, Addition Reaction, Substitution Reactions, Hydrogenation, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alkanes", url: "/organic7", more: "Occurrences, Structures, Lab Preparation of Methane and Ethane, Physical Properties, Other methods of preparation, Combustion in alkanes, Substitution in alkanes, Uses of Methane and Ethane, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alkenes", url: "/organic8", more:"Occurrences, Structures, Lab Preparation of Ethene, Physical Properties, Chemical Properties, Polymerization, Oxidation, Combustion, Uses, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alkynes", url: "/organic9", more: "Occurrences, Structures, Lab Preparation of Ethyne, Physical Properties, Chemical Properties, Uses of Ethyne, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alcohols", url: "/organic10", more: "Occurrences, Structures, Lab and Industrial Preparation of Ethanol, Physical Properties of Alcohol, Chemical Properties, Combustion of Ethanol, Production of Ethanol" },
                        { label: "Carboxylic Acids", url: "/organic11", more: "Occurrences, Structures, Lab and Industrial Preparation of Acetic Acid, Physical Properties of Acetic Acid, Chemical Properties, Esters" },
                    ] }
                ],
                math: [
                    { label: "Real Numbers", url: "/realno", more: "Number Line, Euclid's Division Lemma, Euclid's Division Algorithm ,Fundamental Theorem of Arithmetic, Rational & Irrational Numbers, Applications, Test Drive" },
                    { label: "Polynomials", url: "/poly", more: "Zero of a Polynomial, Dision Algorithm for polynomial, degree of a polynomial, types of polynomial based on degrees, relation between the zeroes and coefficient, Graphical Representation, Applications" },
                    { label: "Pair of Linear Equations in Two Variables", url: "/linear", more: "Linear Equations, Graphical Method, condition for existence of a solution of a pair of linear equations, Elimination Method, Substitution Method, Cross Multiplication Method, Test Drive, Applications" },
                    { label: "Quadratic Equations", children: [
                        { label: "Quadratic Equations", url: "/quadratic", more: "Quadratic Equation, Sridharacharya Formula, Roots of the Equation, Nature of roots, Applications, Test Drive" },
                        { label: "Applications - I", url: "/quadratic2", more: "Applications based on Numbers, Time & Work and Geometrical Figures & Mensuration, Test Drive, Applications" },
                        { label: "Applications - II", url: "/quadratic3", more: "Applications based on Distance, Speed , Time, Money Matters and Ages, Test Drive, Applications" },
                    ] },
                    { label: "Arithmetic Progressions", url: "/ap", more: "General Term of ap, nth term from end, Sum of n terms of an ap, Reverse AP, Applications" },
                    // { label: "Triangles", more: "COMING SOON"},
                    { label: "Coordinate Geometry", children: [
                        { label: "Coordinate Geometry", url: "/coord1", more: "Coordinate Systems, Distance Formula, Colinearity, Coordinates of some particular points, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Section Formula", url: "/coord2", more: "Section Formula, Mid-point Formula, Area of a Triangle, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Straight Line", url: "/straight", more: "Slope of a line, Slope- Intercept form, Point- Slope form, Two- point form, Intercept form, Test Drive, Applications" },
                    ] },
                    { label: "Trigonometry", children: [
                        { label: "Trigonometry", url: "/trigno1", more:"Trigonometric Ratios, associated theorems, Trogonometric ratios of complementary angles, Trigometrical identities, Applications" },
                        { label: "Trigonometry - II", url: "/trigno2", more:"Trigonometry ratios of special angles, Complementary angles, Applications based on Trigonometry and Trigometric Ratios" },
                        { label: "Heights and Distances", url: "/trigno4", more:"Angle of Elevation, Angle of Depression, Moving objects and changing angles, further applications of trigonometry" },
                    ] },
                    { label: "Circles", children: [
                        { label: "Circles", url: "/circle1", more: "Chord, Secant, Tangent, Length of tangent drawn from different points, Concept of Incircle, Cyclic Quadrilateral, Theorems associated with angles, Test Drive, Applications" },
                        { label: "Areas Related to Circles", url: "/circle3", more: "Areas related to Circle, Concept of pi, Circular arc, Perimeter of concentric circles, Various Cases, Applications" },
                        { label: "Sectors and Segments", url: "/circle4", more: "" },
                    ] },
                    { label: "Mensuration", url: "/mensuration1", more: "" },
                    { label: "Probablity", children: [
                        { label: "Probability - I", url: "/prob1", more: "Basics, Experimental probability, Theoretical probability, Applications" },
                        { label: "Probability - II", url: "/prob2", more: "Problem types, Information conveyed, Methodology, Solutions" },
                    ] },
                    // { label: "Statistics", more: "COMING SOON"},
                ]
            },
            HINDI: {
                physics: [
                    {label: "प्रतिबिंब प्रकाश", url: "/reflectionHINDI", more: "प्रतिबिंब के नियम, छवि निर्माण, वास्तविक और आभासी छवियां, दर्पणों का संयोजन, समतल दर्पणों में विचलन, अनुप्रयोग, संकल्पना निर्माता, टेस्ट ड्राइव, व्यक्तिपरक प्रश्न" },
                    {label: "विमान दर्पण", url: "/reflection2HINDI", more: "समतल दर्पणों का अवलोकन, समतल दर्पणों का संयोजन, छवियों की संख्या, अनुप्रयोग, संकल्पना निर्माता, टेस्ट ड्राइव" },
                    {label: "गोलाकार दर्पण", url: "/sphericalHINDI", more:"गोलीय दर्पण, घुमावदार दर्पण के घटक, छवि निर्माण के नियम, दर्पण सूत्र, दर्पण का आवर्धन, चिह्न परिपाटी, अनुप्रयोग, संकल्पना निर्माता, विषयपरक प्रश्न" },
                    {label: "अवतल दर्पण", url: "/spherical2HINDI", more: "अवतल दर्पण, अवतल दर्पण, अनुप्रयोग, टेस्ट ड्राइव में छवि निर्माण के लिए विभिन्न मामलों के लिए विस्तृत विश्लेषण" },
                    {label: "उत्तल दर्पण", url: "/spherical3HINDI", more:"उत्तल दर्पण, उत्तल दर्पण में छवि निर्माण के नियम, उत्तल दर्पण में छवि निर्माण के लिए विभिन्न मामलों का विस्तृत विश्लेषण, अनुप्रयोग, टेस्ट ड्राइव" },
                    {label: "प्रकाश का अपवर्तन", url: "/refractionHINDI", more:"प्रकाश का अपवर्तन, अपवर्तन के नियम, अपवर्तक सूचकांक, ग्लास स्लैब से अपवर्तन, अनुप्रयोग, अनुप्रयोग, अवधारणा निर्माता, टेस्ट ड्राइव, व्यक्तिपरक प्रश्न"},
                    {label: "प्रिज्म", url: "/prismHINDI", more:"प्रिज्म, प्रिज्म के माध्यम से अपवर्तन, प्रकाश का फैलाव, सफेद प्रकाश क्यों फैलता है, प्रिज्म का संयोजन, प्रिज्म का कार्य"},
                    {label: "लेंस", url: "/lensHINDI", more:"लेंस, लेंस के घटक, लेंस में छवि निर्माण के नियम, लेंस सूत्र, लेंस में आवर्धन, लेंस के लिए हस्ताक्षर परिपाटी, अनुप्रयोग, विषयपरक प्रश्न"},
                    {label: "उत्तल लेंस", url: "/lens2HINDI", more:"उत्तल लेंस का विस्तृत विश्लेषण, उत्तल लेंस में छवि निर्माण के विभिन्न मामले, छवि की विशेषताएं, अवलोकन, अनुप्रयोग, टेस्ट ड्राइव"},
                    {label: "अवतल लेंस", url: "/lens3HINDI", more:"अवतल लेंस, छवि निर्माण के नियम, अवतल लेंस में छवि निर्माण के विभिन्न मामले, छवि विशेषताएँ, लेंस की शक्ति, अनुप्रयोग, व्यक्तिपरक प्रश्न, टेस्ट ड्राइव"},
                    {label: "विद्युत आवेश", url: "/chargesHINDI", more: "विद्युत आवेश, विद्युत आवेश, के गुण, कूलम्ब का नियम, अनुप्रयोग, अवधारणा निर्माता, टेस्ट ड्राइव"},
                    {label: "विद्युतीय संभाव्यता/ धारा", url: "/conductor-insulatorHINDI", more:"मुक्त इलेक्ट्रॉन, कंडक्टर और इन्सुलेटर, विद्युतीय संभाव्यता और विद्युतीय संभाव्यता अंतर, विद्युत धारा, वोल्टमीटर, एमीटर, अनुप्रयोग, संकल्पना निर्माता, टेस्ट ड्राइव"},
                    {label: "प्रतिरोध", url: "/resistanceHINDI", more:"प्रतिरोध, प्रतिरोध को प्रभावित करने वाले कारक, प्रतिरोधकता, प्रतिरोध पर सामग्री/लंबाई/क्षेत्र/तापमान का प्रभाव, अनुप्रयोग, संकल्पना निर्माता"},
                    {label: "ओम का नियम", url: "/ohms-lawHINDI", more:"सर्किट तत्वों का परिचय, ओम कानून, ओम कानून का प्रायोगिक प्रदर्शन, अनुप्रयोग, व्यक्तिपरक प्रश्न"},
                    {label: "विद्युत शक्ति", url: "/powerHINDI", more:"विद्युत शक्ति, विद्युत शक्ति की अभिव्यक्तियाँ, विद्युत उपकरण, विद्युत विश्लेषण, विद्युत की वाणिज्यिक इकाई, अनुप्रयोग, संकल्पना निर्माणकर्ता"},
                    {label: "प्रतिरोधों का संयोजन", url: "/combinationofresistancesHINDI", more:"प्रतिरोधों का संयोजन, श्रृंखला संयोजन, समानांतर संयोजन, विभिन्न मामले, अनुप्रयोग"},
                    {label: "इलेक्ट्रिक सर्किट्स", url: "/emfHINDI", more:"बैटरी में विद्युतीय संभाव्यता अंतर, इलेक्ट्रिक सेल, इलेक्ट्रोमोटिव बल, टर्मिनल वोल्टेज, आंतरिक प्रतिरोध, अनुप्रयोग, संकल्पना निर्माता, व्यक्तिपरक प्रश्न"},
                    {label: "चुंबकीय प्रभाव", url: "/em1HINDI", more:"ओर्स्टेड प्रयोग, सीधे तार के कारण चुंबकीय क्षेत्र, वृत्ताकार कुंडली के कारण चुंबकीय क्षेत्र, परिनालिका के कारण चुंबकीय क्षेत्र, विद्युत चुंबक, परिनालिका/बार चुंबक, अनुप्रयोग, विषयपरक प्रश्न"},
                    {label: "बल और कंडक्टर", url: "/em2HINDI", more: "करंट ले जाने वाले कंडक्टर पर बल, लोरेंत्ज़ बल, प्रायोगिक प्रदर्शन, परिमाण और बल की दिशा, फ्लेमिंग का नियम, डीसी मोटर, इसका निर्माण और कार्य, अनुप्रयोग, व्यक्तिपरक प्रश्न"},
                    {label: "E.M.I.", url: "/em3HINDI", more: "इलेक्ट्रोमैग्नेटिक इंडक्शन, फैराडेज लॉ, ए.सी. जेनरेटर, इसका सिद्धांत और कार्य, ए.सी. का लाभ डीसी से अधिक, एसी/डीसी और एसी जेनरेटर/डीसी मोटर के बीच अंतर, अनुप्रयोग, विषयपरक प्रश्न"}
                ],
                chemistry: [
                    {label: "अम्ल", url: "/acid-baseHINDI", more: "अम्ल, अम्लों का वर्गीकरण, मूलता, अम्लों के लिए प्रतिक्रियाएँ, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग" },
                    {label: "क्षार", url: "/acid-base2HINDI", more: "क्षार, क्षारों के गुण, पीएच - स्केल, यूनिवर्सल इंडिकेटर, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग" },
                    {label: "लवण", url: "/saltHINDI", more: "नमक, नमक के घोल का पीएच, सामान्य नमक, सोडियम हाइड्रॉक्साइड, धोने का सोडा, बेकिंग सोडा, विरंजक पाउडर, प्लास्टर ऑफ पेरिस, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग" },
                    {label: "कार्बनिक रसायन", url: "/organicHINDI", more: "कार्बनिक और अकार्बनिक यौगिकों के बीच अंतर, कार्बन परमाणुओं की अनूठी प्रकृति, सहसंयोजक बंधन, ध्रुवीय और गैर-ध्रुवीय यौगिक, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग" },
                    {label: "इसका वर्गीकरण", url: "/organic2HINDI", more: "कार्बनिक यौगिकों का संरचनात्मक प्रतिनिधित्व, कार्बनिक यौगिकों का वर्गीकरण, कार्बन परमाणुओं का वर्गीकरण, कार्यात्मक समूह, सजातीय श्रृंखला, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग" },
                    {label: "नामपद्धति", url: "/organic3HINDI", more:"नामकरण, नामकरण की IUPAC प्रणाली, IUPAC नामकरण के नियम, अनुप्रयोग, टेस्ट ड्राइव"},
                    {label: "असंतृप्त यौगिक", url: "/organic4HINDI", more:"असंतृप्त हाइड्रोकार्बन, असंतृप्त हाइड्रोकार्बन के नामकरण की IUPAC प्रणाली, कार्यात्मक समूह के लिए नामकरण, अनुप्रयोग, पिछले वर्षों के परीक्षा प्रश्न"},
                    {label: "रासायनिक प्रतिक्रिया", url: "/chemreactHINDI", more:"रासायनिक समीकरण, संतुलित रासायनिक समीकरण, संतुलित रासायनिक समीकरण के निहितार्थ, संतुलित रासायनिक समीकरण लिखने के महत्वपूर्ण उदाहरण, संकल्पना निर्माता, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    {label: "प्रतिक्रियाओं के प्रकार", url: "/chemreact2HINDI", more:"संयोजन प्रतिक्रियाएँ, अपघटन प्रतिक्रियाएँ, विस्थापन प्रतिक्रियाएँ, प्रायोगिक प्रदर्शन, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    {label: "प्रतिक्रियाओं के प्रकार- II", url: "/chemreact3HINDI", more:"दोहरी विस्थापन प्रतिक्रियाएँ, ऑक्सीकरण, न्यूनीकरण प्रतिक्रियाएँ, प्रायोगिक प्रदर्शन, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    {label: "एल्केन", url: "/organic7HINDI", more:"प्राप्ति, संरचनाएँ, मीथेन और ईथेन की प्रयोगशाला में तैयारी, भौतिक गुण, तैयारी के अन्य तरीके, अल्केन्स में दहन, एल्केन्स में प्रतिस्थापन, मीथेन और एथेन के उपयोग, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    {label: "ऐल्कीन", url: "/organic8HINDI", more:"प्राप्ति, संरचनाएँ, एथीन की प्रयोगशाला में तैयारी, भौतिक गुण, रासायनिक गुण, पोलीमराइज़ेशन, ऑक्सीकरण, दहन, उपयोग, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    {label: "एल्काइन", url: "/organic9HINDI", more:"प्राप्ति, संरचनाएँ, एथाइन की प्रयोगशाला में तैयारी, भौतिक गुण, रासायनिक गुण, एथाइन के उपयोग, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    {label: "एल्कोहल", url: "/organic10HINDI", more:"प्राप्ति, संरचनाएँ, इथेनॉल की प्रयोगशाला और औद्योगिक तैयारी, शराब के भौतिक गुण, रासायनिक गुण, इथेनॉल का दहन, इथेनॉल का उत्पादन"},
                    {label: "कार्बोक्जिलिक एसिड", url: "/organic11HINDI", more:"एसिटिक एसिड की प्राप्ति, संरचनाएँ, प्रयोगशाला और औद्योगिक तैयारी, एसिटिक एसिड के भौतिक गुण, रासायनिक गुण, एस्टर"},
                    {label: "दहन/ ऑक्सीकरण", url: "/organic6HINDI", more: "दहन और ऑक्सीकरण, योगात्मक प्रतिक्रिया, प्रतिस्थापन प्रतिक्रियाएँ, हाइड्रोजनीकरण, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    /*{label: "METALS", url: "", more:"Physical properties of metals, Chemical properties of metals, Reactivity series of metals, Uses of metals"},
                    {label: "NON-METALS", url: "", more:"Physical properties of nonmetals, Chemical properties of nonmetals, Uses of non metals, Differences between metals and non-metals"},
                    {label: "THEIR REACTIONS", url: "", more:"How do metals and nonmetals react,Chemical bonding and cause of chemical bonding, Ionic or electrovalent bonding, Covalent bonding, Properties of ionic compound, Properties of covalent compound"},
                    {label: "METALS OCCURENCES", url: "", more:"Occurrence of metals, Basic metallurgical process, Refining of metals, Corrosion, Alloys"}*/
                    {label: "एल्युमिनियम निष्कर्षण", url: "/metallurgyHINDI", more:"एल्युमिनियम, निष्कर्षण, एल्युमिना, हॉल- हेरोल्ट्स प्रोसेस, रिफाइनिंग प्रोसेस"},
                    {label: "धातुकर्म", url: "/metallurgy1HINDI", more:"अयस्क, धातुकर्म, अयस्कों का सांद्रण, भूनना, निस्तापन"},
                    {label: "आवर्त सारणी", url: "/periodic", more:"डॉबेराइनर ट्रायड्स, न्यूलैंड्स का ऑक्टेव्स का नियम, मेंडेलीव की आवर्त सारणी, आधुनिक आवर्त सारणी, काल, कोश, वैधता, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    {label: "आवर्त सारणी- II", url: "/periodic2", more:"आवधिक गुण, परमाणु आकार, धात्विक और गैर-धात्विक चरित्र, रासायनिक प्रतिक्रियाशीलता, आयनीकरण क्षमता, इलेक्ट्रॉन एफ़िनिटी, इलेक्ट्रोनगेटिविटी, पिछले वर्षों के परीक्षा प्रश्न, अनुप्रयोग"},
                    /*{label: "रासायनिक संबंध- I", url: "/chembondHINDI", more:"केमिकल बॉन्डिंग, इलेक्ट्रोवेलेंट बॉन्ड, इलेक्ट्रोवेलेंट कंपाउंड्स की संरचनाएं, कॉन्सेप्ट बिल्डर्स, पिछले वर्षों के परीक्षा प्रश्न, एप्लिकेशन"},
                    {label: "रासायनिक संबंध- II", url: "/chembond2HINDI", more:"सहसंयोजक बंधन, ध्रुवीय यौगिक, गैर-ध्रुवीय यौगिक, समन्वय बंधन, संकल्पना निर्माता, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग"}*/
                ],
                math: [
                    {label: "वास्तविक संख्या", url: "/realno", more:"संख्या रेखा, यूक्लिड का विभाजन लेम्मा, यूक्लिड का विभाजन एल्गोरिथम, अंकगणित का मौलिक प्रमेय, परिमेय और अपरिमेय संख्या, अनुप्रयोग, टेस्ट ड्राइव" },
                    {label: "द्विघात समीकरण", url: "/quadratic", more:"द्विघात समीकरण, श्रीधराचार्य सूत्र, समीकरण के मूल, मूलों की प्रकृति, अनुप्रयोग, टेस्ट ड्राइव" },
                    {label: "अनुप्रयोग - I", url: "/quadratic2", more:"संख्या, समय और कार्य और ज्यामितीय आंकड़े और क्षेत्रमिति पर आधारित अनुप्रयोग, टेस्ट ड्राइव, अनुप्रयोग" },
                    {label: "अनुप्रयोग - II", url: "/quadratic3", more:"दूरी, गति, समय, धन संबंधी मामले और उम्र पर आधारित अनुप्रयोग, टेस्ट ड्राइव, अनुप्रयोग" },
                    {label: "बहुआयामी पद", url: "/poly",more:"एक बहुपद का शून्य, बहुपद के लिए विघटन एल्गोरिथम, एक बहुपद की डिग्री, डिग्री के आधार पर बहुपद के प्रकार, शून्य और गुणांक के बीच संबंध, ग्राफिकल प्रतिनिधित्व, अनुप्रयोग"},
                    {label: "रैखिक समीकरणों की जोड़ी", url: "/linear", more:"रैखिक समीकरण, ग्राफिकल विधि, रैखिक समीकरणों की एक जोड़ी के समाधान के अस्तित्व के लिए शर्त, उन्मूलन विधि, प्रतिस्थापन विधि, क्रॉस गुणन विधि, टेस्ट ड्राइव, अनुप्रयोग"},
                    {label: "निर्देशांक ज्यामिति", url: "/coord1", more:"समन्वय प्रणाली, दूरी सूत्र, कोलिनियरिटी, कुछ विशेष बिंदुओं के निर्देशांक, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग"},
                    {label: "खंड सूत्र", url: "/coord2", more:"खंड सूत्र, मध्य-बिंदु सूत्र, त्रिभुज का क्षेत्रफल, पिछले वर्षों के परीक्षा प्रश्न, टेस्ट ड्राइव, अनुप्रयोग"},
                    {label: "सीधी रेखा", url: "/straight", more:"एक रेखा का स्लोप, स्लोप- इंटरसेप्ट फॉर्म, पॉइंट- स्लोप फॉर्म, टू-पॉइंट फॉर्म, इंटरसेप्ट फॉर्म, टेस्ट ड्राइव, अनुप्रयोग"},
                    /*{label: "MATRICES", url: "", more:"Order of a matrix, Types of Matrix, Operation on matrix, Multiplication of matrices"},*/
                    {label: "वृत्त", url: "/circle1", more:"जीवा, छेदक, स्पर्शरेखा, विभिन्न बिंदुओं से खींची गई स्पर्शरेखा की लंबाई, अंतर्वृत्त की अवधारणा, चक्रीय चतुर्भुज, कोणों से जुड़े प्रमेय, टेस्ट ड्राइव, अनुप्रयोग"},
                    {label: "क्षेत्र और वृत्त", url: "/circle2", more:"वृत्त से संबंधित क्षेत्र, पाई की अवधारणा, वृत्ताकार चाप, संकेंद्रित वृत्तों की परिधि, विभिन्न मामले, अनुप्रयोग"},
                    /*{label: "TRIANGLES", url: "", more:"Similarity of polygons, Angle bisector theorem, Pythagorous Theorem, Similarity criteria, Area of similar triangles, Basic proportionality theorem (Thales Theorem)"},*/
                    /*{label: "MENSURATION", url: "", more:"Hemispherical shell, Sphere, Hemisphere, Cuboid, Right Circular Cone, Cube, Hollow Cylinder, Frustum of a cone"},*/
                    {label: "त्रिकोणमिति", url: "/trigno1", more:"त्रिकोणमितीय अनुपात, संबंधित प्रमेय, पूरक कोणों के त्रिकोणमितीय अनुपात, त्रिकोणमितीय पहचान, अनुप्रयोग"},
                    {label: "त्रिकोणमिति- II", url: "/trigno2", more:"विशेष कोणों के त्रिकोणमिति अनुपात, पूरक कोण, त्रिकोणमिति पर आधारित अनुप्रयोग और त्रिकोणमितीय अनुपात"},
                    {label: "ऊँचाई और दूरी", url: "/trigno4", more:"उन्नयन कोण, अवनमन कोण, गतिमान वस्तुएँ और बदलते कोण, त्रिकोणमिति के आगे के अनुप्रयोग"},
                    /*{label: "STATISTICS", url: "", more:"Mean, Median, Mode, Cumulative Frequency, More than Ogive and Less than Ogive, Median of ungrouped data, Median of grouped data and class intervals"},*/
                    {label: "ए.पी.", url: "/ap", more:"एपी की सामान्य अवधि, अंत से एनवें शब्द, एपी की एन शर्तों का योग, रिवर्स एपी, अनुप्रयोग" },
                    {label: "संभावना", url: "", more:"सैद्धांतिक प्रायिकता, प्रायोगिक प्रायिकता, कारों की अवधारणा, प्राथमिक घटना, यौगिक घटना, पूरक घटना"},
                    /*{label: "CONSTRUCTIONS", url: "", more:"Division of a line segment internally, contsruction of triangle similar to a given triangle, construction of tangent to a circle, different cases and applications"},*/
                    /*{label: "TRIANGLES", url: "", more:"Similarity of polygons, Angle bisector theorem, Pythagorous Theorem, Similarity criteria, Area of similar triangles, Basic proportionality theorem (Thales Theorem)"},*/
                    /*{label: "AREA & VOLUME", url: "", more:"Hemispherical shell, Sphere, Hemisphere, Cuboid, Right Circular Cone, Cube, Hollow Cylinder, Frustum of a cone"},*/
                ]
            }
        },
        ICSE: {
            HINGLISH: {
                physics: [
                    { label: "Force, Work, Energy and Power", children: [
                        { label: "Force and Torque",url: "/fortor", more: "Force, Moment of Force, Couple, Centre of Gravity, Applications, Concept Builders, Test Drive"  },
                        { label: "Work and Energy - I", url: "/workenergy1", more: "Concept of Work Done, W = F s cosθ, Power, Applications, Concept Builders, Test Drive" },
                        { label: "Work and Energy - II", url: "/workenergy2", more: "Potential Energy, Kinetic Energy, Momentum, Applications, Concept Builders, Test Drive" },
                        { label: "Work and Energy - III", url: "/workenergy3", more: "Conservation of Energy; Graphical representation of kinetic/potential/total energy; Conservation of energy on simple pendulum; Applications; Concept Builders; Test Drive" },
                        { label: "Machines", url: "/machines", more: ""},
                        { label: "Pulleys", url: "/pulleys", more: ""},
                    ] },
                    { label: "Light", children: [
                        { label: "Refraction of Light", url: "/refraction", more: "Refraction of Light, Laws of Refraction, Refractive Index, Refraction from Glass Slab, Applications, Applications, Concept Builders, Test Drive, Subjective Questions"  },
                        { label: "Prism", url: "/prism", more: "Prism, Refraction through Prism, Dispersion of Light, Why White light disperses, Combination of Prism, Working of Prism" },
                        { label: "Lens", url: "/lens", more: "Lens, Components of Lenses, Rules for Image Formation in lenses, Lens Formula, Magnification in Lens, Sign Convention for Lens, Applications, Subjective Questions" },
                        { label: "Convex Lens", url: "/lens2" },
                        { label: "Concave Lens", url: "/lens3" },
                        // { label: "Spectrum", more: "COMING SOON"},
                    ] },
                    { label: "Sound", url: "/sound1" },
                    { label: "Electricity and Magnetism", children: [
                        { label: "Charges", url: "/charges", more: "Laws of Reflection Image Formation" },
                        { label: "Potential and Current", url: "/conductor-insulator"},
                        { label: "Resistance", url: "/resistance"},
                        { label: "Ohm's Law", url: "/ohms-law"},
                        { label: "Resistances Combo", url: "/combinationofresistances"},
                        { label: "Electric Power", url: "/power"},
                        { label: "Electrical Circuits", url: "/emf"},
                        { label: "Magnetic Effects", url: "/em1"},
                        { label: "Force and Conductor", url: "/em2", more: "Force on current carrying conductor"},
                        { label: "Electromagnetic Induction", url: "/em3", more: "Electromagnetic Induction"}
                    ] },
                    { label: "Calorimetry", children: [
                        { label: "Calorimetry – 1", url: "/calorimetry", more: "Concept of heat, Temperature, Specific heat Capacity, Calorimetry, Applications"  },
                        { label: "Calorimetry – 2", url: "/calorimetry2", more: "Change of phase, Melting, Boiling, Latent heat, Applications" },
                    ] },
                    { label: "Modern Physics", children: [
                        { label: "Radioactivity", url: "/radioactivity", more: " Radioactive emission, Nuclear energy, Nuclear fission, Nuclear fusion, Applications" },
                        { label: "Nuclear Reactions", url: "/nuclear", more: "E = mc2 • Nuclear fission • Nuclear fusion • Power Plant • Applications"  },
                    ] },
                ],
                chemistry: [
                    { label: "Periodic Properties and Variations of Properties", children: [
                        { label: "Periodic Table - I", url: "/periodic", more: "Dobereiner's Triads, Newlands' Law of Octaves, Mendeleev's Periodic Table, Modern Periodic Table, Periods, Shells, Valency, Previous Years Examination Questions, Applications"  },
                        { label: "Periodic Table - II", url: "/periodic2", more: "Periodic Properties, Atomic Size, Metallic & Non-metallic character, Chemical Reactivity, Ionisation Potential, Electron Affinity, Electronegativity, Previous Years Examination Questions, Applications" },
                    ] },
                    { label: "Chemical Bonding", children: [
                        { label: "Chemical Bonding - I", url: "/chembond", more:"Chemical Bonding, Electrovalent Bond, Structures of Electrovalent Compounds, Concept Builders, Previous Years Examination Questions, Applications"  },
                        { label: "Chemical Bonding - II", url: "/chembond2", more:"Covalent Bonds, Polar Compounds, Non- polar Compounds, Coordinate Bond, Concept Builders, Previous Years Examination Questions, Test Drive, Applications" },
                    ] },
                    { label: "Acids, Bases and Salts", children: [
                        { label: "Acids", url: "/acid-base", more: "Acids, Classification of acids, Basicity, Reactions for acids, JADUSAY Perfect Strategy, Test Drive, Applications"  },
                        { label: "Bases", url: "/acid-base2", more: "Bases, Alkalis, Properties of Bases, pH - Scale, Universal Indicator, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Salts", url: "/salt", more: "Salt, pH of Salt Solutions, Common Salt, Sodium Hydroxide, Washing Soda, Baking Soda, Bleaching Powder, Plaster of Paris, JADUSAY Perfect Strategy, Test Drive, Applications" },
                    ] },
                    // analytical chemistry - ammonium hydroixe and sodium hydroxide
                    { label: "Mole Concept and Stochiometry", children: [
                        { label: "Mole Concept - 1", url: "/moleconcept1", more: "Gay-Lussac's Law, Avogadro's Law, Stoichometry, Applications" },
                        { label: "Mole Concept - 2", url: "/moleconcept2", more: "Atomic mass, Mole concept, Vapour Density, Applications" },
                        { label: "Mole Concept - 3", url: "/moleconcept3", more: "Percentage composition, Empirical formula, Molecular formula, Applications" },
                        { label: "Mole Concept - 4", url: "/moleconcept4", more: "Chemical equations, Information conveyed, Methodology, Applications" },
                    ] },
                    // electrolysis
                    { label: "Metallurgy", children: [
                        { label: "Aluminium Extraction", url: "/metallurgy", more: "Aluminium, Extraction, Alumina, Hall- Heroult's Process, Refining Process" },
                        { label: "Metallurgy", url: "/metallurgy1", more: "Ores, Metallurgy, Concentration of Ores, Roasting, Calcination" },
                    ] },
                    { label: "Study of Compounds", children: [
                        // hydrogen chloride
                        { label: "Ammonia", url: "/study", more: "Laboratory Preparation, Haeber's Process, Properties of Ammonia, Some reactions" },
                        { label: "Nitric Acid", url: "/study2", more: "Laboratory Preparation, Ostwald Process, Chemical Properties, Oxidizing Properties, Tests for Nitric Acid" },
                        // Sulphuric Acid
                    ] },
                    { label: "Organic Chemistry", children: [
                        { label: "Organic Chemistry", url: "/organic", more: "Differentiation between organic and inorganic compounds, Unique nature of Carbon atoms, Covalent Bond, Polar and non-polar compounds, JADUSAY Perfect Strategy, Test Drive, Applications"  },
                        { label: "Classification of Organic Compounds", url: "/organic2", more: "Structural representation of organic compounds, Classification of organic compounds, Classification of carbon atoms, Functional Group, Homologous Series, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Nomenclature", url: "/organic3", more: "Nomenclature, IUPAC System of Naming, Rules for IUPAC Nomenclature, Applications, Test Drive" },
                        { label: "Unsaturated Compounds", url: "/organic4", more: "Unsaturated Hydrocarbons, IUPAC System of naming Unsaturated hydrocarbons, Nomenclature for functional group, Applications, JADUSAY Perfect Strategy" },
                        { label: "Combustion and Oxidation", url: "/organic6", more: "Combustion and Oxidation, Addition Reaction, Substitution Reactions, Hydrogenation, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alkanes", url: "/organic7", more: "Occurrences, Structures, Lab Preparation of Methane and Ethane, Physical Properties, Other methods of preparation, Combustion in alkanes, Substitution in alkanes, Uses of Methane and Ethane, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alkenes", url: "/organic8", more:"Occurrences, Structures, Lab Preparation of Ethene, Physical Properties, Chemical Properties, Polymerization, Oxidation, Combustion, Uses, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alkynes", url: "/organic9", more: "Occurrences, Structures, Lab Preparation of Ethyne, Physical Properties, Chemical Properties, Uses of Ethyne, JADUSAY Perfect Strategy, Applications" },
                        { label: "Alcohols and Acids", url: "/organic10", more: "Occurrences, Structures, Lab and Industrial Preparation of Ethanol, Physical Properties of Alcohol, Chemical Properties, Combustion of Ethanol, Production of Ethanol" },
                        { label: "Carboxylic Acids", url: "/organic11", more: "Occurrences, Structures, Lab and Industrial Preparation of Acetic Acid, Physical Properties of Acetic Acid, Chemical Properties, Esters" },
                    ] },
                ],
                math: [
                    // tax
                    // banking
                    // shares and dividend
                    // linear inequations
                    { label: "Quadratic Equations", children: [
                        { label: "Quadratic Equations", url: "/quadratic", more: "Quadratic Equation, Sridharacharya Formula, Roots of the Equation, Nature of roots, Applications, Test Drive"  },
                        { label: "Applications - I", url: "/quadratic2", more: "Applications based on Numbers, Time & Work and Geometrical Figures & Mensuration, Test Drive, Applications" },
                        { label: "Applications - II", url: "/quadratic3", more: "Applications based on Distance, Speed, Time, Money Matters and Ages, Test Drive, Applications" },
                    ] },
                    // ratio and proportion
                    // Factorisation of Polynomials - Remainder and Factor Theorem
                    // matrices
                    { label: "Arithmetic Progressions", url: "/ap", more: "General Term of ap, nth term from end, Sum of n terms of an ap, Reverse AP, Applications" },
                    // geometric prograssion
                    { label: "Coordinate Geometry", children: [
                        { label: "Coordinate Geometry", url: "/coord1", more: "Coordinate Systems, Distance Formula, Colinearity, Coordinates of some particular points, JADUSAY Perfect Strategy, Test Drive, Applications"  },
                        { label: "Section Formula", url: "/coord2", more: "Section Formula, Mid-point Formula, Area of a Triangle, JADUSAY Perfect Strategy, Test Drive, Applications" },
                        { label: "Straight Line", url: "/straight", more: "Slope of a line, Slope- Intercept form, Point- Slope form, Two- point form, Intercept form, Test Drive, Applications" },
                    ] },
                    // similarity of triangles
                    // loci
                    { label: "Circles", children: [
                        { label: "Circles", url: "/circle1", more: "Chord, Secant, Tangent, Length of tangent drawn from different points, Concept of Incircle, Cyclic Quadrilateral, Theorems associated with angles, Test Drive, Applications" },
                        // constructions
                        { label: "Areas Related to Circles", url: "/circle3", more: "Areas related to Circle, Concept of pi, Circular arc, Perimeter of concentric circles, Various Cases, Applications"},
                        { label: "Sectors and Segments", url: "/circle4", more: ""},
                    ] },
                    { label: "Mensuration", url: "/mensuration1", more: "" },
                    { label: "Trigonometry", children: [
                        { label: "Trigonometry - I", url: "/trigno1", more:"Trigonometric Ratios, associated theorems, Trogonometric ratios of complementary angles, Trigometrical identities, Applications" },
                        { label: "Trigonometry - II", url: "/trigno2", more:"Trigonometry ratios of special angles, Complementary angles, Applications based on Trigonometry and Trigometric Ratios" },
                        { label: "Heights and Distances", url: "/trigno4", more:"Angle of Elevation, Angle of Depression, Moving objects and changing angles, further applications of trigonometry" },
                    ] },
                    // statistics
                    { label: "Probablity", children: [
                        { label: "Probability - I", url: "/prob1", more: "Basics, Experimental probability, Theoretical probability, Applications" },
                        { label: "Probability - II", url: "/prob2", more: "Problem types, Information conveyed, Methodology, Solutions" },
                    ] },
                ]
            },
            HINDI: {
                physics: [
                    // {label: "FORCE/ TORQUE",url: "/fortor", more: "Force, Moment of Force, Couple, Centre of Gravity, Applications, Concept Builders, Test Drive" },
                    // {label: "WORK & ENERGY - I", url: "/workenergy1", more:"Concept of Work Done, W = F s cosθ, Power, Applications, Concept Builders, Test Drive" },
                    // {label: "WORK & ENERGY - II", url: "/workenergy2", more:"Potential Energy, Kinetic Energy, Momentum, Applications, Concept Builders, Test Drive" },
                    // {label: "WORK & ENERGY - III", url: "/workenergy3", more:"Conservation of Energy; Graphical representation of kinetic/potential/total energy; Conservation of energy on simple pendulum; Applications; Concept Builders; Test Drive", public:false},
                    {label: "REFRACTION OF LIGHT", url: "/refractionhindi", more:"Refraction of Light, Laws of Refraction, Refractive Index, Refraction from Glass Slab, Applications, Applications, Concept Builders, Test Drive, Subjective Questions" },
                    // {label: "MACHINES", url: "/", more: "COMING SOON..."},
                    {label: "PRISM", url: "/prismhindi", more:"Prism, Refraction through Prism, Dispersion of Light, Why White light disperses, Combination of Prism, Working of Prism"},
                    {label: "LENS", url: "/lenshindi", more:"Lens, Components of Lenses, Rules for Image Formation in lenses, Lens Formula, Magnification in Lens, Sign Convention for Lens, Applications, Subjective Questions"},
                    {label: "CONVEX LENS", url: "/lens2hindi"},
                    {label: "CONCAVE LENS", url: "/lens3hindi"},
                    // {label: "CHARGES", url: "/charges", more: "Laws of Reflection Image Formation"},
                    // {label: "POTENTIAL/ CURRENT", url: "/conductor-insulator"},
                    // {label: "RESISTANCE", url: "/resistance"},
                    // {label: "OHMS LAW", url: "/ohms-law"},
                    // {label: "ELECTRIC POWER", url: "/power"},
                    // {label: "RESISTANCES COMBO", url: "/combinationofresistances"},
                    // {label: "ELECTRICAL CIRCUITS", url: "/emf"},
                    // {label: "MAGNETIC EFFECTS", url: "/em1"},
                    // {label: "FORCE & CONDUCTOR", url: "/em2", more: "Force on current carrying conductor"},
                    // {label: "E.M.I.", url: "/em3", more: "Electromagnetic Induction"}
                ],
                chemistry: [
                    {label: "ACIDS", url: "/acid-basehindi", more: "Acids, Classification of acids, Basicity, Reactions for acids, JADUSAY Perfect Strategy, Test Drive, Applications" },
                    // {label: "BASES", url: "/acid-base2", more: "Bases, Alkalis, Properties of Bases, pH - Scale, Universal Indicator, JADUSAY Perfect Strategy, Test Drive, Applications" },
                    {label: "SALTS", url: "/salthindi", more: "Salt, pH of Salt Solutions, Common Salt, Sodium Hydroxide, Washing Soda, Baking Soda, Bleaching Powder, Plaster of Paris, JADUSAY Perfect Strategy, Test Drive, Applications" },
                    // {label: "ORGANIC CHEMISTRY", url: "/organic", more: "Differentiation between organic and inorganic compounds, Unique nature of Carbon atoms, Covalent Bond, Polar and non-polar compounds, JADUSAY Perfect Strategy, Test Drive, Applications" },
                    {label: "ITS CLASSIFICATION", url: "/organic2hindi", more: "Structural representation of organic compounds, Classification of organic compounds, Classification of carbon atoms, Functional Group, Homologous Series, JADUSAY Perfect Strategy, Test Drive, Applications" },
                    {label: "NOMENCLATURE", url: "/organic3hindi", more:"Nomenclature, IUPAC System of Naming, Rules for IUPAC Nomenclature, Applications, Test Drive"},
                    {label: "UNSATURATED COMP.", url: "/organic4hindi", more:"Unsaturated Hydrocarbons, IUPAC System of naming Unsaturated hydrocarbons, Nomenclature for functional group, Applications, JADUSAY Perfect Strategy"},
                    {label: "CHEMICAL BONDING- I", url: "/chembondhindi", more:"Chemical Bonding, Electrovalent Bond, Structures of Electrovalent Compounds, Concept Builders,JADUSAY Perfect Strategy, Applications "},
                    {label: "CHEMICAL BONDING- II", url: "/chembond2hindi", more:"Covalent Bonds, Polar Compounds, Non- polar Compounds, Coordinate Bond, Concept Builders, JADUSAY Perfect Strategy, Test Drive, Applications"},
                    {label: "CHEMICAL REACTION", url: "/chemreacthindi", more:"Chemical equations, balanced chemical equations, implications of a balanced chemical equation, Important examples writing off balanced chemical equations, Concept Builders, JADUSAY Perfect Strategy, Applications"},
                    {label: "ALKANES", url: "/organic7hindi", more:"Occurrences, Structures, Lab Preparation of Methane and Ethane, Physical Properties, Other methods of preparation, Combustion in alkanes, Substitution in alkanes, Uses of Methane and Ethane, JADUSAY Perfect Strategy, Applications"},
                    {label: "ALKENES", url: "/organic8hindi", more:"Occurrences, Structures, Lab Preparation of Ethene, Physical Properties, Chemical Properties, Polymerization, Oxidation, Combustion, Uses, JADUSAY Perfect Strategy, Applications"},
                    {label: "ALKYNES", url: "/organic9hindi", more:"Occurrences, Structures, Lab Preparation of Ethyne, Physical Properties, Chemical Properties, Uses of Ethyne, JADUSAY Perfect Strategy, Applications"},
                    {label: "ALCOHOLS", url: "/organic10hindi", more:"Occurrences, Structures, Lab and Industrial Preparation of Ethanol, Physical Properties of Alcohol, Chemical Properties, Combustion of Ethanol, Production of Ethanol"},
                    {label: "CARBOXYLIC ACIDS", url: "/organic11hindi", more:"Occurrences, Structures, Lab and Industrial Preparation of Acetic Acid, Physical Properties of Acetic Acid, Chemical Properties, Esters"},
                    {label: "COMBUS./ OXIDATION", url: "/organic6hindi", more: "Combustion and Oxidation, Addition Reaction, Substitution Reactions, Hydrogenation, JADUSAY Perfect Strategy, Applications"},
                    /*{label: "METALS", url: "", more:"Physical properties of metals, Chemical properties of metals, Reactivity series of metals, Uses of metals"},
                    {label: "NON-METALS", url: "", more:"Physical properties of nonmetals, Chemical properties of nonmetals, Uses of non metals, Differences between metals and non-metals"},
                    {label: "THEIR REACTIONS", url: "", more:"How do metals and nonmetals react,Chemical bonding and cause of chemical bonding, Ionic or electrovalent bonding, Covalent bonding, Properties of ionic compound, Properties of covalent compound"},
                    {label: "METALS OCCURENCES", url: "", more:"Occurrence of metals, Basic metallurgical process, Refining of metals, Corrosion, Alloys"}*/
                    {label: "AMMONIA", url: "/studyhindi", more:"Laboratory Preparation, Haeber's Process, Properties of Ammonia, Some reactions"},
                    {label: "NITRIC ACID", url: "/study2hindi", more:"Laboratory Preparation, Ostwald Process, Chemical Properties, Oxidizing Properties, Tests for Nitric Acid"},
                    {label: "ALUMINIUM EXTRACTION", url: "/metallurgyhindi", more:"Aluminium, Extraction, Alumina, Hall- Heroult's Process, Refining Process"},
                    {label: "METALLURGY", url: "/metallurgy1hindi", more:"Ores, Metallurgy, Concentration of Ores, Roasting, Calcination"}
                ],
                math: [
                    // {label: "REAL NUMBERS", url: "/realno", more:"Number Line, Euclid's Division Lemma, Euclid's Division Algorithm ,Fundamental Theorem of Arithmetic, Rational & Irrational Numbers, Applications, Test Drive" },
                    // {label: "QUADR. EQUATIONS", url: "/quadratic", more:"Quadratic Equation, Sridharacharya Formula, Roots of the Equation, Nature of roots, Applications, Test Drive" },
                    // {label: "APPLICATION - I", url: "/quadratic2", more:"Applications based on Numbers, Time & Work and Geometrical Figures & Mensuration, Test Drive, Applications" },
                    // {label: "APPLICATION - II", url: "/quadratic3", more:"Applications based on Distance, Speed , Time, Money Matters and Ages, Test Drive, Applications" },
                    // {label: "POLYNOMIALS", url: "/poly",more:"Zero of a Polynomial, Dision Algorithm for polynomial, degree of a polynomial, types of polynomial based on degrees, relation between the zeroes and coefficient, Graphical Representation, Applications"},
                    // {label: "PAIR OF LINEAR EQ.", url: "/linear", more:"Linear Equations, Graphical Method, condition for existence of a solution of a pair of linear equations, Elimination Method, Substitution Method, Cross Multiplication Method, Test Drive, Applications"},
                    // {label: "COORDINATE GEOM.", url: "/coord1", more:"Coordinate Systems, Distance Formula, Colinearity, Coordinates of some particular points, JADUSAY Perfect Strategy, Test Drive, Applications"},
                    // {label: "SECTION FORMULA", url: "/coord2", more:" Section Formula, Mid-point Formula, Area of a Triangle, JADUSAY Perfect Strategy, Test Drive, Applications"},
                    // {label: "STRAIGHT LINE", url: "/straight", more:"Slope of a line, Slope- Intercept form, Point- Slope form, Two- point form, Intercept form, Test Drive, Applications"},
                    /*{label: "MATRICES", url: "", more:"Order of a matrix, Types of Matrix, Operation on matrix, Multiplication of matrices"},*/
                    // {label: "CIRCLES", url: "/circle1", more:"Chord, Secant, Tangent, Length of tangent drawn from different points, Concept of Incircle, Cyclic Quadrilateral, Theorems associated with angles, Test Drive, Applications"},
                    // {label: "AREA & CIRCLES", url: "/circle2", more:"Areas related to Circle, Concept of pi, Circular arc, Perimeter of concentric circles, Various Cases, Applications"},
                    /*{label: "TRIANGLES", url: "", more:"Similarity of polygons, Angle bisector theorem, Pythagorous Theorem, Similarity criteria, Area of similar triangles, Basic proportionality theorem (Thales Theorem)"},*/
                    /*{label: "MENSURATION", url: "", more:"Hemispherical shell, Sphere, Hemisphere, Cuboid, Right Circular Cone, Cube, Hollow Cylinder, Frustum of a cone"},*/
                    // {label: "TRIGONOMETRY", url: "/trigno1", more:"Trigonometric Ratios, associated theorems, Trogonometric ratios of complementary angles, Trigometrical identities, Applications"},
                    // {label: "TRIGONOMETRY- II", url: "/trigno2", more:"Trigonometry ratios of special angles, Complementary angles, Applications based on Trigonometry and Trigometric Ratios"},
                    // {label: "HEIGHTS & DIST.", url: "/trigno4", more:"Angle of Elevation, Angle of Depression, Moving objects and changing angles, further applications of trigonometry"},
                    /*{label: "STATISTICS", url: "", more:"Mean, Median, Mode, Cumulative Frequency, More than Ogive and Less than Ogive, Median of ungrouped data, Median of grouped data and class intervals"},*/
                    // {label: "A.P.", url: "/ap", more:"General Term of ap, nth term from end, Sum of n terms of an ap, Reverse AP, Applications" },
                    // {label: "PROBABILITY", url: "", more:"Theoretical probability, Experimental probability, Concept of cars, Elementary event, Compound event, Complementary event"},
                ]
            }
        }
    },

    'CLASS-9': {
        CBSE: {
            HINGLISH: {
                physics: [
                    // module 1
                    { label: "Motion", url: "/class9-cbse-physics-motion", more: "Distance and Displacement • Velocity • Uniform and Non-Uniform Motion Along a Straight Line • Acceleration • Distance-Time and Velocity-Time Graphs for Uniform Motion and Uniformly Accelerated Motion • Elementary Idea of Uniform Circular Motion" },

                    // module 2
                    { label: "Force and Laws of Motion", url: "/class9-cbse-physics-forceandlawsofmotion", more: "Force and Motion • Newton's Laws of Motion • Action and Reaction Forces • Inertia of a Body • Inertia and Mass • Momentum • Force and Acceleration" },

                    // module 3
                    { label: "Gravitation", url: "/class9-cbse-physics-gravitation", more: "Gravitation • Universal Law of Gravitation • Force of Gravitation of the Earth (Gravity) • Acceleration Due to Gravity • Mass and Weight • Free Fall" },

                    // module 4
                    { label: "Floatation", url: "/class9-cbse-physics-floatation", more: "Thrust and Pressure • Archimedes's Principle • Buoyancy" },

                    // module 5
                    { label: "Work and Energy – I", url: "/class9-cbse-physics-workandenergy1", more: "Work Done by a Force • Energy • Power • Kinetic and Potential Energy • Law of Conservation of Energy"  },
                    { label: "Work and Energy – II", url: "/class9-cbse-physics-workandenergy2", more: "Work Done by a Force • Energy • Power • Kinetic and Potential Energy • Law of Conservation of Energy"  },
                    { label: "Work and Energy – III", url: "/class9-cbse-physics-workandenergy3", more: "Work Done by a Force • Energy • Power • Kinetic and Potential Energy • Law of Conservation of Energy"  },

                    // module 6
                    { label: "Sound", url: "/class9-cbse-physics-sound", more: "Nature of Sound and Its Propagation in Various Media • Speed of Sound • Range of Hearing in Humans • Ultrasound • Reflection of Sound • Echo"  },
                ],
                chemistry: [
                    // module 1
                    { label: "Matter - I", url: "/class9-cbse-physics-matter1", more: "Definition of Matter • Solid, Liquid, and Gas • Characteristics - Shape, Volume, Density • Change of State - Melting (Absorption of Heat) • Freezing • Evaporation (Cooling by Evaporation) • Condensation • Sublimation" },

                    // module 2
                    { label: "Matter - II", url: "/class9-cbse-physics-matter2", more: "Elements, Compounds, and Mixtures • Heterogeneous and Homogeneous Mixtures, Colloids, and Suspensions • Physical and Chemical Changes" },

                    // module 3
                    { label: "Atoms and Molecules", url: "/class9-cbse-physics-atomsandmolecules", more: "Atoms and Molecules • Law of Chemical Combination • Chemical Formula of Common Compounds • Atomic and Molecular Masses" },

                    // module 4
                    { label: "Structure of the Atom", url: "/class9-cbse-physics-structureoftheatom", more: "Electrons • Protons and Neutrons • Valency • Atomic Number and Mass Number • Isotopes and Isobars" },
                ],
                math: [
                    // module 1
                    { label: "Real Numbers", url: "/class9-cbse-physics-realnumbers", more: "Review of Representation of Natural Numbers, Integers, and Rational Numbers on the Number Line • Rational Numbers • Operations on Real Numbers • Irrational Numbers and Their Representation on the Number Line • Definition of nth Root of a Real Number • Rationalization of Real Numbers • Laws of Exponents" },

                    // module 2
                    { label: "Polynomials", url: "/class9-cbse-physics-polynomials", more: "Polynomials in One Variable • Zeroes of a Polynomial • Remainder Theorem • Factor Theorem • Factorization of Polynomials • Algebraic Expressions and Identities" },

                    // module 3
                    { label: "Coordinate Geometry", url: "/class9-cbse-physics-coordinategeometry", more: "Cartesian System" },
                    { label: "Linear Equations in Two Variables", url: "/class9-cbse-physics-linearequationsintwovariables", more: "Linear Equations • Solution of a Linear Equation" },

                    // module 4
                    { label: "Introduction to Euclid's Geometry", url: "/class9-cbse-physics-introductiontoeuclidsgeometry", more: "Euclid's Definition, Axioms/Postulates, and Theorems" },

                    // module 5
                    { label: "Lines and Angles", url: "/class9-cbse-physics-linesandangles", more: "Terms and Definitions • Intersecting Lines and Non-Intersecting Lines • Pairs of Angles • Lines Parallel to the Same Line" },

                    // module 6
                    { label: "Triangles", url: "/class9-cbse-physics-triangles", more: "Congruence of Triangles • Properties of a Triangle" },

                    // module 7
                    { label: "Quadrilaterals", url: "/class9-cbse-physics-quadrilaterals", more: "Properties of a Parellelogram • Mid-point Theorem" },
                    { label: "Circles", url: "/class9-cbse-physics-circles", more: "Angle Subtended by a Chord at a Point • Perpendicular from the Centre to a Chord • Equal Chords and Their Distances from the Centre • Angle Subtended by an Arc of a Circle • Cyclic Quadrilaterals" },

                    // module 8
                    { label: "Heron's Formula", url: "/class9-cbse-physics-heronsformula", more: "Area of a Triangle Using Heron's Formula" },
                    { label: "Surface Area and Volumes", url: "/class9-cbse-physics-surfaceareasandvolumes", more: "Surface Area of a Right Circular Cone • Surface Area of a Sphere • Volume of a Right Circular Cone • Volume of a Sphere" },

                    // module 9
                    { label: "Statistics", url: "/class9-cbse-physics-statistics", more: "Graphical Representation of Data" },
                ]
            }
        },
        ICSE: {
            HINGLISH: {
                physics: [
                    // module 1
                    { label: "Measurement", url: "/class9-icse-physics-measurement", more: "International System of Units • Vernier Calliper • Micro-Metre Screw Gauge • Simple Pendulum" },
                    { label: "Motion in One Dimension", url: "/class9-icse-physics-motioninonedimension", more: "Scalar and Vector Quantities • Distance • Speed • Velocity • Acceleration • Graphs of Distance-Time and Speed-Time • Equations of Uniformly Accelerated Motion with Derivations" },

                    // module 2
                    { label: "Laws of Motion", url: "/class9-icse-physics-lawsofmotion", more: "Contact and Non-Contact Forces; C.G.S. and S.I. units • Newton's First Law of Motion; Inertia, Mass and Force • Newton's Second Law of Motion; Weight and Mass • Newton's Third Law of Motion • Gravitation" },

                    // module 3
                    { label: "Fluids - I", url: "/class9-icse-physics-fluids1", more: "Change of Pressure with Depth • Transmission of Pressure in Liquids • Atmospheric Pressure" },

                    // module 4
                    { label: "Fluids - II", url: "/class9-icse-physics-fluids2", more: "Buoyancy • Archimedes's Principle • Floatation • Relationship with Density; Relative Density • Determination of Relative Density of a Solid" },

                    // module 5
                    { label: "Heat and Energy", url: "/class9-icse-physics-heatandenergy", more: "Concepts of Heat and Temperature • Anomalous Expansion of Water • Energy Flow and Its Importance • Energy Sources • Green House Effect and Global Warming" },

                    // module 6
                    { label: "Reflection of Light", url: "/class9-icse-physics-reflectionoflight", more: "Reflection of Light; Images Formed by a Pair of Parallel and Perpendicular Plane Mirrors • Spherical Mirrors; Characteristics of Image Formed by These Mirrors • Uses of Concave and Convex Mirrors" },

                    // module 7
                    { label: "Sound", url: "/class9-icse-physics-sound", more: "Nature of Sound Waves • Requirement of a Medium for Sound Waves to Travel • Propagation and Speed in Different Media • Comparison with Speed of Light • Infrasonic • Sonic • Ultrasonic Frequencies and Their Applications"  },

                    // module 8
                    { label: "Current Electricity", url: "/class9-icse-physics-currentelectricity", more: "Simple Electric Circuit • Relation of Current with Charge • Potential Difference • Insulators and Conductors • Closed and Open Circuits • Direction of Current (Electron Flow and Conventional)" },

                    // module 9
                    { label: "Magnetism", url: "/class9-icse-physics-magnetism", more: "Induced Magnetism • Magnetic Field of Earth • Neutral Points in Magnetic Fields • Introduction of Electromagnet and Its Uses" },
                ],
                chemistry: [
                    // module 1
                    { label: "The Language of Chemistry", url: "/class9-icse-chemistry-thelanguageofchemistry", more: "Symbol of an Element • Valency • Formulae of Radicals and Formulae of Compounds • Balancing of Simple Chemical Equations • Relative Atomic Masses (Atomic Weights) and Relative Molecular Masses (Molecular Weights)" },

                    // module 2
                    { label: "Chemical Changes and Reactions", url: "/class9-icse-chemistry-chemicalchangesandreactions", more: "Types of Chemical Changes • Energy Changes in a Chemical Change" },

                    // module 3
                    { label: "Water", url: "/class9-icse-chemistry-water", more: "Water as a Universal Solvent • Hydrated and Anhydrous Substances • Drying and Dehydrating Agents • Soft Water and hard Water" },

                    // module 4
                    { label: "Atomic Structure and Chemical Bonding", url: "/class9-icse-chemistry-atomicstructureandchemicalbonding", more: "Structure of an Atom, Mass number and Atomic Number, Isotopes and Octet Rule • Electrovalent and Covalent Bonding, Structures of Various Compounds – Orbit Structure • Electrovalent Bond • Covalent Bond" },

                    // module 5
                    { label: "The Periodic Table", url: "/class9-icse-chemistry-theperiodictable", more: "Dobereiner's Triads • Newlands's Law of Octaves • Mendeleev's Contributions • Modern Periodic Law • The Modern Periodic Table" },

                    // module 6
                    { label: "Study of The First Element – Hydrogen", url: "/class9-icse-chemistry-hydrogen", more: "Hydrogen from: Water, Dilute Acids and Alkalis • The Preparation and Collection of Hydrogen by a Standard Laboratory Method other than Electrolysis • Industrial Manufacture of Hydrogen by Bosch Process • Oxidation and Reduction Reactions" },

                    // module 7
                    { label: "Study of Gas Laws", url: "/class9-icse-chemistry-studyofgaslaws", more: "The Behaviour of Gases under Temperature and Pressure Changes • Boyle's Law • Charles' Law • Absolute Zero • Gas Equation • Relationship Between Kelvin Scale and Celsius Scale of Temperature • Standard Temperature and Pressure" },

                    // module 8
                    { label: "Atmospheric Pollution", url: "/class9-icse-chemistry-atmosphericpollution", more: "Acid Rain – Composition, Cause and Its Impact • Global Warming • Ozone Depletion" },

                    // module 9
                    { label: "Practical Work", url: "/class9-icse-chemistry-practicalwork", more: "Recognition and Identification of Gases • Simple Experiments" },
                ],
                math: [
                    // module 1
                    { label: "Rational and Irrational Numbers", url: "/class9-icse-maths-rationalandirrationalnumbers", more: "Rational and Irrational Numbers • Proofs of Irrationality" },
                    { label: "Compound Interest", url: "/class9-icse-maths-compoundinterest", more: "Compound Interest" },

                    // module 2
                    { label: "Expansions", url: "/class9-icse-maths-expansions", more: "Expansions" },
                    { label: "Factorisation", url: "/class9-icse-maths-factorisation", more: "Factorisation" },

                    // module 3
                    { label: "Simultaneous Linear Equations in Two Variables", url: "/class9-icse-maths-simultaneouslinearequationsintwovariables", more: "Solving Algebraically by – Elimination, Substitution, and Cross Multiplication Method • Solving Simple Problems by Framing Appropriate Equations" },

                    // module 4
                    { label: "Indices (Exponents)", url: "/class9-icse-maths-indices", more: 'Handling Positive, fractional, Negative and "Zero" Indices • Simplification of Expressions Involving Various Exponents' },
                    { label: "Logarithms", url: "/class9-icse-maths-logarithms", more: "Logarithmic Form • Exponential Form • Laws of Logarithms and Their Uses" },

                    // module 5
                    { label: "Triangles", url: "/class9-icse-maths-triangles", more: "Congruency • Problems Based on Triangles • Mid-Point Theorem • Equal Intercept Theorem • Pythagoras Theorem" },

                    // module 6
                    { label: "Rectilinear Figures", url: "/class9-icse-maths-rectilinearfigures", more: "Proof and Use of Theorems on Parallelogram • Constructions of Polygons • Proof and Use of Area Theorems on Parallelograms" },
                    { label: "Circle", url: "/class9-icse-maths-circle", more: "Chord Properties • Arc and Chord Properties" },

                    // module 7
                    { label: "Statistics", url: "/class9-icse-maths-statistics", more: "Introduction • Collection of Data • Presentation of Data • Graphical Representation of Data • Mean • Median of Ungrouped Data" },

                    // module 8
                    { label: "Mensuration", url: "/class9-icse-maths-mensuration", more: "Area and Perimeter of a Triangle and a Quadrilateral • Area and Circumference of Circle • Surface Area and Volume of Cube and Cuboids" },

                    // module 9
                    { label: "Trignometry", url: "/class9-icse-maths-trignometry", more: "Trigonometric Ratios • Trigonometric Ratios of Standard Angles • Single 2-D Problems Involving One Right-Angled Triangle • Concept of Trigonometric Ratios of Complementary Angles and Their Direct Application" },
                    { label: "Coordinate Geometry", url: "/class9-icse-maths-coordinategeometry", more: "Dependent and Independent Variables • Ordered Pairs, Coordinates of Points and Plotting Them in the Cartesian Plane • Solution of Simultaneous Linear Equations Graphically • Distance Formula" },
                ]
            }
        }
    }
}

contents['CLASS-10']['UPBOARD'] = contents['CLASS-10'].CBSE;
contents['CLASS-9']['UPBOARD'] = contents['CLASS-9'].CBSE;

export const urlToContent = {
    CBSE: {
        HINGLISH: {
            physics: {},
            chemistry: {},
            math: {}
        }
    },
    ICSE: {
        HINGLISH: {
            physics: {},
            chemistry: {},
            math: {}
        }
    },
    UPBOARD: {
        HINGLISH: {
            physics: {},
            chemistry: {},
            math: {}
        }
    },
}

export const urlToContent2 = {};

var courseLevels = ['CLASS-10', 'CLASS-9'];
courseLevels.map((courseLevel) => {
    var boards = ['CBSE', 'ICSE', 'UPBOARD'];
    boards.map((board) => {
        ['physics', 'chemistry', 'math'].map((subject, _) => {
            contents[courseLevel][board]['HINGLISH'][subject].map((item, _) => {
                if (item.children) {
                    item.children.map((this_item, _) => {
                        this_item['subject'] = subject;
                        if (this_item.url) {
                            urlToContent[board]['HINGLISH'][this_item.url] = this_item;
                            urlToContent2[this_item.url] = this_item;
                        }
                    })
                } else {
                    item['subject'] = subject;
                    if (item.url) {
                        urlToContent[board]['HINGLISH'][item.url] = item;
                        urlToContent2[item.url] = item;
                    }
                }
            })
        })
    })
})

export default contents;
