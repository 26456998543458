import React from "react";

import {
    Grid,
    Button,
    Menu,
    MenuItem
} from '@mui/material';
import store from './store.js';

const componentDidMount = async (state, setState) => {
    const userData = await makeRequest({}, '/fetchuserdata');
    if (userData.na) {
        setState({
            ...state,
            name: userData.na
        })
    }
}

const Menubar = () => {
    const [state, setState] = React.useState({
        name: ''
    })
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    React.useEffect(() => { (async () => { await componentDidMount(state, setState); })() }, []);

    const handleClickMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = async () => {
        const resp = await makeRequest({}, '/logout')
        if(resp.success) {
            window.location.href = '/'
        }
    }

    const profile = async () => {
    }

    const managePlan = async () => {
        if (!store.LandingPage) {
            window.location.href = '/';
        }

        // store.LandingPage.setState({
        //     ...store.LandingPage.state,
        //     componentToRender: '/plan'
        // })

        window.location.href = '/plan';
    }

    const contact = async () => {
        if (!store.LandingPage) {
            window.location.href = '/';
        }

        // store.LandingPage.setState({
        //     ...store.LandingPage.state,
        //     componentToRender: '/contact'
        // })

        window.location.href = '/contact';
    }

    return (
        <>
            <Grid
                container
                justifyContent="right"
                width='15px'
                alignItems="center"
            >
                <Grid item xs={6}>
                    <img src="/assets/images/user-icon.svg" style={{width: "28px"}} />
                </Grid>
                <Grid item xs={6}>
                    <Button id="account-button" onClick={handleClickMenu} sx={{ padding: 0 }}>
                        <img src="/assets/images/down-arrow.svg" style={{margin:'0px', paddingTop:"10%", width: "15px"}} />
                    </Button>
                    <Menu
                        id="account-menu"
                        anchorEl={anchorEl}
                        sx={{ marginTop: '7px' }}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'account-button',
                        }}
                    >
                        {/*<MenuItem onClick={profile}>User Profile</MenuItem>*/}
                        <MenuItem>Hi {state.name}</MenuItem>
                        <MenuItem onClick={managePlan}>Buy Course</MenuItem>
                        <MenuItem onClick={contact}>Contact</MenuItem>
                        <MenuItem onClick={logout}>Logout</MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        </>
    )
}

export default Menubar;