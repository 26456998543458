import React from "react";
import {
    Box,
    Grid,
    Button,
    Drawer,
    Menu,
    Typography
} from '@mui/material';
import ContentBar from './ContentBar.jsx';
import Menubar from './Menubar.jsx';
import Banner from './Banner.jsx';
import store from './store.js';

const toggleContentDrawer = (state, setState) => {
    store.user['contentDrawer'] = !state.contentDrawer;
    setState({
        ...state,
        contentDrawer: !state.contentDrawer
    });
}

const StickyHeader = ({ state, setState }) => {
    const [isSticky, setSticky] = React.useState(false);

    const handleScroll = () => { setSticky(window.scrollY > 20 ? true : false) };

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return (
            () => { window.removeEventListener('scroll', handleScroll); }
        );
    }, []);

    if (isSticky) {
        return (
            <Grid
                container
                justifyContent="left"
                alignItems="center"
                sx={{ background: '', width: '100%', zIndex: '999', position: 'fixed' }}
            >
                <Grid item xs={6}>
                    <Button onClick={() => toggleContentDrawer(state, setState)}>
                        <img src="/assets/images/menu-symbol-black.svg" style={{ width: "20px" }} />
                    </Button>
                    <Drawer
                        anchor='left'
                        open={state.drawer}
                        onClose={() => toggleContentDrawer(state, setState)}
                    >
                        <ContentBar />
                    </Drawer>
                </Grid>
            </Grid>
        );
    }
}

const NavbarLoggedIn = () => {
    const [state, setState] = React.useState({
        contentDrawer: store.user?.contentDrawer ? store.user?.contentDrawer : false
    });

    return (
        <>
            <Box sx={{ boxShadow: 3 }}>
                <StickyHeader state={state} setState={setState} />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ bgcolor: "#ae4e7a" }}
                >
                    <Grid item width='120px'>
                        <Grid
                            container
                            justifyContent="left"
                            alignItems="center"
                        >
                            <Grid item xs={6}>
                                <Button onClick={() => toggleContentDrawer(state, setState)}>
                                    <img src="/assets/images/menu-symbol.svg" style={{ width: "20px" }} />
                                </Button>
                                <Drawer
                                    anchor='left'
                                    open={state.contentDrawer}
                                    onClose={() => toggleContentDrawer(state, setState)}
                                >
                                    <ContentBar />
                                </Drawer>
                            </Grid>
                            <Grid item xs={6} paddingTop='3px' paddingBottom='3px'>
                                <a href="/"><img src="/landingpage/assets/img/logo/jadu-white.png" alt="jadusay" style={{width: "60px"}} /></a>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item width='76px'>
                        <Menubar />
                    </Grid>
                </Grid>
            </Box>
            {(()=>{
                if (store.user.subscriptionPlan == 'free') {
                    return (<Banner />);
                }
            })()}
        </>
    )
}

export default NavbarLoggedIn;